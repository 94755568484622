@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-version-history {
  .breadcrumbs-wrapper {
    color: $clr-txt-title;

    a,
    span {
      font-size: 12px;
    }

    a:hover {
      text-decoration: underline;
      color: $primary-darker;
    }

    .breacrumb-page {
      color: $clr-txt-title;
      font-weight: normal;
      margin: 0;
    }
  }
  .breadcrumbs-wrapper> :last-child {
    font-size: 14px;
    font-weight: 700;
    color: #415364;
    margin: 0;
  }
  .heading-message {
    color: $clr-v2-soft-red;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .table-versions {
    th {
      padding: 0.5em 0.1em;
      vertical-align: middle;
      font-weight: 500;
    }

    td {
      padding: 0.2em 0;
      vertical-align: middle;
    }

    thead th {
      border-top: none;
      border-bottom: 2px solid gray;
      color: $clr-txt-content;
    }

    tbody tr {
      border-top: none;
      border-bottom: 2px solid $clr-txt-note;
      color: $clr-txt-content;

      cursor: default;

      td > label.alt-checkbox {
        cursor: pointer;

        input {
          display: none;
        }
      }

      td:nth-child(3) {
        span {
          display: block;
          width: 500px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &:hover > td {
        color: $clr-txt-content;
        &:nth-child(2) {
          color: $clr-v2-soft-red;
        }
      }
    }

    th:nth-child(1) {
      width: 5%;
    }

    td:nth-child(1) {
      text-align: center;
    }

    th:nth-child(2) {
      width: 5%;
      text-align: center;
    }

    td:nth-child(2) {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: $clr-v2-soft-red;
    }

    th:nth-child(3) {
      width: 50%;
    }

    th {
      text-align: left;
    }

    th:nth-child(4) {
      width: 20%;
      padding-left: 30px;
    }

    td:nth-child(4) {
      padding-left: 30px;
    }

    th:nth-child(5) {
      width: 20%;
    }

    .alt-checkbox {
      font-size: $fontsize-v2-small;
      color: $clr-v2-soft-red;
      font-family: $Source;
      margin: 0;

      .togglecheck {
        background-color: $clr-v2-soft-red;
        width: 31px;
        height: 10px;
        top: -3px;
      }

      .togglecheck::after {
        width: 16px;
        height: 16px;
        border: 1px solid #e2e5e8;
        box-shadow: 1px #00000066;
        top: -4px;
      }

      .togglecheck.selected {
        background-color: $clr-v2-soft-blue;
      }

      .togglecheck.selected::after {
        background-color: $clr-v2-soft-blue;
        border-color: $clr-v2-soft-blue;
        left: 15px;
      }
    }

    .role-avatar {
      display: flex;

      .avatar img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      .name {
        text-align: center;
        padding: 10px 0 0 10px;
        display: block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .btn {
    float: right;
    margin-top: 40px;
  }

  @media screen and (max-width: 1190px) {
    .role-avatar {
      justify-content: center;
    }
  }
}
