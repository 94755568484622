@import '../../../assets/scss/variables.scss';

.v2-module .v2-page-card .card-body form {
  div.form-block {
    width: 100%;
    margin-top: unset;
    margin-bottom: 15px;
    display: inline-block;

    hr {
      border: 1px dashed $clr-v2-heavy-grey;
      margin: 0 1%;
    }
  }

  .billing-data-alt-checkbox {
    margin-top: 0;
    font-size: 1rem;

    &.disabled {
      opacity: 0.5;
    }
  }

  .alert-container {
    padding: 13px 13px 0 13px;
  }
}
