:global(.filterList).migrate-list {
  span[role='button'] {
    height: 100%;
    vertical-align: middle;
  }
}

:global(#responsibilities-list) {
  position: relative;
  overflow: auto;
  max-height: 500px;
}