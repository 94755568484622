@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-document-print {
  .breadcrumbs-wrapper {
    color: $clr-txt-title;

    a,
    span {
      font-size: 12px;
    }

    a:hover {
      text-decoration: underline;
      color: $primary-darker;
    }

    .breadcrumb-page {
      color: $clr-txt-title;
      font-weight: normal;
      margin: 0;
    }
  }

  .floating-print-button {
    i {
      vertical-align: unset;
    }
  }

  .v2-page-content .v2-page-card #editor-view-wrapper #print-view {
    margin-bottom: 0;
  }

  #info-header {
    display: block;
    font-family: $Source;

    width: 100%;
    margin: 1cm auto 10px auto;
    font-size: 11pt;
    line-height: 1.5;
    color: #000;

    overflow: hidden;
    page-break-after: always;

    h1 {
      color: #000;
    }

    p {
      line-height: 1.5;
    }

    b,
    strong {
      font-weight: $bold;
    }

    .info-company {
      border: 1pt solid #888;
      padding: 0.5cm;
      margin-bottom: 0.3cm;
      overflow: hidden;
      width: 100%;

      p {
        text-align: center;
        font-size: 16pt;
        font-weight: $bold;
      }
    }

    .info-document {
      border: 1pt solid #888;
      padding: 0.5cm;
      margin-bottom: 0.3cm;
      overflow: hidden;

      h1 {
        font-size: 20pt;
        margin: 0 0 0.3cm;
        font-weight: $bold;
      }

      .code {
        float: left;
        width: 20%;

        strong {
          display: block;
        }
      }

      .secao {
        float: left;
        width: 80%;

        p {
          font-size: 11pt;
          line-height: 1.5;
          color: #000;
          font-weight: $bold;
        }
      }
    }

    .functions {
      border: 1pt solid #888;
      padding: 0.5cm;
      margin-bottom: 0.3cm;
      overflow: hidden;

      p {
        float: left;
        width: 33%;

        strong {
          display: block;
        }
      }
    }

    .dates {
      border: 1pt solid #888;
      padding: 0.5cm;
      margin-bottom: 0.3cm;
      overflow: hidden;

      p {
        float: left;
        width: 50%;

        strong {
          display: block;
        }
      }
    }

    .version {
      border: 1pt solid #888;
      padding: 0.5cm;
      margin-bottom: 0.3cm;
      overflow: hidden;

      p {
        float: left;

        &.numvers {
          width: 20%;
        }

        &.descvers {
          width: 80%;

          strong {
            display: block;
          }
        }
      }
    }

    .breadcrumbs {
      margin-bottom: 0px;
      @include font-size(14px);
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        display: inline-block;
        margin: 0 5px;
        color: $clr-txt-content;
        font-weight: $bold;
      }

      em {
        font-style: normal !important;
        text-decoration: none;
      }

      a:hover {
        color: $primary;
        text-decoration: underline;
      }

      strong {
        font-weight: $bold;
      }
    }
  }

  #documentPrint-header {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    // * {
    //   font-family: 'MyriadPro';
    // }

    #documentPrint-column-logo {
      // background-color: rgba(87, 87, 86, 0.1);
      float: left;
      // width: 169.68px;
      // height: 218.06px;
      max-width: 84.84px;
      // height: 109.03px;

      #documentPrint-image-logo {
        max-width: 100%;
      }
    }

    #documentPrint-column-text {
      float: left;
      width: 100%;
      // height: 218.06px;
      // height: 109.03px;

      #documentPrint-textRow-companyName {
        float: left;
        width: 100%;

        #documentPrint-companyName {
          float: left;
          font-size: 12px !important;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
      }

      #documentPrint-textRow-title {
        float: left;
        width: 100%;

        #documentPrint-title {
          float: left;
          font-weight: bold !important;
          // font-size: 24px !important;
          font-size: 13px !important;
        }

        #documentPrint-version {
          margin-left: 10px;
          // padding-top: 7px;
          padding-top: 3px;
          float: left;
          // font-size: 16px !important;
          font-size: 9px !important;
        }
      }

      #documentPrint-textRow-justification {
        float: left;
        width: 100%;
        // padding-top: 10px;
        padding-top: 5px;
        // height: 75px;
        height: 36px;
        background-image: linear-gradient(to right, #a6a6a6 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        // background-size: 9px 2px;
        background-size: 4px 1px;
        background-repeat: repeat-x;
        // font-size: 16px !important;
        font-size: 9px !important;

        #documentPrint-label-justification {
          float: left;
          // width: 120px;
          // margin-top: 5px;
          // padding-left: 20px;
          // padding-right: 20px;
          // padding-top: 10px;
          // padding-bottom: 10px;
          width: 66px;
          margin-top: 1px;
          padding-right: 7.67px;
          padding-left: 7.67px;
          padding-top: 4.93px;
          padding-bottom: 4.08px;
          background-color: rgba(87, 87, 86, 0.1);
        }

        #documentPrint-justification {
          float: left;
          // margin-left: 10px;
          margin-left: 3px;
        }
      }

      #documentPrint-textRowInfo-data {
        float: left;
        width: 100%;
        // height: 120px;
        height: 59px;
        // border-bottom: 1px dotted $clr-txt-content;
        background-image: linear-gradient(to right, #a6a6a6 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        // background-size: 9px 2px;
        background-size: 4px 1px;
        background-repeat: repeat-x;

        .documentPrint-textRowInfo-column {
          width: 33% !important;
          float: left;
          // height: 55px;
          height: 23px;
          // padding-top: 10px;
          padding-top: 8px;

          label {
            float: left;
            // font-size: 16px !important;
            // padding-left: 20px;
            // padding-top: 10px;
            // padding-bottom: 10px;
            // width: 120px;
            font-size: 9px !important;
            padding-left: 7.67px;
            padding-right: 7.67px;
            padding-top: 4.93px;
            padding-bottom: 4.08px;
            width: 66px;
            background-color: rgba(87, 87, 86, 0.1);
          }

          > p {
            float: left;
            // padding-top: 10px;
            // margin-left: 10px;
            padding-top: 4.93px;
            margin-left: 3px;
          }

          p {
            // font-size: 16px !important;
            font-size: 9px !important;
            color: $clr-txt-content !important;
          }
        }
      }
    }

    .documentPrint-columnText-reduced {
      // width: calc(100% - 200px);
      width: calc(100% - 130px) !important;
      // margin-left: 20px;
      margin-left: 9.58px;
    }
  }
}
