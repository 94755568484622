@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.btn-action {
  display: block;
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  background-color: $primary;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  position: relative;
  pointer-events: visible;

  &.view {
    &:before {
      font-family: 'icomoon';
      font-size: 21px;
      content: '\e90c';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.edit {
    &:before {
      font-family: 'icomoon';
      font-size: 17px;
      content: '\e90a';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.remove {
    background-color: $alert;
    &:before {
      font-family: 'icomoon';
      // @include font-size(12px);
      font-size: 12px;
      content: '\e919';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.trash {
    background-color: $alert;
    &:before {
      font-family: 'icomoon';
      font-size: 17px;
      content: '\e9ac';
      position: absolute;
      top: 17px;
      left: 19px;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.archive {
    background-color: $alert;
    &:before {
      font-family: 'icomoon';
      font-size: 18px;
      content: '\e906';
      position: absolute;
      top: 18px;
      left: 19.5px;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.restore {
    &:before {
      font-family: 'icomoon';
      font-size: 18px;
      content: '\e907';
      position: absolute;
      top: 18px;
      left: 19.5px;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.resend {
    &:before {
      font-family: 'icomoon';
      font-size: 20px;
      content: '\e912';
      position: absolute;
      top: 17px;
      left: 18.5px;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &:disabled, &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &[hidden] {
    display: none;
  }
}