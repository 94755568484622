@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.table {
  thead th {
    text-align: left;
    border-top: none;
    border-bottom: 2px solid gray;
    font-weight: 450;
    color: $clr-txt-note;
    vertical-align: middle;
    &.v2-filter-orderDirection {
      cursor: pointer;

      img {
        margin-left: 15px;
      }
    }
  }

  tbody {
    tr {
      border-top: none;
      border-bottom: 1px solid $clr-txt-note;
      cursor: pointer;

      td {
        color: $clr-txt-content;
        vertical-align: middle;
      }

      &:hover {
        td {
          color: $primary;
        }
      }
    }
  }
}

i {
  width: 30px;
  height: 30px;
  top: 0;
  vertical-align: middle;

  &.icon-reading {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../../dashboard/img/icon-book-hover.svg) $primary-icon center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-editing {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../../dashboard/img/icon-edit-hover.svg) $primary-icon center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-comment {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../../dashboard/img/icon-comment-hover.svg) $primary-icon center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-published {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../../dashboard/img/icon-click-hover.svg) $primary-icon center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-checkmark {
    position: relative;
    &::before {
      content: '\ea10';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background-color: $primary-icon;
      color: $clr-v2-white;
      position: unset;
      margin-top: 0px;
      margin-left: 0px;
      text-align: center;
      line-height: 28px;

      display: inline-block;
    }
  }
}

.container-submenu {
  position: relative;
  .submenu {
    position: absolute;
    display: inline;
    margin-left: 30px;
    top: -20px;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    a {
      i {
        margin-right: 8px;
        width: 30px;
        height: 30px;
        border: 1px solid $primary-icon;
        padding: 6px;
        border-radius: 20px;
        background: $primary-icon;
        color: #fff;
      }
    }
  }
}
