@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.folder .containerTeam {
  #selectedUsers {
    display: flex;
    padding: 20px 0;
    width: 100%;
    flex-wrap: wrap;

    .userBox {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #F2F5F8;
      margin: 1.2%;
      padding: 10px;
      position: relative;
      box-sizing: border-box;
      max-width: 200px;
      background: #F2F5F8;

      &.boxAccessControls {
        max-width: 220px;
        display: flex;
        justify-content: center;
      }

      &.pending {
        &:before {
          content: '!';
          position: absolute;
          width: 17px;
          height: 17px;
          display: block;
          background: #c66;
          bottom: 35px;
          left: 60px;
          color: #fff;
          text-align: center;
          border-radius: 20px;
          font-weight: bold;
          @include font-size(12px);
        }
      }

      div {
        width: 100%;
        text-align: center;
      }

      img {
        border-radius: 90px;
        width: 75px;
        height: 75px;
      }

      .name {
        font-weight: bold;
        @include font-size(14px);
        padding-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .role {
        font-weight: normal;
        color: $primary;
        @include font-size(14px);
      }

      .removeUser {
        position: absolute;
        background: $alert-darker;
        right: -9px;
        top: -9px;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 1000px;
        width: 20px;
        height: 20px;
        border-radius: 15px;
        cursor: pointer;

        &:after,
        &:before {
          content: '';
          width: 2px;
          height: 10px;
          display: block;
          background: white;
          -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
          position: absolute;
        }

        &:after {
          transform: rotate(-35deg);
        }
      }
    }

    .accessControl {
      margin-left: auto;
      padding-right: unset;
      padding-top: 2px;

      select {
        border: 0;
        padding: 0 2px;
      }
    }
  }
}

.tree-view-layer {
  text-align: left !important;
}