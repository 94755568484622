.externalRegister {
  overflow-y: auto !important;
}

.registerForm {
  .input-feedback-passwordStrength {
    font-size: 16px !important;
    font-weight: bold;
    color: #777 !important;
    margin-top: 5px !important;

    .span-strong {
      color: #2fb214;
    }

    .span-weak {
      color: #c6201c;
    }
  }

  .input-feedback-passwordInstructions {
    font-size: 13px !important;
    color: #777777 !important;
  }
}
