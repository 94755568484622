@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-details-read {
  .card-body {
    display: flex;
    padding: 40px;
  }

  .v2-cardheader-filter {
    .v2-filter-notsearching {
      display: flex;
    }

    .v2-filter-searching {
      position: relative;

      .v2-filter-searching-nav.close {
        padding: 0;
        margin: 17px 9px 0 0;
        right: 0 !important;
      }
    }
  }

  .v2-cardheader-filter {
    .v2-select {
      .react-select__menu {
        width: 200px;
        top: 5px;
        left: -70px;
      }
    }
  }

  #highlights {
    width: 300px;
    padding-top: 25px;
    margin-bottom: 30px;
    text-align: center;

    div {
      width: 280px;
      height: 90px;
      background-color: #F2F5F8;
      margin: 10px;
      display: flex;
      flex-wrap: nowrap;
      padding: 10px;
      vertical-align: middle;

      span {
        &:nth-child(1) {
          width: 100px;
          font-size: 50px;
          font-weight: bold;
          border-right: 1px solid black;
        }

        &:nth-child(2) {
          width: 180px;
          padding: 15px;
          text-align: left;
        }
      }

      &:nth-child(1) {
        span {
          &:nth-child(1) {
            color: $clr-txt-title;
          }
        }
      }

      &:nth-child(2) {
        span {
          &:nth-child(1) {
            color: $primary;
          }
        }
      }

      &:nth-child(3) {
        span {
          &:nth-child(1) {
            color: $alert-darker;
          }
          &:nth-child(2) {
            padding-right: 35px;
          }
        }
      }
    }
  }

  .table-responsive {
    padding: 0 40px;
  }

  .table-details-read {
    padding: 20px;
    border-collapse: collapse;

    thead th {
      border-top: none;
      border-bottom: 2px solid gray;
      text-align: left;
      cursor: unset;
    }

    tbody tr {
      border-top: none;
      border-bottom: 1px solid $clr-txt-note;
      cursor: unset;
      &:hover > td {
        color: $clr-txt-content;
      }
    }

    th,
    td {
      vertical-align: middle;
      border-top: none;
      color: $clr-txt-content;
      padding: 0.4em 2em;
    }

    .user-info {
      display: flex;
      img {
        width: 50px;
        height: 50px;
        border-radius: 30px;
      }
      span {
        display: block;
        width: 250px;
        p {
          padding: 14px 0 14px 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .read {
      color: $primary-darker;
    }

    .pending-read {
      color: $alert-darker;
    }
  }

  @media screen and (max-width: 1070px) {
    .card-body {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
