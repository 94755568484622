@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.containerInfo {
  .includeMargin {
    text-align: justify;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .containerInfo-data {
    .list-folder-info-group {
      .list-folder-info-group-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        border-bottom: solid 1px;
        padding-bottom: 20px;

        img {
          border-radius: 90px;
          width: 45px;
          height: 45px;
        }

        .element-name {
          margin: 12px 0 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .containerPermission {
          width: 100%;
          margin-top: 8px;
          .element-permission {
            text-align: justify;
          }
        }
      }
    }
  }
}
