.docusign-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.docusign-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f5f8;
  width: 500px;
  border-radius: 8px;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6em;
}
.docusign-header-modal {
  font-weight: bold;
  background-color: #6cb6e3;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.docusign-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 500px;
  overflow: auto;
  padding-top: 8px;
}

.docusign-modal-text {
  display: block;
  margin: 10px;
}

.docusign-modal-text.align-center {
  text-align: center;
  padding: 20px;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.docusign-close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.docusign-cancel-btn {
  background-color: #ff3541;
  max-width: 200px;
  line-height: 17px;
  // margin-top: 0;
}
.docusign-continue {
  background-color: #6cb6e3;
  max-width: 200px;
  line-height: 17px;
  // margin-top: 0;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 10px 20px;
}

.docusign-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
  border: 4px solid transparent;
  border-top-color: #0060ae;
  animation: spin 1s linear infinite;
}

.btn-nav {
  text-transform: uppercase;
  font-weight: 700 !important;
  background-color: #6cb6e3;
  min-width: 160px !important;
  font-size: 12px !important;
  width: auto !important;
  height: 42px !important;
  border-radius: 500px !important;
  margin-top: 5px !important;
  padding: 0 30px 0 16px !important;
  margin-right: 10px !important;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top-color: #0060ae;
    border-bottom-color: #0060ae;
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #0060ae;
    border-bottom-color: #0060ae;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: #0060ae;
    border-bottom-color: transparent;
  }
}

@keyframes spinnerAnimation {
  to {
    transform: rotate(360deg);
  }
}

.table-docusign-modal {
  border-collapse: collapse;
  width: 100%;
}

.table-docusign-modal td {
  padding: 8px;
  padding-top: 0;
  text-align: left;
  vertical-align: top;
}

.table-docusign-modal td:first-child {
  width: 50%;
  display: flex;
  margin-left: 25px;
}

.table-docusign-modal td:not(:first-child) {
  padding-left: 16px;
}
