@import '../../../assets/scss/variables.scss';

.alt-label-v2 {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
  min-width: 250px;
}
.alt-label-v2 label {
  margin: 0 !important;
}

.v2-module {
  .v2-page-card {
    .card-body {
      form > div {
        margin-top: -20px;

        h3 {
          font-size: 18px;
          font-weight: bold;
          color: $clr-v2-red;
        }

        h5 {
          color: $clr-v2-soft-red;
        }
      }
    }

    .outer-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
    }

    .module-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
    }

    .disabled-container {
      opacity: 0.4;
    }

    .module-moduleList {
      flex-basis: auto;
      max-width: 542px;
      margin-top: 15px;
      border-color: $clr-v2-heavy-grey;
      border-right: 1px;
    }

    #nTotalUsers,
    #nTotalSpace,
    #cCoupon,
    #nPaidValue,
    #nPaidValue-old,
    #nPaidValue-new {
      width: 100%;
      border: 0;
      height: 100px;
      font-family: $Source;
      font-size: 38px;
      border-radius: 10px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
    }

    #nPaidValue-old {
      height: 50px;
      font-size: 16px;
      color: $clr-v2-heavier-grey !important;
    }

    .module-extras {
      display: flex;
      flex-basis: auto;
      max-width: 180px;
      border-color: $clr-v2-heavy-grey;
      border-right: 1px;
      background-color: $clr-v2-softest-grey;

      .module-extras-container {
        margin-left: 5%;
        margin-right: 5%;
        border-left: 2px dashed $clr-v2-heavy-grey;
        border-right: 2px dashed $clr-v2-heavy-grey;
      }

      .module-extras-users {
        padding-bottom: 50px;
        margin-top: 15px;
        padding-left: 10%;
        padding-right: 10%;

        .module-extras-users-container {
          position: relative;
          margin-top: 15px;
        }

        #nTotalUsers {
          padding-right: 46px;
          text-align: right;
          background-color: $clr-v2-softer-grey;
          color: $clr-v2-red;
        }

        .users-border {
          border-bottom: 1px solid $clr-v2-heavy-grey;
          margin-top: 40px;
        }

        .nTotalUsers-up {
          margin-top: 25px;
          top: 0;
          color: $clr-v2-heavier-grey;
        }

        .nTotalUsers-down {
          margin-bottom: 25px;
          bottom: 0;
          color: $clr-v2-heavier-grey;
        }
      }

      .nTotalSpace-up,
      .nTotalUsers-up,
      .nTotalSpace-down,
      .nTotalUsers-down {
        font-weight: bold;
        position: absolute;
        margin-right: 10px;
        right: 0;
        cursor: pointer;
      }

      .module-extras-space {
        position: relative;
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 40px;

        .module-extras-space-container {
          position: relative;
          margin-top: 15px;
        }

        #nTotalSpace {
          background-color: $clr-v2-soft-blue;
          color: $clr-v2-white;
          padding-right: 58%;
          text-align: right;
        }

        .nTotalSpace-text {
          color: $clr-v2-white;
          font-size: 32px;
          margin-left: -55%;
        }

        .nTotalSpace-up {
          margin-top: 25px;
          top: 0;
          color: $clr-v2-white;
        }

        .nTotalSpace-down {
          margin-bottom: 25px;
          bottom: 0;
          color: $clr-v2-white;
        }
      }
    }

    .module-value-container {
      flex-basis: 30%;
    }

    .module-value {
      margin-top: 15px;
      padding-left: 15px;

      #nPaidValue,
      #cCoupon,
      #nPaidValue-old,
      #nPaidValue-new {
        width: 100%;
        background-color: transparent;
        color: $clr-v2-heavier-grey;
        text-align: right;
      }

      .module-value-container {
        margin-top: 15px;
      }

      .module-value-input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        height: 5.625rem;

        margin-top: 15px;
        padding: 0.625rem;

        border: 0;
        border-radius: 10px;
        background-color: $clr-v2-softer-grey;

        .module-value-per-period-container {
          width: 3.5625rem;
          margin-left: 5px;
          margin-right: 10px;
        }

        .total-value {
          width: 100%;
        }
      }

      .module-value-installment-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .installments-number {
          margin-right: 5px;
        }
      }

      .module-value-emphasis {
        font-weight: 500;
        font-size: xx-large;
      }

      .module-value-month {
        font-size: 18px;
      }
    }

    .module-buttonstack {
      width: 100%;
      margin-left: 15px;
      clear: both;
      margin-top: 15px;

      .btn {
        margin-left: 0;
      }

      .module-button-change {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      // .alt-checkbox {
      //   float: none;
      // }
    }

    .module-changeplan-adding {
      color: $success;
      font-weight: bold;

      &::before {
        content: '+ ';
      }
    }

    .module-changeplan-removing {
      color: $alert;
      font-weight: bold;

      &::before {
        content: '- ';
      }
    }

    .module-value-reduction {
      margin-left: 0;
      padding-top: 20 px;
      color: $clr-v2-heavier-grey;
      font-size: 12px;
      display: block;
      margin-bottom: -30 px;
    }
  }

  .alt-checkbox {
    font-size: $fontsize-v2-small;
    color: $clr-v2-soft-red;
    font-family: $Source;
    float: left;
    margin-top: 15.14px;

    .togglecheck {
      background-color: $clr-v2-soft-red;
      width: 31px;
      height: 10px;
      top: -3px;
    }

    .togglecheck::after {
      width: 16px;
      height: 16px;
      border: 1px solid #e2e5e8;
      box-shadow: 1px #00000066;
      top: -4px;
    }

    .togglecheck.selected {
      background-color: $clr-v2-soft-blue;
    }

    .togglecheck.selected::after {
      background-color: $clr-v2-soft-blue;
      border-color: $clr-v2-soft-blue;
      left: 15px;
    }
  }

  .confirmation-alert {
    padding: 10px;
    text-align: left;
    color: $clr-v2-red;

    h5 {
      margin-top: 8px;
      color: $clr-v2-soft-red;
      font-weight: 450;
    }

    li,
    span,
    strong {
      margin-left: 2rem;
    }

    .total-value-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-button {
        display: inline-flex;
        align-items: center;
        background-color: $clr-v2-soft-blue;
        color: white;
        border: none;
        padding: 10px 10px 5px 0px;
        margin-top: 10px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;
      }
    
      .icon-button:hover {
          background-color: #0056b3;
      }
      
      .button-icon {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Source;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 10px;
        margin-bottom: 8px;
        font-size: 18px;
      }
      
      .button-text {
        font-size: 16px; /* Tamanho do texto */
      }

      .cupom-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $clr-v2-softer-grey;
        border-radius: 10px;

        #cCoupon {
          height: 60px;
          text-align: center;
          background: transparent;
          color: $clr-v2-soft-red;
          text-transform: uppercase;
        }
      }

      .total-value-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $clr-v2-softer-grey;
        border-radius: 10px;

        #nPaidValue,
        #nPaidValue-old,
        #nPaidValue-new {
          width: 100%;
          padding-left: 1.8rem;
          text-align: center;
          background: transparent;
          color: $clr-v2-soft-red;
        }

        .total-value-period-container {
          .total-value-period {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .module-dependency-alert {
    .module-dependency-table {
      text-align: left;

      tbody tr.incorrect-selection {
        font-weight: 500;

        td {
          color: $alert;
        }
      }

      tbody tr td {
        cursor: default;

        &:hover {
          color: unset;
        }
      }
    }

    .alert-description {
      p {
        margin-top: 0.625rem;
        text-align: justify;
      }
    }
  }
}

@media only screen and (max-width: 1310) {
  .module-moduleList {
    max-width: 80% !important;
  }

  .module-extras {
    max-width: 20% !important;
  }
}
