@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.attachments-container {
  display: flex;
  margin-top: 30px !important;
  align-items: flex-end;
  position: relative;
}

.attachments-wrapper {
  position: relative;
  margin-bottom: 70px;
  h2 {
    margin-bottom: 10px;
  }

  .attachments {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    a:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li {
      margin-right: 15px;
      padding: 6px 0;
    }
  }
}

.file-field-wrapper {
  min-width: 220px;
  background-color: $clr-v2-soft-grey;
  height: 50px;
  transition: $transition;
  border: 1px solid $clr-v2-soft-grey;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;

  .attach-button {
    vertical-align: middle;
    padding-top: 3px;
    i {
      float: left;
      @include font-size(28px);
      right: 25px;
      top: -2px;
      vertical-align: middle;

      &.icon-attachment {
        position: relative;
        &::before {
          content: '';
          width: 28px;
          height: 28px;
          background: url(../img/icon-editor-attachment.svg) transparent center center no-repeat !important;
          position: absolute;
          vertical-align: middle;
        }
      }
    }
  }
  &:hover {
    background-color: $clr-ui-bkg;
  }

  label {
    height: 0;
    margin: 0;
    width: 0;
  }
}

.attachment {
  background-color: #acd5f2;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  display: flex;

  a {
    padding: 8px 15px;
    &:first-child {
      width: 90%;
    }
  }
}
