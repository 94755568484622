.v2-norm-interactions {
  .v2-page-content {
    #v2-header {
      #v2-header-content {
        .v2-header-title-description-wrapper {
          h5 {
            width: 70%;
          }
        }
      }
    }
  }

  .interactions {
    background-image: url('../img/norminteraction-pixel.svg');
    background-repeat: no-repeat;
    background-size: 1px calc(100% - 105px);

    &:after {
      display: none;
    }

    li {
      .title-wrapper {
        .avatar img {
          background: white;
        }
      }
    }
  }
}
