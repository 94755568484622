@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-invite-users {
  .limit-box {
    border: 1px solid $alert-darker;
    background: transparent;
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;

    p,
    a {
      vertical-align: middle;
    }

    a {
      color: $primary;
    }
  }

  form {
    fieldset {
      border: 1px solid #F2F5F8;
      border-radius: 4px;
      margin-bottom: 25px;

      .btn-close {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $alert-darker;
        cursor: pointer;

        position: absolute;
        top: -10px;
        right: -10px;

        // opacity: $opacity;
        // transition: $transition;

        &:before {
          font-family: 'icomoon';
          font-size: 9px;
          content: '\e919';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      .form-head {
        background-color: #F2F5F8;
        width: 100%;
        height: 40px;
        padding: 10px 1.5%;
        legend {
          vertical-align: middle;
          height: 40px;
        }
      }

      .form-body {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px;

        label:not(.alt-checkbox) {
          display: flex;
          flex-wrap: wrap;
          margin: 0 1% 20px;
          color: $clr-txt-title;
          font-size: 15px;
          width: 100%;

          span:first-child,
          input {
            width: 100%;
          }

          > span {
            padding-left: 5px;
          }

          .required {
            color: $alert;
          }

          .selectCustom {
            width: 100%;
            input {
              height: 20px;
            }
          }

          &.split2 {
            width: 48%;
          }

          &.split3 {
            width: 31.33%;
          }
        }

        .alt-checkbox {
          font-size: $fontsize-v2-small;
          color: $clr-v2-soft-red;
          font-family: $Source;
          padding: 0 0 0 11px;
          .togglecheck {
            background-color: $clr-v2-soft-red;
            width: 31px;
            height: 10px;
            top: -3px;
          }

          .togglecheck::after {
            width: 16px;
            height: 16px;
            border: 1px solid #e2e5e8;
            box-shadow: 1px #00000066;
            top: -4px;
          }

          .togglecheck.selected {
            background-color: $clr-v2-soft-blue;
          }

          .togglecheck.selected::after {
            background-color: $clr-v2-soft-blue;
            border-color: $clr-v2-soft-blue;
            left: 15px;
          }
        }

        input[type='text'],
        input[type='date'],
        input[type='time'],
        input[type='file'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        select,
        textarea {
          display: block;
          width: 100%;
          height: 40px;
          padding: 0 15px;
          border: 1px solid #ccc;
          border-radius: $border-radius;
          margin-top: 5px;
          background-color: #fff;
          font-size: $sz-content;
          font-family: $Source;
          color: $clr-txt-content;
          background-color: #fff;
          transition: $transition;

          &:focus {
            border-color: $primary;
            outline: none;
          }
        }

        input[type='text'],
        input[type='file'],
        input[type='email'],
        input[type='number'],
        textarea {
          &:read-only {
            background-color: $clr-ui-lightbkg;
            border-color: #F2F5F8;
            color: $clr-txt-content;
            pointer-events: none;
          }
        }

        select.multiple,
        textarea {
          padding: 15px;
          height: 120px;
          resize: vertical;
        }
      }
    }
  }

  @media screen and (max-width: 1331px) {
    form fieldset {
      .form-head {
        padding: 10px 3%;
      }
    }
  }
}
