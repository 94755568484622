@import '../../../assets/scss/variables.scss';

.table-pagination {
  display: flex;
  justify-content: space-between;

  button {
    font-size: 13px;
    color: white;
    background-color: $clr-v2-soft-blue;
    height: 35px;
    line-height: 0;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50vh;

  img {
    width: 64px;
    height: 64px;
  }
}
