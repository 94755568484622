@import '../../../assets/scss/variables.scss';

.table-container-controler {
  overflow-x: auto;
  position: relative;
  width: 100%;
  background: #f8f9fa;
}

.table-container {
  overflow-x: auto;
  position: relative;
  width: 100%;
  background: #f8f9fa;
}

.left-overlay,
.right-overlay {
  position: absolute;
  top: 0;
  width: 30px;
  height: 100%;
  z-index: 10;
  cursor: pointer; /* Mostra que é clicável */
}

.left-overlay {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(32, 31, 31, 0.5));
}

.right-overlay {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(32, 31, 31, 0.5));
}

.report-table {
  width: 100%; /* Garante que a tabela ocupe 100% do container */
  table-layout: auto; /* Permite que a tabela ajuste a largura das colunas automaticamente */
}

.report-table th,
.report-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Impede a quebra de linha dentro das células */
}

/* Colunas específicas com quebra de linha */
.col-localization,
.col-description,
.col-redactor,
.col-reviewer,
.col-responsible {
  min-width: 150px; /* Define uma largura mínima para as colunas */
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; /* Quebra de linha para textos longos */ 
  white-space: normal; /* Permite a quebra de linha */
}

/* Outras colunas */
.col-version {
  min-width: 50px; /* Largura mínima específica para a coluna de versão */
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-status {
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-publication-date,
.col-due-date {
  min-width: 85px; /* Largura mínima específica para as colunas de datas */
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-table th.v2-filter-orderDirection img {
  margin-left: 5px;
}

@media screen and (max-width: 1280px) {
  .report-table th,
  .report-table td {
    font-size: 14px;
  }
}


.overlay-left,
.overlay-right {
  position: absolute; /* Posiciona a div sobreposta de forma absoluta em relação ao container */
  top: 35px; /* Adiciona 20px de margem na parte superior */
  width: 25px; /* Largura da div sobreposta */
  height: calc(100% - 45px); /* Altura da div menos 20px no topo e 15px na parte inferior */
  z-index: 100; /* Garante que a div fique sobre o conteúdo */
  cursor: pointer; /* Mostra que a div é clicável */
  display: none; /* Oculta as divs por padrão */
  justify-content: center; /* Centraliza o ícone horizontalmente */
  align-items: center; /* Centraliza o ícone verticalmente */
  font-size: 50px; /* Tamanho do ícone */
  color: white; /* Cor do ícone */
}

/* Definições de cor sólida preta semi-transparente */
.overlay-left {
  left: 0; /* Fixa à esquerda do container */
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay-right {
  right: 0; /* Fixa à direita do container */
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay-left:hover,
.overlay-right:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Muda a cor ao passar o mouse */
}

/* Exibe as divs quando a resolução for menor ou igual a 1280px */
@media screen and (max-width: 1280px) {
  .overlay-left,
  .overlay-right {
    display: flex; /* Exibe as divs apenas em resoluções menores ou iguais a 1280px */
  }
}


