@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .containerJustification {
    background-color: $clr-v2-soft-grey;
    color: #777;
    padding-left: 20px;
    border: 0;
    border-radius: 5px;
    word-break: break-word;
  }

  #editor-view-wrapper #editor-view,
  #editor-view-wrapper #print-view {
    width: 100%;
    padding: 0;
    font-family: $Source;
    font-weight: 100;
    margin-bottom: 100px;
  }

  .btn.back {
    float: none;
  }
}

@media screen and (max-width: 600px)
{
  .v2-page-card
  {
    .containerJustification,
    .form
    {
      margin-left: 5% !important;
      padding-right: 5%;
    }
  }
}
