@import '../../../assets/scss/variables.scss';

.report-table {
  thead th {
    text-align: left;
    border-top: none;
    border-bottom: 2px solid gray;
    font-weight: 450;
    color: $clr-txt-note;
    position: relative;
    cursor: pointer;

    &.v2-filter-orderDirection {
      img {
        position: absolute;
        margin-left: 15px;
      }
    }
  }

  tbody tr {
    border-top: none;
    border-bottom: 1px solid $clr-txt-note;

    td {
      color: $clr-txt-content;
    }
  }
}
