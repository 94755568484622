.content-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-api-section {
  p {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
    padding: 0;
    color: #3b454f;
    text-align: left;

    .link {
      color: #0066ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.new-token-button {
  margin: 0;
  height: 40px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #415364;
  color: #415364;
  stroke: #415364;
  cursor: pointer;

  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  transition: all 0.2s ease;
  &:hover {
    background-color: #415364;
    color: #ffffff;
    svg,
    path {
      stroke: #ffffff;
    }
  }
}

.loading {
  margin: 0 auto;
}

.applications-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: separate;

    th,
    td {
      border-top: 1px solid #ccd1d6;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #737d86;
      vertical-align: middle;
    }

    tbody td {
      color: #3b454f;
      padding: 12px 16px;
    }

    thead th:first-child {
      border-top-left-radius: 4px;
      border-left: 1px solid #ccd1d6;
    }

    thead th:last-child {
      border-top-right-radius: 4px;
      border-right: 1px solid #ccd1d6;
    }

    tbody td:first-child {
      border-left: 1px solid #ccd1d6;
    }

    tbody td:last-child {
      border-right: 1px solid #ccd1d6;
    }

    tbody tr:last-child {
      td {
        border-bottom: 1px solid #ccd1d6;

        &:first-child {
          border-bottom-left-radius: 4px;
        }
      }
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    .customer-application-token {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
    }

    .copy-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: #0066ff;
      cursor: pointer;
    }

    .revoke-button,
    .log-button {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      cursor: pointer;
    }

    .revoke-button {
      color: #ff3541;
      margin-right: 16px;
    }

    .log-button {
      color: #0066ff;
    }
  }
}

.new-application-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .application-input {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: #415364;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;

    input {
      width: 100%;
      border: 1px solid #6cb6e3;
      border-radius: 4px;
      padding: 14px 16px;
    }
  }

  .applications-permissions {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;
      color: #415364;
    }

    .permissions-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      padding: 16px;
      border: 1px solid #ccd1d6;
      border-radius: 4px;
      background-color: #fff;

      .permission {
        width: 100%;

        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;
          margin: 0 0 8px 0;
          padding: 0;
          color: #415364;
        }

        > div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 24px;
          flex-wrap: wrap;
        }
      }
    }
  }

  .new-application-texts-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    flex-direction: column;
  }

  .created-application-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    margin: 0;
    padding: 0;
    color: #415364;
  }

  .created-application-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #737d86;
    text-align: left;
  }

  .created-application-token {
    border: 1px solid #ccd1d6;
    border-radius: 4px;
    padding: 24px;
    background-color: #fff;
    text-align: center;
    word-break: break-all;
    color: #3b454f;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }

  .created-application-footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
}

.application-footer-container {
  width: 100%;
  border-top: 1px solid #ccd1d6;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

.application-button {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  padding: 10px 32px;
  max-height: 44px;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &.outline {
    background: transparent;
    border: 1px solid #53b7e8;
    color: #53b7e8;
  }
}
