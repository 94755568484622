@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-new-group {
  form {
    display: flex;
    flex-wrap: wrap;

    label {
      width: 100%;
      margin-bottom: 20px;
    }

    .required {
      color: $clr-social-gl;
    }

    input[type='text'],
    input[type='date'],
    input[type='time'],
    input[type='file'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select,
    textarea {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #ccc;
      border-radius: $border-radius;
      margin-top: 5px;
      background-color: #fff;
      font-size: $sz-content;
      font-family: $Source;
      color: $clr-txt-content;
      background-color: #fff;
      transition: $transition;

      &:focus {
        border-color: $primary;
        outline: none;
      }
    }

    input[type='text'],
    input[type='file'],
    input[type='email'],
    input[type='number'],
    textarea {
      &:read-only {
        background-color: $clr-ui-lightbkg;
        border-color: #F2F5F8;
        color: $clr-txt-content;
        pointer-events: none;
      }
    }

    select.multiple,
    textarea {
      padding: 15px;
      height: 120px;
      resize: vertical;
    }

    .container-group {
      display: block;
      position: relative;
      width: 100%;

      > label {
        margin: 0;
        padding: 0;
        width: 100%;
      }

      #searchResult {
        width: 100%;
        border: 1px solid $clr-ui-border;
        background: #fff;

        .notice {
          &.backup-notice {
            margin-bottom: 0;
          }
        }

        &.notfound {
          overflow: hidden;
        }

        margin-top: -1px;
        max-height: 220px;
        z-index: 22;
        overflow-y: scroll;

        li {
          display: inline-block;
          width: 100%;
          padding: 5px;
          height: 50px;

          &.pending {
            &:before {
              content: '!';
              position: absolute;
              width: 17px;
              height: 17px;
              display: block;
              background: #c66;
              bottom: 0px;
              color: #fff;
              text-align: center;
              border-radius: 20px;
              font-weight: bold;
              @include font-size(12px);
            }
          }

          img {
            border-radius: 90px;
            width: 45px;
            height: 45px;
            float: left;
            margin-right: 10px;
            float: left;
          }

          .btn {
            float: right;
            height: auto;
            @include font-size(14px);
            padding: 7px;
            line-height: initial;
            font-weight: normal;
          }

          .infos {
            @include font-size(16px);
            color: $primary;
            margin-top: 14px;
            width: 50%;
            float: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            b {
              font-weight: bold;
              color: $clr-txt-title;
            }
          }
        }
      }

      #selectedUsers {
        display: flex;
        padding: 20px 0;
        width: 100%;
        flex-wrap: wrap;

        .userBox {
          display: flex;
          flex-wrap: wrap;
          border: 1px solid #F2F5F8;
          margin: 1.2%;
          padding: 10px;
          position: relative;
          box-sizing: border-box;
          max-width: 200px;
          background: #F2F5F8;

          &.boxAccessControls {
            flex: 1 0 100%;
            max-width: 100%;
            display: flex;
          }

          &.pending {
            &:before {
              content: '!';
              position: absolute;
              width: 17px;
              height: 17px;
              display: block;
              background: #c66;
              bottom: 35px;
              left: 60px;
              color: #fff;
              text-align: center;
              border-radius: 20px;
              font-weight: bold;
              @include font-size(12px);
            }
          }

          div {
            width: 100%;
            text-align: center;
          }

          img {
            border-radius: 90px;
            width: 75px;
            height: 75px;
          }

          .name {
            font-weight: bold;
            @include font-size(14px);
            padding-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .role {
            font-weight: normal;
            color: $primary;
            @include font-size(14px);
          }

          .removeUser {
            position: absolute;
            background: $alert-darker;
            right: -9px;
            top: -9px;
            white-space: nowrap;
            overflow: hidden;
            text-indent: 1000px;
            width: 20px;
            height: 20px;
            border-radius: 15px;
            cursor: pointer;

            &:after,
            &:before {
              content: '';
              width: 2px;
              height: 10px;
              display: block;
              background: white;
              -webkit-transform: rotate(35deg);
              transform: rotate(35deg);
              left: 9px;
              top: 4px;
              position: absolute;
            }

            &:after {
              transform: rotate(-35deg);
            }
          }
        }

        .accessControl {
          margin-left: auto;
          padding-right: 10px;
          padding-top: 20px;

          // .icon_select_mate {
          //   position: absolute;
          //   top:20px;
          //   right: 2%;
          //   font-size: 16px;
          //     height: 22px;
          //   transition: all 275ms;
          // }

          // .select_mate {
          //   position: relative;
          //   float: left;
          //   min-width: 300px;
          //   width: 300px;
          //   min-height: 60px;
          //   font-family: 'Roboto';
          //   color: #777;
          //   font-weight: 300;
          //   background-color: #fff;
          //   box-shadow: 1px 2px 10px -2px rgba(0,0,0,0.3);
          //   border-radius: 3px;
          //   transition: all 375ms ease-in-out;
          // /* Oculto el elemento select */
          //   select {
          //   position: absolute;
          //   overflow: hidden;
          //   height: 0px;
          //   opacity: 0;
          //     z-index: -1;
          //   }
          // }

          select {
            border: 0;
          }

          // select{
          //   border:0;
          //   color: $clr-txt-content;
          //   font-family: $Source;
          //   @include font-size(14px);
          //   // -webkit-appearance: none;
          //   // -moz-appearance: none;
          //   text-indent: 1px;
          //   text-overflow: '';
          //   margin-left: 3px;
          //   padding: 5px 5px 5px 0 !important;

          //   option{
          //     padding: 5px !important;
          //   }

          // }
        }
      }

      .boxInvite {
        background: #fff;
        display: inline-block;
        width: 100%;

        .form {
          height: 80px;
          overflow: hidden;
        }

        .limit-box {
          text-align: center;
          margin: 10px;
          background: white;

          .btn {
            margin: 0;
          }
          a {
            color: $primary;
          }
        }

        > p {
          margin: 10px 10px 10px;
          font-weight: normal;
        }

        label {
          font-weight: normal;
        }

        .btn {
          height: auto;
          @include font-size(14px);
          padding: 10px;
          line-height: normal;
          line-height: initial;
          font-weight: normal;
          margin: 25px 0;
        }
      }

      .permission-group {
        display: flex;
        flex-wrap: wrap;

        h2 {
          margin: 0 0 2px 2px;
        }

        .permission-section {
          width: 100%;
          border: 1px solid #F2F5F8;
          border-radius: 3px;
          margin-bottom: 15px;

          .permission-section-head {
            background-color: #F2F5F8;
            height: 40px;
            padding: 10px 0 10px 15px;
            font-weight: bold;
          }

          .permission-section-body {
            display: flex;
            flex-wrap: wrap;

            .permission-section-body-row {
              border: 1px solid #F2F5F8;
              display: flex;
              flex-wrap: wrap;
              padding: 10px;
              width: 100%;

              .alt-checkbox {
                font-size: $fontsize-v2-small;
                color: $clr-v2-soft-red;
                font-family: $Source;
                min-width: 170px;
                width: auto;
                margin: 0;

                .togglecheck {
                  background-color: $clr-v2-soft-red;
                  width: 31px;
                  height: 10px;
                  top: -3px;
                }

                .togglecheck::after {
                  width: 16px;
                  height: 16px;
                  border: 1px solid #e2e5e8;
                  box-shadow: 1px #00000066;
                  top: -4px;
                }

                .togglecheck.selected {
                  background-color: $clr-v2-soft-blue;
                }

                .togglecheck.selected::after {
                  background-color: $clr-v2-soft-blue;
                  border-color: $clr-v2-soft-blue;
                  left: 15px;
                }
              }
            }
          }
        }
      }

      .box-permission {
        height: 150px;
        // Caso sejam adicionadas mais permissoes possam exceder o limite da box descomentar as linahs abaixo:
        // overflow-y: scroll;
        // overflow-x: hidden;

        li {
          width: 100%;
          display: inline-block;

          label {
            width: 100%;
            margin-bottom: 10px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
