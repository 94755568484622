.scrollable-form {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 6px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }
}

.scrollable-remove-alert {
    max-height: 8rem;
    line-height: 1.6rem;
    overflow-y: auto;
    padding-right: 6px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }
}
