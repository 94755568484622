// ***** Versão de impressão
* {
  -webkit-print-color-adjust: exact;
}
#print-view {
  #footer,
  header {
    display: block;
  }

  #container {
    margin-top: 0;
  }

  font-family: $Source;

  width: 100%;
  margin: 0 auto;
  font-size: 11pt;
  line-height: 1.5;
  color: #000;

  .floating-print-button {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $primary;
    color: #fff;
    border: 1px solid #fff;
    text-align: center;
    font-size: 27px;
    line-height: 63px;
    overflow: hidden;
  }

  h1 {
    color: #000;
  }

  p {
    line-height: 1.5;
  }

  b,
  strong {
    font-weight: $bold;
  }

  header {
    max-width: 21cm;
    margin-top: 1cm;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    page-break-after: always;
  }

  .info-company {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;
    width: 100%;

    p {
      text-align: center;
      font-size: 16pt;
      font-weight: $bold;
    }
  }

  .info-document {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    h1 {
      font-size: 20pt;
      margin: 0 0 0.3cm;
      font-weight: $bold;
    }

    .code {
      float: left;
      width: 20%;

      strong {
        display: block;
      }
    }

    .secao {
      float: left;
      width: 80%;

      p {
        font-size: 11pt;
        line-height: 1.5;
        color: #000;
        font-weight: $bold;
      }
    }
  }

  .functions {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;
      width: 33%;

      strong {
        display: block;
      }
    }
  }

  .dates {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;
      width: 50%;

      strong {
        display: block;
      }
    }
  }

  .version {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;

      &.numvers {
        width: 20%;
      }

      &.descvers {
        width: 80%;

        strong {
          display: block;
        }
      }
    }
  }

  .breadcrumbs {
    margin-bottom: 0px;
    @include font-size(14px);
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: inline-block;
      margin: 0 5px;
      color: $clr-txt-content;
      font-weight: $bold;
    }

    em {
      font-style: normal !important;
      text-decoration: none;
    }

    a:hover {
      color: $primary;
      text-decoration: underline;
    }

    strong {
      font-weight: $bold;
    }
  }
}

#editor-view-wrapper {
  * {
    font-family: 'Trebuchet MS';
    word-break: break-word;
  }
  h1 {
    margin: 20vh 0;
    text-align: center;
    color: #415464;
    font-size: 40px;
    text-transform: uppercase;
    p {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 20px;
    text-align: center;
    color: #415464;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: normal;
    p {
      font-weight: bold;
      font-size: 30px;
    }
  }

  blockquote {
    border-left: 2px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  .graph-box #graph {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    // box-shadow: 3px 3px 4px rgb(204 204 204 0.5);
    padding: 15px;
  }

  .graph-box #graph div {
    flex-basis: calc(20% - 25px);
    height: 350px;

    text-align: center;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .graph-box #graph div .graphic {
    display: block;
    width: 90px;
    height: 80%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .graph-box #graph div .graphic .bar {
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    background: orangered;
    display: block;
    height: 80%;
  }

  .graph-box #graph div p {
    display: block;
    clear: both;
    width: 100%;
    white-space: nowrap;
    font-size: 13px;
  }

  section.double {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  section.double div {
    /* flex: 1 2; */
    flex-basis: calc(50% - 20px);
  }
  section {
    &.center {
      text-align: center;
    }
  }
  section h2 {
    display: block;
    background: lightgrey;
    color: #415464;
    padding: 2px 10px;
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  body {
    overflow: auto !important;
  }

  #editor-view-wrapper,
  #editor-view-wrapper * {
    visibility: visible;
    &#print-view {
      display: none;
    }
  }

  #info-header {
    margin-top: 0 !important;
  }

  #editor-view-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 1px;
  }

  .content-box-report {
    h1 {
      font-size: 36px;
    }
  }

  .page {
    height: 100vh !important;
  }

  .pagination {
    margin: 0;
  }

  small {
    margin-bottom: 22%;
  }
}
