@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-document-details {
  .breadcrumbs-wrapper {
    overflow-wrap: break-word;
    margin-bottom: 40px;
    color: $clr-v2-red;
    font-size: 12px;
    margin: 0;
    .breadcrumbs {
      display: inline;
    }

    .inner-bread {
      display: inline-block;

      a {
        color: $clr-v2-red;
      }
    }

    span {
      display: inline-block;
      margin: 0 5px;
      font-weight: normal;
    }
  }
  .breadcrumbs-wrapper > :last-child {
    font-size: 14px;
    font-weight: 700;
    color: #415364;
    margin: 0;
  }
  .btn-menu {
    display: block;
    cursor: pointer;
    position: absolute;
    transform: scale(0.7) rotate(90deg);
    top: 2px;
    right: -18px;
    margin: 8px 30px 0 0;
    padding: 15px;

    span,
    &:before,
    &:after {
      display: block;
      float: left;
      content: ' ';
      width: 6px;
      height: 6px;
      background-color: $clr-v2-soft-red;
      border-radius: 50%;
      margin: 0 3px;
      transition: $transition;
    }

    &.active span,
    &.active:before,
    &.active:after,
    &:hover span,
    &:hover:before,
    &:hover:after {
      background-color: $clr-ui-border;
    }
  }

  .tools {
    display: none;
    max-width: 260px;
    margin: 0;
    padding: 20px 20px 0;
    list-style: none;

    position: absolute;
    top: 40px;
    right: 20px;

    border-radius: $border-radius;
    background-color: #fff;
    border: 1px solid#F2F5F8;
    box-shadow: $global-shadow;
    z-index: 50;

    li {
      margin: 0 0 20px;

      a,
      span {
        cursor: pointer;
        transition: $transition;

        i {
          display: inline-block;
          margin-right: 10px;
          font-size: 19px;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  #document-info {
    #document-header {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      .document-container-info {
        display: flex;
        width: 100%;
        .document-justification {
          margin: 5px 15px 5px 0;
          padding: 15px 25px;
          background-color: #f2f5f8;
          border: 1px solid #f2f5f8;
          border-radius: 4px;
          width: 395px;
          max-height: 192px;
          overflow: hidden;
          text-overflow: ellipsis;

          h5 {
            color: $clr-txt-title;
          }

          p {
            max-height: 145px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .document-roles {
          display: flex;
          .code {
            color: $clr-txt-title;
            font-weight: $bold;
          }

          & > .breadcrumbs {
            border-bottom: 1px solid $clr-ui-border;
            margin-bottom: 30px;
            font-size: $sz-content;

            p {
              display: inline-block;
              color: $clr-txt-content;
            }

            strong {
              color: $clr-txt-title;
              font-weight: $bold;
              display: inline-block;
            }
          }

          .role-avatar {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            float: left;
            text-align: center;
            border: 1px solid#F2F5F8;
            padding: 15px 5px;
            margin: 5px 10px;
            border-radius: 4px;
            background-color: #f2f5f8;
            width: 135px;

            .versionsLink {
              color: $primary;
              @include font-size(14px);
              font-weight: bold;
            }

            .titleVersion {
              display: block;
              width: 100%;
              @include font-size(50px);
              line-height: 1;
              font-weight: $bold;
              height: 52px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &.manual {
                @include font-size(30px);
                padding: 8px 10px;
              }
            }

            .header {
              display: block;
              width: 100%;
              margin-bottom: 10px;
              font-size: 13px;
              color: $clr-txt-title;
              min-width: 85px;
            }

            .avatar {
              display: inline-block;
              width: 50px;
              height: 50px;
              margin-bottom: 0;
              border-radius: 50%;
              overflow: hidden;

              img {
                display: block;
                width: 50px;
                height: 50px;
              }
            }

            .name {
              display: block;
              font-weight: bold;
              margin: 0;
              @include font-size(14px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .info {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .document-container-dates {
        padding: 20px;
        width: 100%;
        .dates {
          padding-bottom: 0px;
          // border-bottom: 1px solid $clr-ui-border;
          margin-bottom: 10px;
          overflow: hidden;
        }

        .requisites {
          display: flex;
          flex-wrap: wrap;

          span {
            cursor: pointer;
            background-color: #53b7e8;
            margin: 5px;
            color: white;
            border-radius: 1px;
            float: left;
            text-align: center;
            width: 150px;
            padding: 3px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .lower {
        padding-bottom: 30px;
        border-bottom: 1px solid $clr-ui-border;
        overflow: hidden;

        h2 {
          @include font-size(18px);
          font-weight: $bold;
          margin: 0 0 5px;
        }

        .left {
          float: left;
          width: 80%;

          .version {
            color: $clr-txt-title;
            @include font-size(18px);
          }
        }

        .right {
          float: left;
          width: 20%;

          p {
            strong {
              display: block;
              font-weight: $bold;
            }
          }

          .versions {
            display: block;
            max-width: 120px;
            height: 36px;
            padding-left: 40px;
            margin-bottom: 20px;
            background: url(../../../assets/img/ico-versoes.png) no-repeat center left;
            transition: $transition;

            &:hover {
              opacity: $opacity;
            }
          }

          .btn.small {
            min-width: 0;
            width: 100%;
            margin: 0 0 10px;
          }
        }
      }

      .btn-menu {
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
        padding: 10px 10px;

        span,
        &:before,
        &:after {
          display: block;
          float: left;
          content: ' ';
          width: 6px;
          height: 6px;
          background-color: $clr-txt-note;
          border-radius: 50%;
          margin: 0 3px;
          transition: $transition;
        }

        &.active span,
        &.active:before,
        &.active:after,
        &:hover span,
        &:hover:before,
        &:hover:after {
          background-color: $primary;
        }
      }

      .tools {
        display: none;
        max-width: 260px;
        margin: 0;
        padding: 20px 20px 0;
        list-style: none;

        position: absolute;
        top: 30px;
        right: 50px;

        border-radius: $border-radius;
        background-color: #fff;
        border: 1px solid#F2F5F8;
        box-shadow: $global-shadow;

        li {
          margin: 0 0 20px;

          a,
          span {
            cursor: pointer;
            transition: $transition;

            i {
              display: inline-block;
              margin-right: 10px;
              font-size: 19px;
            }

            &:hover {
              color: $primary;
            }
          }
        }
      }

      @media screen and (max-width: 1407px) {
        .document-container-info {
          .document-justification {
            padding: 15px 10px;
          }
          .document-roles .role-avatar {
            width: 125px;
          }
        }
      }

      @media screen and (max-width: 1300px) {
        .document-container-info {
          .document-justification {
            padding: 15px 10px;
          }
          .document-roles .role-avatar {
            width: 110px;
          }
        }
      }

      @media screen and (max-width: 1280px) {
        .document-container-info {
          .document-justification {
            padding: 15px 10px;
          }
          .document-roles .role-avatar {
            width: 100px;
          }
        }
      }

      @media screen and (max-width: 1100px) {
        .document-container-info {
          .document-justification {
            padding: 5px 10px;
          }

          .document-roles .role-avatar {
            width: 80px;
            padding: 5px;
            .header {
              display: inline-block;
              min-width: unset;
            }
          }
        }
      }

      @media screen and (max-width: 1023px) {
        .document-container-info {
          display: unset;
          .document-justification {
            padding: 15px 25px;
            width: 100%;
          }

          .document-roles {
            display: unset;
            .role-avatar {
              width: 100px;
              padding: 15px 5px;
              .header {
                display: inline-block;
                min-width: unset;
              }
            }
          }
        }
      }
    }

    #editor-view-wrapper {
      width: 100%;

      #document-text {
        width: 100%;

        p {
          margin-bottom: 10px;
          // color: $clr-v2-heavier-grey;
        }
      }
    }
  }

  #document-attachments {
    margin: 5px 15px 0 0;
    padding: 15px 25px 0 25px;
  }

  #document-attachments span {
    font-weight: 700;
    color: #415364;
  }

  #download-document {
    margin: 5px 15px 5px 0;
    padding: 0 25px;
  }

  .attachments-wrapper {
    margin-bottom: 30px;
  }
  .attachments {
    margin-top: 10px;
  }
  .download-file-attachment {
    padding-left: 8px;
    text-decoration: underline;
    font-weight: 700;
    color: #3b454f;
  }

  .download-file:hover {
    cursor: pointer;
  }

  .download-file-attachment {
    cursor: pointer;
  }

  .align {
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    bottom: 2px;
  }

  .clip-icon {
    font-size: 16px;
    margin-right: 10px;
  }

  .optionEvidence .evidencesAdd a {
    padding: 6px 10px 10px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100px;

    img {
      margin-top: 20px;
      width: 64px;
      height: 64px;
    }
  }
}
