@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .interactions {
    background-image: url('../img/documentinteraction-pixel.png');
    background-size: 1px;
    background-repeat: repeat-y;
    background-position-x: 50px;

    border: 0;

    .title-wrapper {
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 400px;
        color: $clr-v2-heavier-grey;
      }
    }

    li:before {
      content: none;
    }

    .message {
      padding: 10px 50px;
      margin-top: -7px;
      margin-bottom: 2px;
      pre {
        white-space: pre-wrap;
      }
    }

    .date {
      padding-left: 50px;
      font-weight: normal;
    }
  }
}
