@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?g6u7gh');
  src: url('../fonts/icomoon.eot?g6u7gh#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?g6u7gh') format('truetype'), url('../fonts/icomoon.woff?g6u7gh') format('woff'),
    url('../fonts/icomoon.svg?g6u7gh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-attachment:before {
  content: '\e900';
}
.icon-bell:before {
  content: '\e901';
}
.icon-calendar1:before {
  content: '\e902';
}
.icon-document-add:before {
  content: '\e903';
}
.icon-document-create:before {
  content: '\e904';
}
.icon-document-download:before {
  content: '\e905';
}
.icon-document-remove:before {
  content: '\e906';
}
.icon-document-upload:before {
  content: '\e907';
}
.icon-document:before {
  content: '\e908';
}
.icon-documents:before {
  content: '\e909';
}
.icon-edit:before {
  content: '\e90a';
}
.icon-eye-closed:before {
  content: '\e90b';
}
.icon-eye-opened:before {
  content: '\e90c';
}
.icon-folder-add:before {
  content: '\e90d';
}
.icon-folder-opened:before {
  content: '\e90e';
}
.icon-folder:before {
  content: '\e90f';
}
.icon-folders:before {
  content: '\e910';
}
.icon-image:before {
  content: '\e911';
}
.icon-invite-add:before {
  content: '\e912';
}
.icon-next:before {
  content: '\e913';
}
.icon-notebook:before {
  content: '\e914';
}
.icon-plus:before {
  content: '\e915';
}
.icon-prev:before {
  content: '\e916';
}
.icon-print:before {
  content: '\e917';
}
.icon-search:before {
  content: '\e918';
}
.icon-close:before {
  content: '\e919';
}
.icon-clock1:before {
  content: '\e91a';
}
.icon-duplicate:before {
  content: '\e91b';
}
.icon-move:before {
  content: '\e91c';
}
.icon-pdf-download:before {
  content: '\e91d';
}
.icon-home:before {
  content: '\e91e';
}
.icon-pencil:before {
  content: '\e91f';
}
.icon-images:before {
  content: '\e920';
}
.icon-play1:before {
  content: '\e921';
}
.icon-connection1:before {
  content: '\e922';
}
.icon-book:before {
  content: '\e923';
}
.icon-file-text:before {
  content: '\e924';
}
.icon-file-empty:before {
  content: '\e925';
}
.icon-files-empty:before {
  content: '\e926';
}
.icon-file-picture:before {
  content: '\e927';
}
.icon-file-zip:before {
  content: '\e92b';
}
.icon-copy:before {
  content: '\e92c';
}
.icon-stack:before {
  content: '\e92e';
}
.icon-folder-plus:before {
  content: '\e931';
}
.icon-folder-minus:before {
  content: '\e932';
}
.icon-price-tag:before {
  content: '\e935';
}
.icon-credit-card1:before {
  content: '\e93f';
}
.icon-phone:before {
  content: '\e942';
}
.icon-address-book:before {
  content: '\e944';
}
.icon-compass:before {
  content: '\e949';
}
.icon-clock:before {
  content: '\e94e';
}
.icon-alarm:before {
  content: '\e950';
}
.icon-calendar2:before {
  content: '\e953';
}
.icon-display:before {
  content: '\e956';
}
.icon-mobile:before {
  content: '\e958';
}
.icon-tv:before {
  content: '\e95b';
}
.icon-drawer:before {
  content: '\e95c';
}
.icon-drawer2:before {
  content: '\e95d';
}
.icon-download:before {
  content: '\e960';
}
.icon-upload:before {
  content: '\e961';
}
.icon-floppy-disk:before {
  content: '\e962';
}
.icon-undo2:before {
  content: '\e967';
}
.icon-redo2:before {
  content: '\e968';
}
.icon-bubble:before {
  content: '\e96b';
}
.icon-user:before {
  content: '\e971';
}
.icon-users:before {
  content: '\e972';
}
.icon-user-minus:before {
  content: '\e974';
}
.icon-binoculars:before {
  content: '\e985';
}
.icon-zoom-in:before {
  content: '\e987';
}
.icon-zoom-out:before {
  content: '\e988';
}
.icon-enlarge:before {
  content: '\e989';
}
.icon-shrink:before {
  content: '\e98a';
}
.icon-key:before {
  content: '\e98d';
}
.icon-lock1:before {
  content: '\e98f';
}
.icon-unlocked:before {
  content: '\e990';
}
.icon-cog:before {
  content: '\e994';
}
.icon-cogs:before {
  content: '\e995';
}
.icon-magic-wand:before {
  content: '\e997';
}
.icon-bin:before {
  content: '\e9ac';
}
.icon-clipboard1:before {
  content: '\e9b8';
}
.icon-cloud-download:before {
  content: '\e9c2';
}
.icon-cloud-upload:before {
  content: '\e9c3';
}
.icon-cloud-check:before {
  content: '\e9c4';
}
.icon-link:before {
  content: '\e9cb';
}
.icon-eye:before {
  content: '\e9ce';
}
.icon-eye-blocked:before {
  content: '\e9d1';
}
.icon-bookmark:before {
  content: '\e9d2';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-IcoMoon:before {
  content: '\eaea';
}
.icon-camera:before {
  content: '\e928';
}
.icon-play:before {
  content: '\e929';
}
.icon-film:before {
  content: '\e92a';
}
.icon-connection:before {
  content: '\e92d';
}
.icon-file-text2:before {
  content: '\e92f';
}
.icon-credit-card:before {
  content: '\e940';
}
.icon-map:before {
  content: '\e94b';
}
.icon-map2:before {
  content: '\e94c';
}
.icon-calendar:before {
  content: '\e954';
}
.icon-drive:before {
  content: '\e963';
}
.icon-database:before {
  content: '\e964';
}
.icon-bubbles:before {
  content: '\e96c';
}
.icon-bubbles2:before {
  content: '\e96d';
}
.icon-bubble2:before {
  content: '\e96e';
}
.icon-bubbles3:before {
  content: '\e96f';
}
.icon-bubbles4:before {
  content: '\e970';
}
.icon-speak {
  &:before {
    background: url(../img/ico-speak.svg) center center no-repeat;
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    transform: scale(1.8);
    background-size: contain;
  }
}
.icon-lock:before {
  content: '\e991';
}
.icon-clipboard:before {
  content: '\e9b9';
}
.icon-map-pin {
  background: url(../img/map-pin.svg) no-repeat left top;
}
.icon-clock2 {
  background: url(../img/clock.svg) no-repeat left top;
}
.icon-bin:before {
  content: '\e9ac';
}
.icon-drawer:before {
  content: '\e95c';
}

.icon-credit-card {
  background: url(../img/cc.svg) no-repeat left top;
}
