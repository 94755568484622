@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .containerJustification {
    background-color: $clr-v2-soft-grey;
    color: #777;
    padding-left: 20px;
    border: 0;
    border-radius: 5px;
  }

  #editor-view-wrapper #editor-view,
  #editor-view-wrapper #print-view {
    width: 100%;
    padding: 0;
    font-family: $Source;
    font-weight: 100;
    margin-bottom: 100px;
  }
  .btn.back {
    float: none;
  }

  #docusign-btn {
    background-color: #415364;
    max-width: 200px;
    line-height: 17px;
    // margin-top: 0;
  }
  .docusign-btn:disabled {
    opacity: 0.6;
    cursor: default;
  }

  #ver_nvaluereviewperiod,
  #ver_nvaluenotification {
    border-radius: 5px;
  }

  #ver_creviewperiod,
  #ver_cnotification {
    background-color: $clr-v2-soft-grey;
    border: 0;
    border-radius: 5px;
  }

  .containerTeam > label > b,
  .form > label {
    font-size: 14px;
  }

  .documentpublish-back {
    cursor: pointer;
  }

  .containerTeam {
    .user-message {
      color: red;
      display: block;
    }

    #searchResult {
      .list-group-item {
        &:before {
          bottom: 0 !important;
        }
      }
    }
    #selectedUsers {
      .userBox {
        max-width: 183px;
        height: 123px;
        text-align: center;
        background: #eee;
        border-radius: 5px;

        .name {
          padding-top: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .removeUser {
          background-color: rgb(186, 8, 21);
          border-color: rgb(186, 8, 21);

          &:before,
          &:after {
            background-color: #ffffff;
          }
        }

        img {
          float: none;
        }
      }

      .userBox.pending {
        &:before {
          bottom: auto;
          top: 65px;
          left: 55px;
        }
      }
    }
  }
  .detail-anchor{
      text-decoration: underline;
      color: #0066FF;
      margin-left: 10px;
      cursor: pointer;
  }
  .status-details p{
    color: #22272D;
  }
  .status-details:second-child{
    margin-top: 10px;
  }
}


