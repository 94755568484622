@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-audit-index {
  .v2-cardheader-filter .v2-filter-searching .v2-filter-searching-nav {
    // padding: 17.25px 0 0 0;
  }

  .v2-filter-searching-nav {
    right: 30px !important;
  }

  .v2-page-cardheader .v2-page-btn-goback {
    left: 0;
  }

  .v2-page-content .card-body {
    padding: 0;
  }

  .v2-cardheader-filter .v2-filter-notsearching {
    margin-right: 15px;
  }

  .v2-filter-notsearching-left {
    display: inline-block;
    margin-left: 60px;

    .containerBar {
      display: flex;
      padding: 0 20px;
      position: relative;
      width: 350px;

      .progressBar {
        border: 1px solid $card-borders;
        border-radius: 30px;
        background: #e6e6e6;
        width: 88%;
        display: block;
        margin: 13px auto;
        height: 30px;
        position: relative;
        overflow: hidden;

        .fill {
          height: 100%;
          width: 0%;
          background: $clr-v2-soft-blue;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .percent {
        color: $primary;
        margin: auto 0 auto 10px;
        line-height: 2.4;
        @include font-size(13px);
        min-width: 35px;
        text-align: left;
      }
    }
  }

  .right-menu {
    z-index: 2;
  }

  .alert-container {
    padding: 13px 13px 0 13px;
  }

  #table-emule {
    margin: 0;
    padding: 0 0 10px 0;
    position: relative;
    //z-index: 1;

    hr {
      margin: 0;
      height: 3px;
      background: $clr-v2-heavier-grey;
      border: none;
    }

    .head-table ul:first-of-type {
      margin-left: 30px;
      border: none;
    }

    .body-table {
      li {
        padding: 6px 0;
      }
    }
  }

  #table-emule.auditTable {
    .head-table {
      li.status {
        width: 19% !important;
      }

      li.auditor {
        width: 22% !important;
      }

      li.evidences {
        width: 20% !important;
      }
    }

    .body-table {
      ul {
        border: none;

        li {
          border: none;

          .requisite-item-list-title {
            div:first-child {
              display: flex;
              align-items: center;
              flex-basis: 60px;
              margin-left: 28px;
            }
          }

          .view-bt {
            border-right: 0;
            margin-right: 0;
            width: 30px;
            height: 30px;
          }

          .optionsContainerTable {
            padding-top: 0;
            width: 59%;

            .status {
              width: 32% !important;
            }

            .responsible {
              width: 31% !important;
            }

            .optionsHover {
              width: 21% !important;
              margin-right: 20px;

              > div {
                position: relative;
              }
            }
          }

          a.view-bt {
            padding: 0;
            line-height: 1.5;
          }

          span.optionsHover {
            margin-top: 0;
            a {
              width: 28px;
              height: 28px;
              padding: 3px;
              border: 1px solid gray;
              line-height: 1.3;
            }

            a.speak-audit:before {
              height: 30px;
              background: #afaba5;
            }

            a.speak-audit {
              background: none;
              border: none;

              .icon-speak:before {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.79' height='21.585' viewBox='0 0 28.79 21.585'%3E%3Cg id='Grupo_807' data-name='Grupo 807' transform='translate(-2418.684 -7089.415)'%3E%3Cpath id='Caminho_821' data-name='Caminho 821' d='M2430.758,7107.722c0,.935-.01,1.77,0,2.6.01.619-.324.785-.832.6-2.515-.89-5.026-1.792-7.522-2.733-.2-.076-.36-.465-.4-.728a2.478,2.478,0,0,1,.189-.776,3.993,3.993,0,0,0-1.106-4.007,7.257,7.257,0,0,1-2.222-7.073,7.393,7.393,0,0,1,6.824-5.918,8.508,8.508,0,0,1,5.363,1.145,5.821,5.821,0,0,1,2.912,5.192,6.134,6.134,0,0,0,.782,3.235,13.263,13.263,0,0,1,.655,1.389c.34.791.051,1.261-.8,1.313-.265.016-.532,0-.876,0a2.066,2.066,0,0,0,.062.434c.6,1.405-.055,2.815-.011,4.224.008.243-.474.636-.795.728A18.209,18.209,0,0,1,2430.758,7107.722Z' fill='%23777' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cpath id='Caminho_822' data-name='Caminho 822' d='M2441.756,7089.426c1.054,0,2.108-.019,3.161.005a2.435,2.435,0,0,1,2.544,2.552c.016,1.161.019,2.323,0,3.485a2.443,2.443,0,0,1-2.49,2.539q-3.2.062-6.4,0a2.445,2.445,0,0,1-2.5-2.529c-.021-1.189-.024-2.378,0-3.566a2.427,2.427,0,0,1,2.451-2.483C2439.594,7089.4,2440.676,7089.425,2441.756,7089.426Z' fill='%23777' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cg id='Grupo_806' data-name='Grupo 806'%3E%3Ccircle id='Elipse_247' data-name='Elipse 247' cx='0.486' cy='0.486' r='0.886' transform='translate(2438.735 7093.24)' fill='%23FFF'/%3E%3Ccircle id='Elipse_248' data-name='Elipse 248' cx='0.486' cy='0.486' r='0.886' transform='translate(2441.735 7093.24)' fill='%23FFF'/%3E%3Ccircle id='Elipse_249' data-name='Elipse 249' cx='0.486' cy='0.486' r='0.886' transform='translate(2444.735 7093.24)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
                  center center no-repeat;
                background-size: contain;
              }
            }

            .menuContextEvid {
              right: 0;

              li {
                padding: 10px;
              }
            }
          }

          span.status .icon-next {
            width: 18px;
            height: 20px;
            padding: 4px;
            font-size: 10px;
            font-weight: normal;
            transform: rotate(90deg);
          }

          .status:before {
            border: none;
          }
        }

        li.all-finished {
          .view-bt i {
            background: #415364;
          }

          span.optionsHover {
            a {
              border: none;
            }

            a.speak-audit:before {
              height: 30px;
              background: white;
            }

            a.speak-audit .icon-speak:before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.79' height='21.585' viewBox='0 0 28.79 21.585'%3E%3Cg id='Grupo_807' data-name='Grupo 807' transform='translate(-2418.684 -7089.415)'%3E%3Cpath id='Caminho_821' data-name='Caminho 821' d='M2430.758,7107.722c0,.935-.01,1.77,0,2.6.01.619-.324.785-.832.6-2.515-.89-5.026-1.792-7.522-2.733-.2-.076-.36-.465-.4-.728a2.478,2.478,0,0,1,.189-.776,3.993,3.993,0,0,0-1.106-4.007,7.257,7.257,0,0,1-2.222-7.073,7.393,7.393,0,0,1,6.824-5.918,8.508,8.508,0,0,1,5.363,1.145,5.821,5.821,0,0,1,2.912,5.192,6.134,6.134,0,0,0,.782,3.235,13.263,13.263,0,0,1,.655,1.389c.34.791.051,1.261-.8,1.313-.265.016-.532,0-.876,0a2.066,2.066,0,0,0,.062.434c.6,1.405-.055,2.815-.011,4.224.008.243-.474.636-.795.728A18.209,18.209,0,0,1,2430.758,7107.722Z' fill='%23FFF' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cpath id='Caminho_822' data-name='Caminho 822' d='M2441.756,7089.426c1.054,0,2.108-.019,3.161.005a2.435,2.435,0,0,1,2.544,2.552c.016,1.161.019,2.323,0,3.485a2.443,2.443,0,0,1-2.49,2.539q-3.2.062-6.4,0a2.445,2.445,0,0,1-2.5-2.529c-.021-1.189-.024-2.378,0-3.566a2.427,2.427,0,0,1,2.451-2.483C2439.594,7089.4,2440.676,7089.425,2441.756,7089.426Z' fill='%23FFF' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cg id='Grupo_806' data-name='Grupo 806'%3E%3Ccircle id='Elipse_247' data-name='Elipse 247' cx='0.486' cy='0.486' r='0.886' transform='translate(2438.735 7093.24)' fill='%236CB6E3'/%3E%3Ccircle id='Elipse_248' data-name='Elipse 248' cx='0.486' cy='0.486' r='0.886' transform='translate(2441.735 7093.24)' fill='%236CB6E3'/%3E%3Ccircle id='Elipse_249' data-name='Elipse 249' cx='0.486' cy='0.486' r='0.886' transform='translate(2444.735 7093.24)' fill='%236CB6E3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
                center center no-repeat;
              background-size: contain;
            }
          }
        }

        li.open > .row-table > span .requisite-item-list-title .icon-next {
          transform: rotate(90deg);
        }
      }
    }

    .body-table > ul:first-of-type > li:first-of-type > .row-table {
      border: none;
    }

    .body-table > ul:first-of-type {
      border-bottom: 1px solid #afaba5;
    }

    .row-table {
      width: 100%;
      min-height: 45px;
      border-top: 1px solid #fff;
      display: flex;
      padding: 5px 0 5px 0;

      &.focus {
        border: 2px solid #444;
        border-radius: 3px;
      }
    }
  }

  #table-emule.lastOption {
    .body-table {
      ul {
        li {
          span:last-child {
            width: 25px;
            max-width: unset;
            min-width: unset;
          }

          span:first-child {
            width: 40% !important;
          }

          span {
            .icon-next {
              padding: 9px;
            }
          }

          span.optionsHover {
            a:after {
              bottom: -15px;
              left: -25px;
              padding: 2px 6px;
              height: 1rem;
            }
          }

          .subLevel {
            margin-top: unset;
            margin-bottom: unset;

            li {
              border: none;
            }
          }
        }

        li.non-finished > .row-table {
          border-top: 1px solid #afaba5;
          background: white;
          color: $clr-v2-heavier-grey;
        }

        li.non-finished > .row-table > .optionsContainerTable .optionsHover {
          a {
            border: 1px solid $clr-v2-heavier-grey;
          }

          a.speak-audit:before {
            background: #afaba5;
          }

          a.speak-audit {
            background: none;
            border: none;

            .icon-speak:before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.79' height='21.585' viewBox='0 0 28.79 21.585'%3E%3Cg id='Grupo_807' data-name='Grupo 807' transform='translate(-2418.684 -7089.415)'%3E%3Cpath id='Caminho_821' data-name='Caminho 821' d='M2430.758,7107.722c0,.935-.01,1.77,0,2.6.01.619-.324.785-.832.6-2.515-.89-5.026-1.792-7.522-2.733-.2-.076-.36-.465-.4-.728a2.478,2.478,0,0,1,.189-.776,3.993,3.993,0,0,0-1.106-4.007,7.257,7.257,0,0,1-2.222-7.073,7.393,7.393,0,0,1,6.824-5.918,8.508,8.508,0,0,1,5.363,1.145,5.821,5.821,0,0,1,2.912,5.192,6.134,6.134,0,0,0,.782,3.235,13.263,13.263,0,0,1,.655,1.389c.34.791.051,1.261-.8,1.313-.265.016-.532,0-.876,0a2.066,2.066,0,0,0,.062.434c.6,1.405-.055,2.815-.011,4.224.008.243-.474.636-.795.728A18.209,18.209,0,0,1,2430.758,7107.722Z' fill='%23777' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cpath id='Caminho_822' data-name='Caminho 822' d='M2441.756,7089.426c1.054,0,2.108-.019,3.161.005a2.435,2.435,0,0,1,2.544,2.552c.016,1.161.019,2.323,0,3.485a2.443,2.443,0,0,1-2.49,2.539q-3.2.062-6.4,0a2.445,2.445,0,0,1-2.5-2.529c-.021-1.189-.024-2.378,0-3.566a2.427,2.427,0,0,1,2.451-2.483C2439.594,7089.4,2440.676,7089.425,2441.756,7089.426Z' fill='%23777' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3Cg id='Grupo_806' data-name='Grupo 806'%3E%3Ccircle id='Elipse_247' data-name='Elipse 247' cx='0.486' cy='0.486' r='0.886' transform='translate(2438.735 7093.24)' fill='%23FFF'/%3E%3Ccircle id='Elipse_248' data-name='Elipse 248' cx='0.486' cy='0.486' r='0.886' transform='translate(2441.735 7093.24)' fill='%23FFF'/%3E%3Ccircle id='Elipse_249' data-name='Elipse 249' cx='0.486' cy='0.486' r='0.886' transform='translate(2444.735 7093.24)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
                center center no-repeat;
              background-size: contain;
            }
          }
        }

        li.all-finished {
          background: #6cb6e3;
          color: #fff;
        }
      }

      ul li {
        padding: 0;
      }
    }
  }

  .requisite-item-list-title {
    align-items: center;
  }

  .changeState {
    color: $clr-v2-heavier-grey;
  }

  #section-desc-audit-index {
    min-width: unset;
    padding: 10px 15px;
    position: relative;
    z-index: 0;

    > p {
      margin-bottom: 20px;
      color: #afaba5;
    }

    .document-editor__toolbar.requisite-editor {
      display: inline-block;
      width: 100%;
      border-bottom: 0;
      margin-bottom: 0;
    }

    .titlefinalnoteincluded {
      font-weight: bold;
    }

    .editor-view,
    #editor-view {
      border: 1px solid #ccc;
      height: 150px;
      overflow: auto;
      margin-top: -1px;
    }

    .ck-rounded-corners .ck.ck-toolbar,
    .ck.ck-toolbar.ck-rounded-corners {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .comment-box {
    width: 99%;
  }
}

@media only screen and (max-width: 1024px) {
  .v2-audit-index {
    #table-emule.auditTable .body-table ul li .optionsContainerTable {
      .optionsHover {
        width: 185px !important;
        margin-right: 5px;
      }

      span {
        display: unset;
      }

      .status {
        width: 140px !important;
      }

      .responsible {
        width: 135px !important;
      }
    }

    #table-emule.lastOption {
      .head-table ul li:first-child {
        width: 31% !important;
      }

      .body-table ul li span:first-child {
        width: 36% !important;
      }
    }

    #table-emule.auditTable {
      .head-table {
        li.status {
          width: 140px !important;
        }

        li.auditor {
          width: 135px !important;
        }

        li.evidences {
          width: 175px !important;
        }
      }
    }
  }
}
