@import '../../../assets/scss/variables.scss';

.v2-page {
  display: flex;

  .centered-loading {
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 42%;
  }
}

#container {
  width: 100%;
  font-family: $Source;
  background-color: $clr-v2-heavy-grey;

  .plus {
    display: block;
    width: 20px;
    height: 20px;

    &:before {
      font-family: 'icomoon';
      font-size: 9px;
      content: '\e903';
      transform: translate(-50%, -50%);
    }
  }
}

.v2-page-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 102vh;
  width: 100%;
  overflow: scroll;
}



.v2-page-content {
  float: left;
  padding-left: 91px;
  padding-right: 93px;
  background-color: #dbdfe5;
  width: calc(100% - 0px);
  // height: 100vh;
  // overflow: auto;
  transition: width 0.5s;
  padding: 12px 32px 32px 32px;

  .card-body {
    padding: 25px;

    .centered-text {
      text-align: center;
    }
  }
  /*
  #v2-header,
  .v2-page-card {
    max-width: $central-width;
  }*/
}

.set-footer {
  height: 95vh !important;
}

.v2-page-content-short {
  margin-left: 92px;
  margin-right: 92px;
}

.v2-page-card {
  // clear: both;
  background-color: $clr-v2-white;
  // border: 1px solid $clr-v2-white;
  border-radius: 5px;
  min-height: calc(100vh - 176px);
  // overflow: auto;
  // margin-top: 1%;
  width: 100%;
  // position: relative;
}

.v2-navitem {
  cursor: pointer;
}

.menu-expanded {
  .v2-page-content {
    width: calc(100% - 0px);
  }
}

.sidemenu-names {
  .v2-sidemenu-name {
    display: inline-block;
  }
}

.v2-sidemenu-name {
  display: none;
}

.right-menu {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 180px;
  z-index: 1;

  .btn-menu {
    transform: rotate(90deg);

    &:before,
    span,
    &:after {
      width: 4px;
      height: 4px;
      margin: 0 2px;
      background-color: $clr-v2-soft-red;
    }

    &:before:hover,
    span:hover,
    &:after:hover {
      background-color: $clr-v2-heavy-grey;
    }
  }
}

.button-stack {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-add-invite,
  .btn-add {
    color: $primary;
    border: 1px solid $primary;
    background-color: transparent;
    font-weight: 500;
    &:hover {
      color: white;
      background-color: $primary;
      opacity: 1;
    }
  }

  .btn-cancel {
    color: black;
    border: 1px solid black;
    background-color: transparent;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: $back;
      border-color: $back;
      opacity: 1;
    }
  }
}

.v2-top-header {
  display: none;
}

@media screen and (max-width: 600px) {
  .v2-top-header {
    display: flex;
    width: 100%;
    background-color: #6cb6e3;
    // padding: 40px 32px 0;
  }
}

@media screen and (min-width: 1660px) {
  // .v2-page-content {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   align-items: center;
  //   position: relative;

  //   .v2-page-card {
  //     // top: 125px;
  //     width: 100%;
  //   }
  // }
}
