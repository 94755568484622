@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.card-body:last-child {
  margin-bottom: 100px;
}

.v2-filter-searching-nav {
  right: 35px !important;
}

.archived-folder {
  cursor: pointer;
  border: unset !important;
}

.sweet-alert.modal-edit.modal-document-preview {
  width: 1200px !important;
}

@media (max-width: 1200px) {
  .sweet-alert.modal-edit.modal-document-preview {
    width: 100% !important;
  }
}

.v2-document {
  .btn-content-desktop {
    display: block;
  }

  .btn-folder-new,
  .btn-request-new {
    background-image: url('../../default-v2/img/icon-plus-blue.svg') !important;
    background-repeat: no-repeat;
    background-position: 30px center;
  }

  .btn-document-new,
  .btn-folder-new,
  .btn-request-new {
    padding-left: 45px;
    padding-right: 30px;
  }

  .v2-filter-notsearching {
    .btn,
    .alt-checkbox {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .v2-select .react-select__menu {
      width: 190px;
      margin-top: 0;
      margin-left: 0;
      z-index: 10;
    }

    .right-menu {
      top: -2px;

      i {
        color: $primary;
      }

      .submenu {
        display: block;
        min-width: 180px;
        padding: 10px 20px 5px;
        border: 1px solid #f2f5f8;
        margin-top: 50px;
        background-color: $clr-v2-white;
      }
    }

    @media (min-width: 481px) {
      display: inline-flex;
      justify-content: flex-end;
      width: 95%;
    }
  }

  .red-border {
    border: 1px solid red !important;
  }

  .block-title-v2 {
    width: 70px;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .input-select input {
    margin-top: 5px;
    width: 100%;
    height: 40px;
    padding-left: 14px;
    // padding: 14px, 8px, 14px, 16px;
    background: #ffffff;
    border: 1px solid #acb4ba;
    border-radius: 4px;
    // color: #ACB4BA;
    // font-weight: 400;
    //   font-size: 14px;
    //   line-height: 150%;
  }

  // .v2-cardheader-filter.folderindex-filter-withOrder {
  //   .v2-filter-notsearching {
  //     width: 820px;
  //   }
  // }

  // .v2-cardheader-filter.folderindex-filter-withoutOrder {
  //   .v2-filter-notsearching {
  //     width: 690px;
  //   }
  // }

  .filterList {
    border-width: 0px 0px 2px 0px;
    border-color: $clr-v2-heavier-grey;
    border-style: solid;
    padding: 9px;

    .arrow {
      display: inline-block;
      width: 12px;
      height: 13px;
      // background: red;
      // border:1px solid red;
      text-align: right;

      i {
        @include font-size(9px);
        transform: rotate(-90deg);
        display: inline-block;
      }

      &.asc {
        i {
          transform: rotate(90deg);
        }
      }
    }

    > span {
      cursor: pointer;
      &.active {
        font-weight: 500;
      }

      .folderindex-list-icon {
        margin-left: 15px;
      }
    }

    .filterByName {
      width: 35% !important;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      @include font-size(14px);
    }

    .filterByName-v2 {
      width: 30% !important;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      @include font-size(14px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .alt-checkbox-v2 {
      width: 3% !important;
      display: inline-block;
    }

    .filterByResponsible-v2 {
      width: 20%;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      word-break: break-word;
      @include font-size(14px);
    }
    .filterByEnvelope {
      width: 15% !important;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      @include font-size(14px);
    }
    .filterByPublish {
      width: 15%;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      word-break: break-word;
      
      
      @include font-size(14px);
    }

    .filterByDue {
      width: 15%;
      display: inline-block;
      color: $clr-v2-heavier-grey;
      word-break: break-word;
      @include font-size(14px);
    }

    .checkbox {
      width: 15%;
      // display: inline-block;
      // color: $clr-v2-heavier-grey;
      // word-break: break-word;
      // @include font-size(14px);
    }
  }

  .folder-submenu,
  .document-submenu {
    position: absolute;
    right: 0;
    top: 12px;
    margin-right: 2px;
    background: white;
    width: 70px;
    opacity: 0;
    overflow: visible;

    .submenu .insidespan {
      padding: 5px;
      margin-right: 5px;
      display: inline-block;
      font-size: 15px;
      font-size: 0.9375rem;
      padding: 15px;
      border-radius: 30px;
      background: #53b7e8;
      color: #eee;
      text-align: center;
      height: 28px;
      width: 28px;
      padding: 5px;
      text-align: center;
      font-weight: bold;
      position: relative;
      z-index: 10;
    }

    &:hover {
      opacity: 1;
    }
  }

  .document-submenu,
  .folder-submenu {
    top: unset;
    left: 0;
    width: 20%;
    margin-right: 0;
    .submenu {
      float: right;
    }
  }

  .submenu-view {
    top: 5px;
    margin: 1px 0;
    &:hover {
      opacity: 1;
      .submenu .insidespan {
        background: #53b7e8;
      }
    }
  }

  .folder-list {
    overflow: hidden;

    li {
      float: left;
      width: 23.5%;
      margin: 1% 2% 1% 0;

      a,
      .archived-folder {
        display: block;
        width: 100%;
        height: 60px;
        border-radius: 5px;
        border: 1px solid $clr-ui-border;
        position: relative;
        transition: $transition;

        &:hover {
          opacity: $opacity;
        }

        &:after {
          display: block;
          content: ' ';
          width: 70px;
          height: 15px;
          // background: url(../img/folder-tip.png) no-repeat center center;
          position: absolute;
          top: -15px;
          left: -1px;
        }

        .folders-files {
          position: absolute;
          top: 10px;
          left: 10px;
          @include font-size(18px);
          line-height: 24px;
          max-width: 93%;

          .folderMnemonic {
            color: $clr-ui-border;
            font-size: 0.9rem;
          }

          span {
            display: block;
            @include font-size(14px);
            line-height: 1.4;
            max-width: 185px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            i {
              float: left;
              color: $primary;
              @include font-size(16px);
              margin-right: 10px;
            }
          }
        }

        strong {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 8px 10px;
          background-color: #f2f5f8;
          border-top: 1px solid $clr-ui-border;
          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &.list-view {
      border-bottom: 1px solid $clr-ui-border;

      li {
        width: 100%;
        margin: 0;
        padding: 0;

        a,
        .archived-folder {
          border-radius: 0;
          overflow: hidden;
          height: auto;
          border: 1px solid $clr-ui-border;
          border-right: 0;
          border-left: 0;
          border-bottom: 0;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 15px;
          padding-bottom: 15px;

          &:after {
            display: none;
          }

          &:hover {
            background-color: $clr-ui-lightbkg;
            opacity: 1;

            strong {
              color: $primary;
            }
          }

          .folders-files {
            position: static;
            width: 100%;
            float: left;
            line-height: 17px;

            span {
              display: inline-block;
              width: 30%;
              height: 17px;
              margin: 0;
              font-size: $sz-note;

              &.folderMnemonic {
                margin-left: 7%;
              }

              &.folderName {
                width: 43.5%;
                max-width: unset;
              }

              i {
                font-size: 17px;
              }
            }
          }

          strong {
            position: static;
            float: left;
            width: 40%;
            padding: 0;
            background-color: transparent;
            border-top: 0;
            font-size: $sz-content;
            color: $clr-v2-heavier-grey;
            transition: $transition;
          }
        }
      }
    }
  }

  .modal-edit {
    label {
      font-size: inherit !important;
    }
  }

  .card-body .centered-text {
    margin-top: 15px;
    img {
      width: 35px;
    }
  }
}

.v2-document-grid,
.accessedRecently {
  li {
    .folders-files {
      span {
        padding-top: 15px !important;
      }
    }
  }
  .folder-list {
    & > li {
      .right-menu {
        div {
          position: absolute;
          right: 15px;
          top: 10px;

          .submenu {
            margin-top: 20px;

            li {
              width: 180px !important;
              height: 40px !important;
              margin: 0 !important;
              float: none;
            }

            i {
              color: #53b7e8;
            }

            li * {
              @include font-size(14px);
            }
          }
        }
        .btn-menu {
          right: -14px;
          z-index: 1;
        }
      }
    }
  }
  .folder-list,
  .document-list {
    overflow: hidden;

    > li {
      float: left;
      width: 250px;
      height: 100px;
      margin: 1% 2% 1% 0;
      border-radius: 2px;
      border: 1px solid #dbdbdb;
      padding: 0;

      a {
        border: 0;
      }

      .folderindex-grid-folderName {
        text-align: center;
        height: 48px;
        border: 0;
        border-radius: 0;
        width: 100%;
        transition: $transition;
        background-color: $clr-v2-soft-grey;
        float: left;
        max-width: none !important;
        padding-top: 10px;
      }

      .folderindex-grid-folderIcon {
        width: 100%;
        float: left;
        height: 50px;
        padding-top: 13px;
        text-align: center;
        display: block;
        border: 0 !important;
      }

      .folderindex-grid-documentIcon {
        width: 100%;
        height: 50px;
        padding-top: 13px;
        text-align: center;
      }

      .folderindex-grid-documentTitle {
        height: 28px;
        padding-top: 10px;
        text-align: center;
        background-color: #f2f5f8;
      }

      .folderindex-grid-due {
        width: 100%;
        height: 20px;
        text-align: center;
        background-color: $clr-v2-soft-grey;

        h3 {
          text-align: center;
          font-size: 11px;
          min-width: 100%;
        }
      }

      .folders-files {
        width: 100% !important;
        position: static !important;
      }
    }
  }
}

.accessedRecently {
  .folderindex-grid-documentTitle {
    height: 48px !important;
    padding-top: 15px !important;
  }
}

// tem algum css genérico com prioridade muito alta que
// afeta esse elemento, por isso esse seletor tão específico
.containerTeam label.folderindex-recursivePerms {
  margin: 0 !important;
  font-size: 14px !important;
}

.search-results {
  .document-list,
  .folder-list {
    margin-bottom: 50px;
  }

  h2 {
    color: $clr-v2-heavier-grey;
    @include font-size(22px);
    margin: 0 0 30px;
  }
}

.document-list {
  .filterByName {
    width: 35% !important;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    @include font-size(14px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filterByName-v2 {
    width: 30% !important;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    @include font-size(14px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .alt-checkbox-v2 {
    width: 3% !important;
    display: inline-block;
  }

  .filterByResponsible-v2 {
    width: 20%;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    @include font-size(14px);
  }

  .filterByEnvelope {
    width: 10% !important;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    @include font-size(14px);
  }
  .filterByPublishContainer{
    position: relative;
  }
                                                                                                                                .filterByDueContainer {
                                                                                                                                  position: relative;
                                                                                                                                }
  .filterByPublish {
    width: 15%;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    word-break: break-word;
    max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    @include font-size(14px);
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filterByExecutionDate {
    position: relative;

    width: 17%;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    word-break: break-word;
    
    @include font-size(14px);
  }

  .filterByDue {
    width: 15%;
    display: inline-block;
    color: $clr-v2-heavier-grey;
    word-break: break-word;
    max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    @include font-size(14px);
  }

  &.list-view {
    margin-top: -1px;
  }

  &.trash {
    > li {
      &:hover {
        background-color: #fff;
        cursor: default;
        h2,
        h3 {
          color: inherit;
        }
      }
    }
  }

  &.list-view > li {
    float: none;
    width: 100%;
    padding: 15px 10px 10px;
    border: 1px solid $clr-ui-border;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    margin: 0;
    overflow: hidden;
    transition: $transition;
    cursor: pointer;
    border-radius: 0;

    &:last-child {
      border-bottom: 1px solid $clr-ui-border;
    }

    .right-menu {
      display: none;
    }

    h2,
    h3 {
      float: left;
      @include font-size(14px);
      font-weight: $regular;
      min-height: 0;
      // width: 40%;
      max-width: 100%;
      margin-bottom: 0;
    }

    p {
      float: left;
      margin-right: 0;
      // width: 20%;
      @include font-size(14px);

      &.dates {
        word-break: break-word;
      }

      &.dates {
        text-align: center;
        width: 17% !important;
        float: right !important;
      }

      &.attachments {
        margin-right: 0;
      }

      &.norm {
        display: none;
      }

      i {
        float: left;
        font-size: 21px;
        color: $primary;
        margin-right: 5px;
      }
    }

    .saved-in {
      display: none;
    }
  }
  & > li {
    float: left;
    width: 23.5%;
    padding: 10px;
    border: 1px solid $clr-ui-border;
    margin: 1% 2% 1% 0;
    // overflow:hidden;
    transition: $transition;
    cursor: pointer;
    border-radius: 5px;

    .right-menu {
      .submenu {
        margin-top: 20px;
        li * {
          @include font-size(14px);
        }

        span {
          display: flex;
          align-items: center;
        }

        i:before {
          display: flex;
          height: 100%;
          align-items: center;
        }
        .icon-edit:before {
          font-size: 16px;
        }
        .icon-duplicate:before {
          font-size: 18px;
        }
        .icon-document-remove:before {
          font-size: 17px;
          color: $alert;
        }
        .icon-bin:before {
          color: $alert;
          font-size: 17px;
        }
      }

      .btn-menu {
        top: -4px;
        right: -29px;

        &:before,
        span,
        &:after {
          background-color: $clr-v2-soft-red;
        }
      }

      .btn-menu:hover {
        &:before,
        span,
        &:after {
          background-color: $clr-v2-heavy-grey;
        }
      }
    }

    &:hover {
      background-color: $clr-ui-lightbkg;

      h2,
      h3 {
        color: $clr-v2-heavier-grey;
      }
    }

    .right-menu {
      top: 10px;
      right: 15px;

      .submenu {
        i {
          display: inline-block;
          color: $primary;
          margin-right: 5px;
        }
      }
    }

    h2,
    h3 {
      @include font-size(14px);
      color: $clr-v2-heavier-grey;
      font-weight: normal;
      height: 25px;
      max-width: 85%;
      margin-bottom: 5px;
      transition: $transition;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      i {
        @include font-size(16px);
        margin-right: 5px;
        color: $primary;
      }
    }

    p {
      float: left;
      height: 21px;
      line-height: 21px;
      margin-right: 60px;
      font-size: $sz-note;

      &.attachments {
        margin-right: 0px;
        display: flex;
        @include font-size(14px);
      }

      &.status-reading {
        margin-left: 15px;
        display: flex;
        @include font-size(14px);
        margin-right: 0;
      }

      &.norm {
        margin-right: 0;

        i {
          transform: translatey(3px);
          font-size: 17px;
        }
      }

      i {
        float: left;
        @include font-size(18px);
        color: $primary;
        margin-right: 5px;
      }
    }

    .saved-in {
      font-size: $sz-note;
      color: $clr-v2-heavier-grey;
      margin-bottom: 10px;

      strong {
        font-weight: $bold;
      }

      .breadcrumbs {
        margin-bottom: 4px;
        float: none;
        display: inline-block;
        font-size: $sz-note;
        color: $clr-v2-heavier-grey;
      }
    }
  }
}

.break-line-btn {
  max-width: 200px;
  line-height: 17px;
  // margin-top: 0;
}

.edition-list {
  li {
    padding-bottom: 15px;
    border-bottom: 1px solid $clr-ui-border;
    margin-bottom: 30px;

    h2 {
      @include font-size(21px);
      font-weight: $bold;
      color: $clr-v2-heavier-grey;
      margin-bottom: 10px;
    }
  }
}

.accessedRecently {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  h2,
  .document-list {
    width: 100%;
  }
  .document-list {
    overflow: unset;
  }
}

.grid-view-container {
  display: flex;
  flex-wrap: wrap;

  h2,
  ul {
    width: 100%;
  }
  .folder-list,
  .document-list {
    overflow: unset;
  }
}

@media screen and (max-width: 640px) {
  .document-list {
    li {
      width: 100%;
      margin: 0 0 2%;
    }
  }
}

@media screen and (max-width: 600px) {
  .v2-document {
    .accessedRecently,
    .grid-view-container {
      padding-left: 5% !important;
      padding-right: 5% !important;
    }

    .v2-page-cardheader {
      border-radius: 5px;

      .btn {
        min-width: 45px;
        padding-right: 15px;
        padding-left: 15px;
        width: 45px;
        margin-right: 10px;
      }

      .btn-content-desktop {
        display: none;
      }

      .btn-document-new {
        background-image: url('../img/folderindex-document-small.svg') !important;
        background-position: 15px !important;
      }

      .btn-folder-new {
        background-image: url('../img/folderindex-folder-small.svg') !important;
        background-position: 15px !important;
      }

      .btn-request-new {
        background-image: url('../../document/img/icon-comment.svg') !important;
        background-position: 12px !important;
      }

      .navitem-viewtype,
      .header-document-filter {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .filterList {
    .filterByPublish {
      width: 12%;
      margin-right: 5%;
    }

    .filterByDue {
      width: 10%;
    }
  }

  .document-list {
    &.list-view > li {
      * {
        margin-bottom: 7%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .folder-list {
    li {
      width: 100%;
      margin: 0 0 20px;
    }
  }

  .document-list {
    li {
      .attachments {
        width: 100%;
        margin: 0 0 20px;
      }
      .status-reading {
        width: 100%;
      }
    }
  }
}

.container-card {
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  gap: 16px;
}
.container-card__header {
  display: flex;

  align-items: center;
  h2 {
    line-height: 100%;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: fit-content;
  }
}
.container-card__main {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.toolltip {
  position: absolute;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  top: -15px;
  right: 52%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 250px;
  height: 51px !important;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;

}
