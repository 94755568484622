// icon color
$primary-icon: #34a4e0;
$search-icon: #f08300;

// Cores
$primary-lighter: #e4f4fc;
$primary: #53b7e8;
$primary-darker: #209ad6;

$alert-lighter: #df9f9f;
$alert: #c66;
$alert-darker: #ac3939;
$back: #777;

$success: #0c6;

$mintgreen: #e9f7f2;
$card-borders: #dbdbdb;

$clr-txt-title: #444;
$clr-txt-content: #777;
$clr-txt-note: #999;

$clr-ui-lightborder: #eee;
$clr-ui-border: #ccc;
$clr-ui-bkg: #f8f8f8;
$clr-ui-lightbkg: #fcfcfc;

$clr-social-fb: #3b5998;
$clr-social-gl: #dd4b39;

$clr-support-blue: #06c;
$clr-support-green: #0c6;
$clr-support-yellow: #fc3;

$clr-v2-soft-red: #415364;
$clr-v2-soft-blue: #6cb6e3;
$clr-v2-softest-grey: #f9f8f8;
$clr-v2-softer-grey: #efeeee;
$clr-v2-soft-grey: #EFF1F4;
$clr-v2-heavy-grey: #dedede;
$clr-v2-heavier-grey: #777777;
$clr-v2-white: #ffffff;
$clr-v2-red: #444;

$fontsize-v2-small: 12.5px;

$global-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
$transition: all 200ms ease-in-out;
$opacity: 0.7;
$border-radius: 3px;

$light: 300;
$regular: 400;
$bold: 700;

$sz-title: 36px;
$sz-content: 16px;
$sz-note: 14px;

$central-width: 1407px;

// VARIAVEIS MENU
$crl-v2-dark-blue: #4c7f9f;
$crl-v3-soft-blue: #7db4df;

// Fontes
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);
$Source: 'Source Sans Pro', Helvetica, Arial, sans-serif;
