@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
.v2-master-list {
  .v2-filter-searching {
    position: relative;

    .v2-filter-searching-nav {
      top: 18px;
      right: 15px !important;
      padding: 0;
    }
  }

  .distribution-msg {
    color: $alert;
    margin-bottom: 20px;
  }

  
}

.report-table {

  .col-localization,.col-description {
    padding-right: 10px; 
    span {
        display: inline-block;
        width: 250px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word; 
      
        div {
            white-space: normal; 
            word-wrap: break-word; 
            overflow-wrap: break-word; 
        }
    }
  }

.scrollable-table-container {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}


/* Para resolução menor ou igual a 1270px */
@media (max-width: 1270px) {
  .scrollable-table-container {
    /* Garantir que a rolagem seja possível */
    overflow-x: scroll;
  }
}


/* Media query para telas menores que 1440px */
@media (max-width: 1280px) {
  .col-localization span {
      width: 180px !important; 
  }
  .col-distribution,.col-localization,.col-description span {
      width: 185px !important; 
  }
  .col-redactor,.col-reviewer, .col-responsible {
    span {
      width: 100px !important;
      div {
          white-space: normal; 
          word-wrap: break-word;
          overflow-wrap: break-word;
      }
    }
  }
}

.col-version {
  span {
    display: inline-block;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.col-status {
  span {
    display: inline-block;
    width: 100px;
  }
}

.col-publication-date,
.col-due-date {
  span {
    display: inline-block;
    width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.col-redactor,
.col-reviewer,
.col-responsible {
  span {
    display: inline-block;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;   
    div {
      white-space: normal; 
      word-wrap: break-word; 
      overflow-wrap: break-word; 
    } 
  }
}

  .badges {
    float: unset;
    margin-left: -8px;
    padding-top: 8px;
    width: 200px;

    .team-badge {
      display: block;
      border: 1px solid #e2e2e2;
      background: #F2F5F8;
      @include font-size(12px);
      padding: 5px 10px;
      border-radius: 30px;
      margin: 1%;
      word-wrap: normal;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      //white-space: nowrap;

      @media screen and (max-width: 1330px) {
        max-width: 120px;
      }

      @media screen and (max-width: 1180px) {
        max-width: 110px;
      }

      @media screen and (max-width: 1080px) {
        max-width: 90px;
      }

      @media screen and (max-width: 650px) {
        max-width: 140px;
      }
    }
  }
}

.btn-pdf {
  width: 50px !important;
  min-width: 50px !important;
  margin-right: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: #777 !important;
  // border-color: hsl(0, 0%, 75%) !important;
  border: none;
  box-shadow: none !important;
  background-color: hsl(208, 100%, 89%) !important;
  font-weight: bold !important;

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: attr(tooltiptext);
    background: #5b5b5b;
    position: absolute;
    top: -25px;
    right: 80px;
    color: #F2F5F8;
    margin: 0 auto;
    text-align: center;
    width: 65px;
    font-weight: normal;
    @include font-size(11px);
    padding: 4px 6px;
    border-radius: 3px;
    opacity: 0;
    z-index: 10;
  }
}
