@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.fieldset-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fieldset {
  width: 400px;
}

.custom-hyperlink {
  color: #6cb6e3;
  text-decoration: underline;
}

.migrate-icon {
  margin-right: 10px;
  margin-left: 12px;
  margin-top: 4px;
}

.migrate-icon-title {
  position: relative;
  top: 3px;
  margin-right: 10px;
  margin-left: 5px;
  // margin-top: 4px;
}

form {
  fieldset {
    position: relative;
    margin-bottom: 20px;
    border: 2px solid #f2f5f8;
    border-radius: 4px;

    .required {
      color: $clr-social-gl;
    }

    .close {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $alert-darker;
      cursor: pointer;

      position: absolute;
      top: -10px;
      right: -10px;

      opacity: 1;

      &:before {
        font-family: 'icomoon';
        font-size: 9px;
        content: '\e919';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
    }

    .customer-head {
      legend {
        font-weight: $bold;
        position: absolute;
        background: #f2f5f8;
        padding: 10px 20px;
        width: 100%;
      }
    }

    label {
      display: block;
      width: 100%;
      margin: 0 1% 20px;
      color: $clr-txt-title;
    }

    .search {
      position: relative;

      .btn-hover {
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid $clr-ui-border;
        border-radius: 0 3px 3px 0;
        background-color: $clr-ui-bkg;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;

        &:before {
          font-family: 'icomoon';
          font-size: 19px;
          content: '\e918';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $clr-txt-note;
        }

        &:focus {
          border-color: $primary;
        }
      }
    }

    input[type='text'],
    input[type='date'],
    input[type='time'],
    input[type='file'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select,
    textarea {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #ccc;
      border-radius: $border-radius;
      margin-top: 5px;
      background-color: #fff;
      font-size: $sz-content;
      font-family: $Source;
      color: $clr-txt-content;
      background-color: #fff;
      transition: $transition;

      &.hidden {
        display: none;
      }

      &:focus {
        border-color: $primary;
        outline: none;
      }

      option {
        color: $clr-txt-content;
        padding: 3px 0;
      }
    }

    select {
      background-color: #f2f5f8;
    }

    .selectCustom {
      .react-select__multi-value {
        border-radius: 10px;
        max-width: 150px;
      }
    }

    .user-info {
      padding: 60px 10px 10px 10px;
      display: flex;

      .avatar {
        margin: 0 30px;
        div {
          position: relative;
          width: 120px;
          height: 120px;
          border: 1px solid $primary;
          border-radius: 80px;
          background-color: #f2f5f8;
          text-align: center;
          margin-top: 20px;
          padding: 48px 0;

          .icon {
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            border: 2px solid white;
            border-radius: 30px;
            background-color: #f2f5f8;
            top: 80px;
            left: 90px;
            padding: 2px;
            cursor: pointer;

            .icon-camera {
              color: $clr-v2-soft-red;
            }
          }

          #avatar-preview {
            top: -1px;
            left: -1px;
            width: 120px;
            height: 120px;
            border-radius: 80px;
            position: absolute;
          }
        }
      }
      .user-fields {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-left: 2px solid #f2f5f8;
        margin-left: 30px;
        padding-left: 30px;

        .input-feedback-passwordStrength {
          font-size: 16px !important;
          font-weight: bold;
          color: #777 !important;
          margin-bottom: 0px;
          padding-top: 0px !important;

          .span-strong {
            color: #2fb214;
          }

          .span-weak {
            color: #c6201c;
          }
        }

        .input-feedback-passwordInstructions {
          font-size: 13px !important;
          color: #777777 !important;
        }
      }
    }

    .user-config {
      padding: 60px 10px 10px 10px;
      display: flex;
      flex-wrap: wrap;
    }

    .alt-checkbox {
      font-size: $fontsize-v2-small;
      color: $clr-v2-soft-red;
      font-family: $Source;
      width: 250px;
      margin: 0;

      .togglecheck {
        background-color: $clr-v2-soft-red;
        width: 31px;
        height: 10px;
        top: -3px;
      }

      .togglecheck::after {
        width: 16px;
        height: 16px;
        border: 1px solid #e2e5e8;
        box-shadow: 1px #00000066;
        top: -4px;
      }

      .togglecheck.selected {
        background-color: $clr-v2-soft-blue;
      }

      .togglecheck.selected::after {
        background-color: $clr-v2-soft-blue;
        border-color: $clr-v2-soft-blue;
        left: 15px;
      }
    }

    .colspan-31p {
      width: 31.3%;
    }

    .colspan-48p {
      width: 48%;
    }

    .user-config-times {
      padding: 45px 10px 10px 20px;
      .config-message {
        margin-bottom: 20px;
      }

      .config-body {
        display: flex;
        .config-times {
          width: 175px;
          padding: 0 12px;
          border-right: 1px solid $card-borders;
          height: fit-content;
          &:last-child {
            border-right: unset;
            padding-right: unset;
          }
          &:first-child {
            padding-left: unset;
          }
          .menu {
            display: grid;
            text-align: center;
            margin-bottom: 10px;

            span {
              margin-bottom: 10px;
              font-weight: bold;
            }

            button {
              background: white;
              padding: 8px;
              border-radius: 5px;
              border: 1px solid $card-borders;
              cursor: pointer;

              &:hover {
                background: $card-borders;
              }
            }
          }
          .times {
            .time {
              display: flex;
              margin-bottom: 15px;
              .from,
              .to {
                display: grid;

                label {
                  margin-bottom: 3px;
                }

                input[type='time'] {
                  width: 60px;
                  height: 30px;
                  background: unset;
                  padding: unset;
                  border: unset;
                }

                border-bottom: 2px solid $primary-icon;
              }

              .from {
                margin-right: 10px;
              }

              .remove {
                background-color: white;
                border: unset;
                position: relative;
                cursor: pointer;
                &:before {
                  font-family: 'icomoon';
                  @include font-size(12px);
                  content: '\e919';
                  transform: translate(-50%, -50%);
                  color: $alert-darker;
                  padding-left: 2px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 1630px) {
          display: grid;
          grid-template-columns: 200px 200px 200px 200px;
          grid-row-gap: 30px;
        }

        @media screen and (max-width: 1180px) {
          display: grid;
          grid-template-columns: 200px 200px 200px;
          grid-row-gap: 30px;
        }

        @media screen and (max-width: 1023px) {
          display: grid;
          grid-template-columns: 200px;
          grid-row-gap: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  form {
    fieldset {
      .user-info {
        flex-wrap: wrap;
        justify-content: center;
        .user-fields {
          border-left: none;
          margin-left: 0;
        }
      }

      .user-fields,
      .user-config {
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px 30px 20px 30px;

        label {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1290px) {
  .v2-page.menu-expanded form fieldset .user-config-times .config-body {
    display: grid;
    grid-template-columns: 200px 200px 200px;
  }
}

@media screen and (max-width: 1090px) {
  .v2-page.menu-expanded form fieldset .user-config-times .config-body {
    display: grid;
    grid-template-columns: 200px 200px;
  }
}

@media screen and (max-width: 1023px) {
  .v2-page.menu-expanded form fieldset .user-config-times .config-body {
    display: grid;
    grid-template-columns: 200px;
  }
}
