.share-flex {
  display: flex;
  align-items: center;
}

.share-checkbox {
  width: 40%;
  margin-right: 10px;
}

.share-warning {
  width: 60%;
  text-align: left;
}

.share-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: medium;
}
.share-button-center {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.share-box {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 1rem;
}

.share-row {
  display: flex;
  justify-content: space-between;
  margin-top: -30px;
}

.share-col {
  flex: 1;
  margin: 0 0.5rem;
  text-align: center;
}

.share-btn-primary {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #53b7e8;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.share-btn-secondary {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #53b7e8;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.share-qrcode {
  width: 75px;
  height: 75px;
  display: block;
  margin: 0 auto 0.5rem;
}

.share-hidden-textarea {
  position: absolute;
  left: -9999px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 15px;
}

.icon-container-left {
  margin-right: 26px !important;
}

.icon-container-center {
  margin-right: 20px !important;
}

.icon-container-right {
  margin-right: 34px !important;
}
.share-modal .icon {
  font-size: 4rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-image {
  width: 100px;
  height: 100px;
}
