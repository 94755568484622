@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .pending-norms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .pending-norms-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      margin: 0 auto;
      padding: 0;

      .pending-norms-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px;
        background-color: white;
        border: 1px solid $clr-v2-heavier-grey;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-bottom: 20px;

        .norm-initials {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .norm-title {
          font-size: 16px;
          text-align: center;
          margin-bottom: 10px;
        }

        .buttons-container {
          display: flex;
          justify-content: center;
          width: 100%;

          button {
            padding: 8px 20px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0 10px;
            width: 120px;
          }

          .btn-pending-norm {
              width: 120px;
              height: 40px;
              font-size: 14px;
              line-height: 0.8;
          }
        }
      }
    }
  }

  .pending-norms-header {

    a,
    .pending-norms-no-route {
      text-transform: uppercase;
      font-weight: normal;
      color: $clr-v2-heavier-grey;
      padding-left: 20px;
      font-size: 16px;
    }

    .pending-norms-no-route {
      font-weight: normal !important;
      color: $clr-v2-heavier-grey !important;
    }
  }

  .pending-norms-read {

    a,
    p,
    pre {
      color: $primary;
    }

    a {
      font-weight: bold !important;
    }
  }

  .pending-norms-hover {
    &:hover {
      a {
        color: $primary;
        font-weight: bold !important;
      }
    }
  }

  .green-link {
    color: green;
  }
  
  .blue-link {
    color: blue;
  }
}
