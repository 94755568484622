.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 3px 0 -3px 0;
  animation: spinnerAnimation 1s linear infinite;
}

@keyframes spinnerAnimation {
  to {
    transform: rotate(360deg);
  }
}