@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-version-compare {
  .breadcrumbs-wrapper {
    color: $clr-txt-title;

    a,
    span {
      font-size: 12px;
    }

    a:hover {
      text-decoration: underline;
      color: $primary-darker;
    }

    .breacrumb-page {
      color: $clr-txt-title;
      font-weight: normal;
      margin: 0;
    }
  }
  .breadcrumbs-wrapper> :last-child {
    font-size: 14px;
    font-weight: 700;
    color: #415364;
    margin: 0;
  }
  #versions-compared {
    .version {
      float: left;
      width: 48%;
      margin-bottom: 50px;

      &:first-child {
        margin-right: 4%;
      }

      h2 {
        font-size: 21px;
        color: $clr-txt-title;
        margin-bottom: 10px;
      }

      .data {
        line-height: 21px;

        strong {
          font-weight: $bold;
        }

        i {
          float: left;
          color: $primary;
          font-size: 21px;
          margin-right: 10px;
        }
      }
    }

    .label {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0;
      background-color: #F2F5F8;
      max-width: 350px;
      padding: 15px;

      h2 {
        font-size: 18px;
        color: $clr-txt-title;
        margin-bottom: 10px;
        font-weight: 450;
        width: 100%;
      }

      span {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 5px;

        &.del {
          background-color: #f5e0e0;
          color: $alert-darker;
          width: 45%;
        }

        &.ins {
          background-color: #e0f5f5;
          color: $primary-darker;
          width: 50%;
          margin-right: 8px;
        }
      }
    }

    .table-versions {
      width: 100%;

      th {
        padding: 0.5em 0.1em;
        vertical-align: middle;
        font-weight: 500;
      }

      td {
        padding: 0.2em 0;
        vertical-align: middle;
      }

      thead th {
        border-top: none;
        border-bottom: 2px solid gray;
        color: $clr-txt-content;
      }

      tbody tr {
        border-top: none;
        border-bottom: 2px solid $clr-txt-note;
        color: $clr-txt-content;

        cursor: default;
        &:hover > td {
          color: $clr-txt-content !important;
        }

        td > label.alt-checkbox {
          cursor: pointer;

          input {
            display: none;
          }
        }
      }

      th:nth-child(1) {
        width: 10%;
        text-align: center;
      }

      td:nth-child(1) {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: $clr-v2-soft-red;
      }

      th:nth-child(2) {
        width: 50%;
      }

      td:nth-child(2) {
        padding-right: 50px;
      }

      td:nth-child(3) {
        padding-right: 20px;
      }

      th {
        text-align: left;
      }

      .role-avatar {
        display: flex;
        flex-wrap: wrap;

        .avatar img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }

        .name {
          text-align: center;
          padding: 10px 0 0 10px;
        }
      }
    }

    .compared-text {
      /* Difference Highlighting and Strike-through */
      del {
        color: #ac3939;
        background-color: #f5e0e0;
        text-decoration: none;
        span {
          color: #ac3939 !important;
          background-color: #f5e0e0 !important;
          text-decoration: none !important;
        }
      }

      ins {
        color: #209ad6;
        background-color: #e0f5f5;
        text-decoration: none;
        span {
          color: #209ad6 !important;
          background-color: #e0f5f5 !important;
          text-decoration: none !important;
        }
      }

      span.diffmod.fr-emoticon {
        position: relative;
        > ins {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px solid #209ad6;
          background-color: unset;
        }

        > del {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px solid #ac3939;
          background-color: unset;
        }
      }

      /* Image Diffing */
      del.diffimg.diffsrc {
        position: relative;
      }

      del.diffimg.diffsrc {
        img {
          border-top: 5px solid #ac3939 !important;
          border-bottom: 5px solid #ac3939 !important;
          opacity: 0.5;
        }
      }

      ins.diffimg.diffsrc {
        img {
          border-top: 5px solid #209ad6 !important;
          border-bottom: 5px solid #209ad6 !important;
        }
      }

      /* List Diffing */
      ol.diff-list {
        list-style: none;
        counter-reset: section;
        display: table;
      }

      ol.diff-list > li.normal,
      ol.diff-list > li.removed,
      ol.diff-list > li.replacement {
        display: table-row;
      }

      ol.diff-list > li > div {
        display: inline;
      }

      ol.diff-list > li.replacement:before,
      ol.diff-list > li.new:before {
        color: #209ad6;
        background-color: #e0f5f5;
        text-decoration: none;
      }

      ol.diff-list > li.removed:before {
        counter-increment: section;
        color: #ac3939;
        background-color: #f5e0e0;
        text-decoration: none;
      }

      // /* List Counters / Numbering */
      ol.diff-list > li.normal:before,
      ol.diff-list > li.removed:before,
      ol.diff-list > li.replacement:before {
        width: 15px;
        overflow: hidden;
        content: counter(section) '. ';
        display: table-cell;
        text-indent: -1em;
        padding-left: 1em;
      }

      ol.diff-list > li.normal:before,
      ol li.replacement + li.replacement:before,
      ol.diff-list > li.replacement:first-child:before {
        counter-increment: section;
      }
      ol.diff-list li.removed + li.replacement {
        counter-increment: none;
      }
      ol.diff-list li.removed + li.removed + li.replacement {
        counter-increment: section -1;
      }
      ol.diff-list li.removed + li.removed + li.removed + li.replacement {
        counter-increment: section -2;
      }
      ol.diff-list li.removed + li.removed + li.removed + li.removed + li.replacement {
        counter-increment: section -3;
      }
      ol.diff-list li.removed + li.removed + li.removed + li.removed + li.removed + li.replacement {
        counter-increment: section -4;
      }
      ol.diff-list li.removed + li.removed + li.removed + li.removed + li.removed + li.removed + li.replacement {
        counter-increment: section -5;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -6;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -7;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -8;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -9;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -10;
      }
      ol.diff-list
        li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.removed
        + li.replacement {
        counter-increment: section -11;
      }

      ul.diff-list {
        list-style-type: none;
      }

      ul.diff-list {
        li {
          list-style-type: none;
        }
        li::before {
          content: '\2022';
          color: black;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1.1em;
          font-size: 1.1em;
        }

        li.replacement,
        li.new {
          &::before {
            content: '\2022';
            color: #209ad6;
            background-color: #e0f5f5;
          }
        }

        li.removed {
          &::before {
            content: '\2022';
            color: #ac3939;
            background-color: #f5e0e0;
          }
        }
      }

      // /* Exception Lists */
      ul.exception,
      ul.exception li:before {
        list-style: none;
        content: none;
      }
      .diff-list ul.exception ol {
        list-style: none;
        counter-reset: exception-section;
        /* Creates a new instance of the section counter with each ol element */
      }
      .diff-list ul.exception ol > li:before {
        counter-increment: exception-section;
        content: counters(exception-section, '.') '.';
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .role-avatar {
      justify-content: center;
    }
  }
}
