@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

// Vincular documentos
.listSelectedDocuments {
  display: flex;
  clear: both;
  flex-wrap: wrap;

  li {
    flex: 1 0 21%;
    display: inline-block;
    border: 1px solid $clr-ui-border;
    flex-basis: 21%;
    max-width: 24%;
    margin: 3px;
    padding: 25px 10px;
    text-align: left;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    i {
      margin-right: 10px;
    }
  }
}

// GetUserMedia anexar foto/video
.captureMediaBox {
  text-align: center;

  .backMediaSelect {
    position: absolute;
    z-index: 99;
    color: $clr-ui-bkg;
    left: 25px;
    margin-top: 23px;
    @include font-size(45px);
    cursor: pointer;
  }

  .labelEvidence {
    clear: both;
    float: none;
    margin: 20px auto;
  }

  .buttonSetMedia {
    width: 60px;
    height: 60px;
    border: 0;
    display: inline-block;
    cursor: pointer;
    margin: 0 5px;
    background: $primary;
    color: $clr-ui-bkg;
    @include font-size(45px);
    padding: 6px;
    border-radius: 3px;

    &.disabled {
      background: $clr-txt-content;
      cursor: not-allowed;
    }
  }
}

.getPicture {
  object-fit: cover;
  position: relative;

  .previewPic {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .controls {
    position: absolute;
    width: 100%;
    bottom: 20%;

    .button-capture {
      box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.4);
      background: rgba(227, 73, 28, 0.8);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      cursor: pointer;
      border: 0;
      font-size: 0;
    }

    button {
      box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.4);
      background: rgba(227, 73, 28, 0.8);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      cursor: pointer;
      border: 0;
      font-size: 0;

      &.repeatCapture {
        padding: 17px 18px;
        font-size: 20px;
        outline: 0;
        border-radius: 4px;
        box-shadow: 0 0 0;
        width: 220px;
        font-weight: bold;
        background: #fff;
      }
    }

    .button-switch-camera {
      background-color: #fff;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 0;
      font-size: 0;

      /* Cor de fundo */
      color: #fff;
      /* Cor do texto */
      border: none;
      /* Remove a borda */
      position: absolute;
      right: 30px;
      font-size: 8px;
      transition: background-color 0.3s ease;
      /* Transição suave da cor de fundo */
    }

    .button-switch-camera:hover {
      background-color: #28333e;
      /* Cor de fundo ao passar o mouse */
    }
  }
}

#videoRecorder {
  [class*='video-recorder__Wrapper'] {
    [class*='render-actions__ActionsWrapper'] {
      bottom: 80px;
      padding-bottom: 0;
    }

    svg.SVGInline-svg {
      visibility: hidden;
    }

    [data-qa$='start-replaying'] {
      font-size: 0;
      color: transparent;

      &:after {
        content: 'Gravar novamente';
        font-size: 20px;
        color: #000;
      }
    }

    [data-qa$='turn-on-camera'] {
      font-size: 0;
      color: transparent;

      &:after {
        content: 'Ligar camera';
        font-size: 20px;
        color: #000;
      }
    }

    div[class*='record-button__Instructions'] {
      font-size: 0;
      color: transparent;
      display: none;
    }
  }
}

// Drag drop upload documentos
.renderFiles {
  text-align: center;

  .list-group {
    display: flex;
    flex-wrap: wrap;

    li {
      display: inline-block;
      border: 1px solid $clr-ui-border;
      margin: 7px;
      flex-basis: 30%;
      padding: 25px 10px;
      text-align: left;
      max-width: 30%;

      white-space: nowrap;
      text-overflow: ellipsis;

      .icon-close {
        width: unset;
        height: unset;
        position: absolute;
        @include font-size(8px);
        top: -8px;
        right: -15px;
        border: 2px solid $alert;
        border-radius: 20px;
        font-weight: 300;
        background: #fff;
        padding: 3px;
        color: $alert;
        cursor: pointer;
      }

      .icon-error {
        position: absolute;
        @include font-size(8px);
        top: -10px;
        left: -10px;
        fill: $alert;
        stroke: #fff;
      }

      i {
        margin-right: 10px;
      }

      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }
  }
}

.drag-drop-zone {
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;

  .drag-drop-zone p {
    color: $clr-txt-title;
    flex: 1;
  }

  button {
    margin: 20px auto;
    display: block;

    &.disabled {
      color: red;
    }
  }
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}

.progress-evidences {
  display: block;
  height: 30px;
  background: #209ad6;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  margin: 5px 0;
  line-height: 1.7;
}

.comment-box {
  .edit-by {
    float: right;

    width: 75%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: right;
  }
}

span.validation-error {
  color: $alert;
  font-size: 0.8em;
}