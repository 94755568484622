@import '../../../assets/scss/variables.scss';

/* documentaccess.scss */
html,
body {
  height: 100%;
  margin: 0;
  overflow: auto;
  background-color: #f0f0f0;
}

.body-background {
  background-color: #f0f0f0;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  /* Garantir que o contêiner ocupe a altura total da viewport */
  overflow-y: auto;
  /* Permitir rolagem vertical */
}

.document-view {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 20px;
  /* Adiciona uma margem superior ao documento */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Permitir rolagem dentro do documento */
}

.document-view header {
  display: flex;
  flex-direction: row;
  /* Alterar para linha */
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.document-view header .logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  /* Adicionar espaço entre a logo e o nome */
}

.document-view header .logo img {
  max-width: 100px;
}

.document-view header .company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.document-view header .company-info h1 {
  font-size: 24px;
  margin: 0;
  text-align: left;
}

.document-view header .title {
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
}

.document-view .details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.document-view .details div {
  flex: 1 1;
  text-align: center;
}

.document-view .details div:not(:last-child) {
  border-right: 1px solid #ddd;
}

.document-view .details strong {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.document-view .published-edited {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.document-view .content {
  margin-bottom: 20px;
}

.document-view footer {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  font-size: 12px;
  color: #aaa;
}

.footer-info {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: #888;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message div {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.error-message h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.error-message p {
  font-size: 16px;
  color: #333;
}
