@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .active-header {
    margin-bottom: 20px;
  }

  .button-back {
    cursor: pointer;
  }

  .containerJustification.justification,
  .containerJustification.suggestion {
    background-color: $clr-v2-soft-grey;
    color: #777;
    border: 0;
    border-radius: 5px;
  }

  .containerJustification.suggestion {
    background-color: white;
    border: 2px solid $primary;

    h2 {
      color: $primary;
    }
  }

  #document-editor {
    cursor: text;
  }

  .attach-button {
    border: 0;
    text-align: right;

    i {
      float: none;
    }
  }

  .btn {
    padding: 0 10px;

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  .attachments-container span {
    font-weight: 700;
    color: #415364;
  }

  .download-file-attachment {
    padding-left: 8px;
    text-decoration: underline;
    font-weight: 700;
    color: #3b454f;
  }
  .download-file-attachment {
    cursor: pointer;
  }
  .clip-icon {
    font-size: 16px;
    margin-right: 10px;
  }
  .align {
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    bottom: 2px;
  }
  .documenteditor-back {
    cursor: pointer;
  }

  .editor-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #53b7e8;
    height: 30px;
    margin-bottom: 15px;
    padding-left: 15px;
    font-family: $Source;
  }

  .attachment-download {
    float: right;
    margin-right: 40px;
    cursor: pointer;
  }

  .attachment-image {
    max-width: 100%;
  }
}