.alt-label label {
  width: auto;
}

.v2-quality-panel-index {
  .btn-standard-new {
    background-image: url('../../default-v2/img/icon-plus-white.svg') !important;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 10px 24px 10px 16px;
    margin-right: 15px;
    width: 150px;
    height: 44px;
    border-radius: 500px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .v2-cardheader-filter .v2-filter-searching .v2-filter-searching-nav {
    // padding: 17.25px 0 0 0;
  }

  .v2-filter-searching-nav {
    right: 30px !important;
  }

  .right-menu {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 150px;
    z-index: 1;

    .btn-menu {
      top: 15px;
    }

    .submenu {
      min-width: unset;
    }
  }

  .contentRole {
    position: relative;
    z-index: 0;
  }

  img.seloNormAudit + h3 {
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img.seloNormAudit {
    width: 48px !important;
    top: 16px !important;
    right: 40px !important;
  }

  .container-cards > ul > li {
    width: 31.33% !important;
    border: unset;
    display: unset;
    margin: 10px;
    transition: grid-row-end 2s ease, height 2s ease;

    .content {
      border: 1px solid #dbdbdb;
      border-radius: 2px;
    }
  }

  .grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto auto auto; //repeat(auto-fill, minmax(310px, 1fr));
    grid-auto-rows: 10px;
    margin-left: 1%;
  }
}

@media only screen and (max-width: 1178px) {
  .v2-quality-panel-index {
    .container-cards > ul > li {
      width: 47% !important;
    }
  }
}

@media only screen and (max-width: 985px) {
  .v2-quality-panel-index {
    .container-cards > ul > li {
      width: 100% !important;
    }
  }
}
