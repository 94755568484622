.v2-pdf-page {
  @media print {
    body * {
      visibility: hidden;
    }

    #report-to-print, #report-to-print * {
      visibility: visible;
    }

    #report-to-print {
      position: absolute;
      top: 0;
      left: 0;
    }

    .content-box-report {
      h1 {
        font-size: 36px;
      }
    }

    .page {
      height: 100vh !important;
    }

    .pagination {
      margin: 0;
    }

    small {
      margin-bottom: 22%;
    }
  }
}
