@import '../../../assets/scss/variables.scss';

.v2-cardheader-filter {
  height: 64px;

  .btn {
    min-width: 111.7px;
    height: 30px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 2px 2px 5px #0000004d;
    border-radius: 15px;
    font-size: 13.5px;
    font-weight: normal;
    text-transform: none;
    line-height: inherit;
    // letter-spacing: 0.5px;
    font-family: $Source;
    float: left;
    margin-top: 12px;
  }

  .btn-secondary {
    background-color: transparent;
    border-color: $clr-v2-soft-blue;
    color: $clr-v2-soft-blue;
    box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.1), 2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .btn-secondary:hover {
    background-color: $clr-v2-soft-blue;
    color: $clr-v2-white;
    box-shadow: 2px 2px 5px #0000004d;
  }

  .btn:first-letter {
    text-transform: uppercase;
  }

  .v2-filter-notsearching-flex {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    float: none !important;
  }

  .v2-filter-notsearching {
    padding-top: 5px;
    padding-right: 35.16px;
    margin: 0;
    float: right;

    .alt-checkbox {
      font-size: $fontsize-v2-small;
      color: $clr-v2-soft-red;
      font-family: $Source;
      float: left;
      margin-top: 15.14px;

      .togglecheck {
        background-color: $clr-v2-soft-red;
        width: 31px;
        height: 10px;
        top: -3px;
      }

      .togglecheck::after {
        width: 16px;
        height: 16px;
        border: 1px solid #e2e5e8;
        box-shadow: 1px #00000066;
        top: -4px;
      }

      .togglecheck.selected {
        background-color: $clr-v2-soft-blue;
      }

      .togglecheck.selected::after {
        background-color: $clr-v2-soft-blue;
        border-color: $clr-v2-soft-blue;
        left: 15px;
      }
    }

    .v2-navitem {
      float: left !important;
      margin-top: 18.14px;
    }

    .v2-filter-orderDirection {
      margin-left: 0px !important;
      margin-right: 10px !important;
      list-style: none;
    }

    .v2-filter-iconSearch {
      width: 30px;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
    }

    .v2-filter-iconSearch img:hover {
      margin-top: -7px !important;
    }

    select {
      border: 1px solid #f2f5f8;
      font-family: $Source;
      color: $clr-v2-soft-red;
      font-size: $fontsize-v2-small;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: #f2f5f8;
      // background-image: url('../img/select-down.svg');
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 18.14px;
      width: 110px;
      height: 53px;
      margin-left: 20px;
      float: left;
    }

    .v2-select {
      .react-select__control {
        border: 1px solid #f2f5f8;
        font-family: $Source;
        color: $clr-v2-soft-red;
        font-size: $fontsize-v2-small;
        background-color: #f2f5f8;
        width: 100px;
        margin-left: 20px;
        height: 53px;
        float: left;
      }

      .react-select__menu {
        background-color: $clr-v2-white !important;
        border: 1px solid #dbdbdb;
        box-shadow: 2px #0000004d !important;
        max-width: 190px;
        margin-top: 50px;
        margin-left: 73%;
      }

      .react-select__option {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: $fontsize-v2-small;
        font-family: $Source;
        color: $clr-v2-soft-red;
      }

      .react-select__option--is-selected,
      .react-select__option--is_active {
        background-color: $clr-v2-white;
        color: $clr-v2-soft-blue;
      }

      .react-select__option--is-selected::before {
        content: ' ';
        position: absolute;
        z-index: 1;
        // top: 23px;
        margin-top: 4px;
        left: 33px;
        border: 4px solid $clr-v2-soft-blue;
        border-radius: 20px;
      }

      .react-select__option--is-focused {
        background-color: $clr-v2-soft-grey;
        color: $clr-v2-soft-blue;
      }

      .react-select__indicator-separator {
        background-color: transparent;
      }
    }

    select {
      option {
        padding-left: 56px !important;
        padding-right: 56px !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        height: 45px;
        background-color: $clr-v2-white;
      }
    }

    ul {
      display: flex;
    }
  }

  .v2-filter-searching {
    height: 100%;
    padding-left: 11px;
    border: 0.5px solid #6cb6e3;
    border-radius: 5px;
    .v2-filter-searching-icon {
      padding-left: 20px;
      padding-top: 20px;
      width: 16px;
      height: 16px;
      color: $clr-v2-soft-red;
      font-weight: 500;
      position: absolute;
      cursor: pointer;
    }

    .v2-filter-searching-input {
      outline: none;
      background-color: #f2f5f8;
      border: none;
      // border-bottom: 3.25px solid $clr-v2-soft-blue;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: $clr-v2-soft-red;
      font-family: $Source;
      padding-left: 51.35px;
      font-size: 12px;
    }

    .v2-filter-searching-input::placeholder {
      opacity: 0.5;
    }

    .v2-filter-searching-input:focus::placeholder {
      font-weight: 400;
      opacity: 1;
      color: black;
    }

    .v2-filter-searching-nav {
      position: absolute;
      padding-top: 23px;
      padding-right: 0;
      margin: 0;
      font-weight: 500;
      color: $clr-v2-soft-red;
      cursor: pointer;
    }
  }
}
