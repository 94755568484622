@import '../../../assets/scss/variables.scss';

.v2-container-sidebar {
  width: 100%;
  background-color: #fff;
}

.v2-sidemenu {
  height: 100vh;
  min-width: 96px;
  background-color: $clr-v2-white;
  transition: width max-width min-width 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  .v2-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .v2-sidemenugroup {
    width: 100%;
  }

  .v2-link-logo {
    align-self: center;

    @media screen and (max-width: 600px) {
      align-self: flex-start !important;
    }
  }

  .v2-sidemenugroup-logo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-inline: 24px;
    padding-top: 24px;

    @media screen and (min-width: 600px) {
      padding: 30px 20px;
      height: 150px;
    }
  }

  .v2-sidemenugroup-lowerborder {
    height: 0;
    border-bottom: 2px solid $clr-v2-soft-grey;
    transition: 1s ease;
    width: 100%;
    margin: 1rem auto;
  }

  .v2-sideborder {
    border-bottom: 2px solid $clr-v2-soft-grey;
    width: 100%;
    margin: 0 auto;

    margin-top: -1rem;
    margin-bottom: 18px;
  }

  p {
    display: flex;
    justify-content: space-between;
  }

  .v2-sidemenuitem-icon {
    vertical-align: middle;
    width: 18px;

    // @media screen and (max-width: 600px) {
    //   width: 14px;
    //   height: 21px;
    // }
  }

  .v2-sidemenuitem-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 3.5px;
    padding: 10px 30px 10px 35px;

    p {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 600px) {
      padding: 8px 16px 8px 8px;
    }
  }

  .v2-link {
    display: inline-block;
    width: 100%;
    list-style: none;

    @media screen and (max-width: 600px) {
      padding-left: 1.5rem;
      padding-right: 2rem;
    }
  }

  .v2-sidemenuitem-item {
    border-bottom: 2px solid transparent;

    .v2-sidemenu-border {
      display: flex;
      flex-wrap: nowrap;
      height: 2px;
      visibility: hidden;

      .v2-sidemenuitem-border-solid {
        width: 100%;
        background-color: $crl-v3-soft-blue;
      }
    }
  }

  li.v2-sidemenuitem-item {
    position: static;
  }

  @media screen and (max-width:600px) {
    li.v2-sidemenuitem-item {
      position: relative;
    }
  }

  .v2-sidemenuitem-item:hover {
    @media screen and (max-width: 600px) {
      background-color: rgba(108, 182, 227, 0.16);
      border-radius: 8px;
      border-bottom: none !important;
    }

    color: #6cb6e3 !important;
    background-color: #eeeeee;
    border-bottom: 2px solid #7db4df;

    >div p span {
      color: #6cb6e3 !important;
    }
  }

  .v2-selected-sidemenuitem {
    background-color: #eeeeee;
    border-bottom: 2px solid #7db4df !important;

    @media screen and (max-width: 600px) {
      background-color: rgba(108, 182, 227, 0.16);
      border-radius: 8px;
      border-bottom: none !important;
    }

    div.v2-sidemenuitem-content {
      p {
        span {
          color: $clr-v2-soft-blue !important;
        }
      }
    }

    // p {
    //   span {
    //     color: #6cb6e3 !important;
    //   }
    // }
    >div p span {
      color: #6cb6e3 !important;
    }
  }
}

.v2-selected-sidemenuitem {
  div.v2-sidemenuitem-content {
    p {
      span {
        color: $clr-v2-soft-blue !important;
      }
    }
  }

  // p {
  //   span {
  //     color: #6cb6e3 !important;
  //   }
  // }

  .v2-sidemenu-border {
    .v2-sidemenuitem-border-solid {
      background-color: $clr-v2-soft-blue;
      width: 100%;
    }
  }

}

.v2-list-report {
  .v2-sidemenuitem-subitems-opened {
    .v2-list {
      .v2-menu-main {
        li.v2-sidemenuitem-item {

          div.v2-sidemenuitem-content {
            padding: 10px !important;

            &:hover {
              p {
                span {
                  color: #6cb6e3 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .v2-selected-sidemenuitem {
    background-color: #eeeeee;
    border-bottom: 2px solid #7db4df !important;

    @media screen and (max-width: 600px) {
      background-color: rgba(108, 182, 227, 0.16);
      border-radius: 8px;
      border-bottom: none !important;
    }

    div.v2-sidemenuitem-content {
      p {
        span {
          color: #415364 !important;
        }

        .cname {
          color: #6cb6e3 !important;
        }
      }
    }
  }
}

.v2-list-report {
  .v2-sidemenuitem-report {
    &:hover {
      background-color: rgba(108, 182, 227, 0.16) !important;
      border-bottom: 2px solid #7db4df !important;

      div {
        p {
          .cname {
            color: #6cb6e3 !important;
          }
        }
      }
    }
  }

  .v2-sidemenuitem-subitems-opened {
    .v2-list {
      .v2-menu-main {
        li.v2-sidemenuitem-item {
          border: none !important;

          &:hover {
            background-color: #eee;
            border-radius: 0px;
            border: none !important;
          }

          div.v2-sidemenuitem-content {
            &:hover {
              p {
                span {
                  color: #6cb6e3 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .v2-selected-sidemenuitem {
    // background-color: #f2f5f8;
    background-color: rgba(108, 182, 227, 0.16);

    div.v2-sidemenuitem-content {
      p {
        span {
          color: #415364 !important;
        }

        .cname {
          color: #6cb6e3 !important;
        }
      }
    }
  }
}

.v2-sidemenuitem-subitems-opened {
  .v2-list {
    .v2-menu-main {
      li.v2-sidemenuitem-item {
        border: none !important;

        &:hover {
          background-color: #eee;
          border-radius: 0px;
          border: none !important;
        }

        div.v2-sidemenuitem-content {
          &:hover {
            p {
              span {
                color: #6cb6e3 !important;
              }
            }
          }
        }
      }
    }
  }
}

.v2-sidemenuitem-subitems {
  display: none;
  position: absolute;
  margin-left: 96px;
  width: 200px;
  background-color: $clr-v2-white;
  box-shadow: 2px 2px 5px #0000004d;
  border: 1px solid $card-borders;
  color: $clr-v2-soft-red;
  z-index: 99999;
  font-size: 16px;

  ul {
    position: relative;
    z-index: 99999;
  }

  .v2-sidemenuitem-icon {
    margin-right: 15px;
    margin-left: 0;
    width: 19x;
  }
}

.v2-sidemenuitem-subitems-md {
  .v2-sidemenuitem-subitems {
    width: 295px;

    .v2-sidemenuitem-content {
      color: #415364;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.v2-sidemenuitem-subitems-opened {
  display: block !important;
}

.v2-sidemenugroup-user {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .v2-sidemenuitem-user-border {
    >.v2-sidemenuitem-content {
      text-align: center;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 10px 10px 30px !important;
    }
  }
}

.box-user {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    gap: 8px;
  }
}

.v2-sidemenuitem-user-avatar {
  border-radius: 25px;
  margin: 0;
}

.v2-sidemenugroup-user {
  margin-bottom: 0.4rem;
}

.v2-sidemenu-short {
  display: block;
}

.v2-sidemenugroup-space {
  font-size: 12px;
  color: $clr-v2-soft-red;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $clr-v2-soft-grey;
  height: 216px;

  @media screen and (max-width: 600px) {
    height: auto !important;
  }

  p {
    margin-top: 4px;
  }

  a {
    margin-top: 4px;
    clear: both;
    color: $clr-v2-soft-blue;
  }

  canvas {
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}


.v2-sidemenu-expand {
  background-color: $clr-v2-white;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  position: absolute;
  font-size: 10px;
  top: 75px;
  left: 82px;
  cursor: pointer;
  transition: left 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.161));

  i {
    width: 10px;
    height: 10px;
    color: $crl-v2-dark-blue;
  }
}

.home-border {
  border-bottom: 3px solid $clr-v2-soft-blue;
  position: relative;

  &:before {
    border-bottom: 3px dotted $clr-v2-soft-blue;
    position: absolute;
  }
}

.v2-sidemenugroup-user {
  ul {
    .v2-sidemenuitem-subitems ul {
      height: inherit;
    }

    .v2-sidemenuitem-content {
      color: #415364;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      padding: 10px;
    }

    .v2-sidemenuitem-content:hover {
      // background-color: $clr-v2-soft-grey;
    }

    .v2-sidemenuitem-subitems {
      a div {
        color: #415364;
        cursor: pointer;
        font-size: 14px !important;
        font-weight: 400;
        padding: 10px;
      }
    }

    .v2-sidemenuitem-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
}

.menu-expanded {
  .v2-sidemenu {
    min-width: 240px;
    width: 240px;
  }

  .v2-sidemenu-expand {
    transition: .5 ease;
    left: 225px;
  }

  .v2-sidemenu-logo {
    width: auto;
  }

  .v2-sidemenuitem-icon {
    margin-left: 0;
    margin-right: 15px;
    width: 18px;
  }

  .v2-sidemenuitem-item {
    color: $crl-v2-dark-blue;
    font-size: 16px;
  }

  .v2-sidemenuitem-subitems {
    margin-left: 240px;
  }

  .v2-sidemenugroup-user {
    ul {
      .v2-sidemenuitem-subitems ul {
        height: inherit;
      }

      .v2-sidemenuitem-content {
        color: #415364;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
      }

      .v2-sidemenuitem-content:hover {
        // background-color: $clr-v2-soft-grey;
      }

      .v2-sidemenuitem-icon {
        // width: 29px;
      }
    }
  }

  .v2-sidemenu-space-large-texts,
  .v2-sidemenu-large {
    display: block;
  }
}

/** NOVAS CLASSES **/

.v2-sidemenu-top-bar {
  margin-bottom: auto;
  padding-top: 0 !important;
}

.v2-sidemenugroup-user {
  padding: 0 !important;

  .v2-menu-main {
    li.v2-sidemenuitem-item {
      border: none !important;
      background-color: transparent !important;

      p span {
        color: #415364 !important;
      }

      &:hover {
        background-color: #eee !important;
        border-radius: 0px;
        border: none !important;
      }

      div.v2-sidemenuitem-content {


        &:hover {
          p {
            span {
              color: #6cb6e3 !important;
            }
          }
        }
      }
    }
  }

}

.v2-sidemenugroup-user-config {
  padding: 0 !important;
}

.margin-right {
  margin-right: 10px;
  margin-left: 12px;
}

.bold {
  font-weight: 700;
}

.bold:hover {
  cursor: pointer;
}

.v2-sidemenu-space-short {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $clr-v2-soft-red;
  }
}

.v2-sidemenu-space-large-texts {
  min-height: 62px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $clr-v2-soft-red;
  }

  a {
    font-weight: 600;
  }

  @media screen and (min-width: 600px) {
    margin-left: 25px;
  }
}

.v2-sidemenu-hamburger {
  display: none;
}

.v2-sidemenugroup-logo {
  display: flex;
  flex-direction: column;
  gap: 16px !important;
  padding: 0;

  // .v2-box-icon {
  //   border-radius: 50%;
  //   height: 64px;
  //   width: 64px;

  //   img {
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 50%;
  //   }
  // }
}

.v2-cname {
  color: #415364;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  display: none;
}

.v2-sideborder {
  width: 100%;
  display: none;
}

.v2-menu {
  // max-width: 240px;
}

// MEDIA QUERY =======================================

@media screen and (min-width: 600px) {

  .v2-box-icon,
  p.v2-cname {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .v2-sidemenuitem-icon {
    width: 14px !important;
  }

  .v2-sidemenuitem-subitems-opened {
    width: 260px !important;
    top: 2.7rem !important;

    ul {
      a {
        padding: 0px !important;

        li {
          div {
            padding: 8px !important;
          }
        }
      }
    }
  }

  .v2-sidemenugroup-user-config,
  .v2-sidemenugroup-space {
    padding: 0 !important;
    position: relative !important;
    z-index: -1 !important;

    ul {
      // padding: 15px 0;
    }
  }

  .v2-sidemenuitem-subitems {
    margin-left: 0px !important;

    top: 0;
    z-index: 99999;
    font-size: 16px;

    ul {
      position: relative;
      z-index: 99999;
    }

    .v2-sidemenuitem-icon {
      margin-right: 15px;
      margin-left: 0;
      width: 19x;
    }
  }


  .v2-sidemenuitem-subitems-opened {
    width: 260px !important;
    top: 2.7rem !important;

    ul {
      a {
        padding: 0px !important;

        li {
          div {
            padding: 8px !important;
          }
        }
      }
    }
  }

  .v2-sidemenugroup-user-config,
  .v2-sidemenugroup-space {
    padding: 0 !important;
    position: relative !important;
    z-index: -1 !important;

    ul {
      // padding: 15px 0;
    }
  }

  .v2-sidemenuitem-subitems {
    margin-left: 0px !important;

    top: 0;
    z-index: 99999;
    font-size: 16px;

    ul {
      position: relative;
      z-index: 99999;
    }

    .v2-sidemenuitem-icon {
      margin-right: 15px;
      margin-left: 0;
      width: 19x;
    }
  }

  .v2-sideborder {
    display: inline-block;
  }

  .v2-cname {
    display: block;
  }

  .v2-menu {
    background-color: #fff;
    width: 304px;
    height: 100%;
    transform: translate(-100%);
    transition: all 0.5s ease;
    overflow: auto;
  }

  .v2-sidemenu-user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
  }

  .v2-box-icon {
    height: 64px;
    width: 64px;

    @media screen and (max-width: 600px) {
      width: 28px;
      height: 28px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  // .v2-sidemenu {
  //   background-color: #6cb6e3;
  //   display: flex;
  // }
  .v2-page {
    display: block !important;
  }

  .v2-sidemenu-hamburger {
    display: block;
    cursor: pointer;
    width: 2.3rem;
    margin-left: 10px;
    // border: 1px solid $clr-v2-soft-grey;
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // margin-top: 10px;

    position: absolute;
    top: 2.7rem;
    left: 1rem;
    z-index: 1;
  }

  .v2-sidemenu {
    //height: 75px;
    max-height: 100%;
    width: 100%;
    display: flex;
    float: left;
    transform: translateX(-100%);

    //display: none;

    .v2-sidemenugroup {
      display: none;
    }

    &.sidemenu-open {
      background-color: rgba(0, 0, 0, 0.5);
      height: 100vh;
      max-height: 100vh;
      transform: translateX(0%);

      .v2-menu {
        transform: translateX(0%);
        background-color: #fff;
        transition: transform .5s ease;
      }

      // transform: translateX(-101%);
      // transform: translateX(0%);
      .v2-sidemenugroup {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        transform: translateX(0%);
        transition: 0.5s ease;
        position: relative;
        // z-index: -1 !important;
        transition: 0.5s ease;

        // z-index: -1 !important;
        .v2-sidemenu-name {
          display: block;
          padding-left: 20px;
          color: #5a646e;
          font-weight: 600;
        }

        ul {
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .v2-sidemenuitem-content {
          margin-bottom: 0px;
        }

        li {
          // background-color: $clr-v2-white;
        }

        .v2-sidemenugroup-lowerborder {
          // background-color: $clr-v2-white;
          // padding: 0px !important;
          // margin: 0px !important;
          display: flex;
          justify-content: center;

          .v2-border {
            width: 2rem;
          }
        }
      }

      .v2-sidemenu {
        // display: none;

        .v2-box-icon {
          border-radius: 50%;
          height: 64px;
          width: 64px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .v2-cname {
          color: #415364;
          font-size: 20px;
          font-weight: 700;
          line-height: 100%;
        }
      }

      .v2-sidemenu-top-bar {
        width: 100%;
        // padding-top: 15px !important;
        background-color: $clr-v2-white !important;
        transition: width 1s ease;
      }
    }

    .chartjs-render-monitor {
      width: 98px !important;
      height: 57.5px !important;
      margin-top: 8px !important;
    }
  }

  @media screen and (max-width: 600px) {
    .v2-sidemenu {
      background-color: transparent;
      position: fixed;
      z-index: 8;
      transform: translateX(-100%);
      //display: none;
    }

    .v2-sidemenu.sidemenu-open {
      display: flex;
    }
  }

  .v2-sidemenu-expand {
    display: none;
  }

  .v2-sidemenu-extraspace {
    display: none;
  }

  .v2-page-content {
    width: 100% !important;
    display: block !important;
    float: left;
    height: inherit !important;
  }

  #v2-header {
    padding-left: 10px !important;
    // padding-right: 10px !important;

    #v2-header-content {
      margin-top: 5px;

      .breadcrumbs {
        margin-bottom: 10px;
      }
    }

    .v2-header-menu {
      float: right;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0px;
      margin-bottom: 10px;

      #v2-navitem-company {
        display: none;
      }

      .v2-navitem {
        margin-left: 18px !important;
      }

      #v2-navitem-config {
        margin-top: 15px;
      }

      #v2-navitem-notification {
        margin-top: 15px;
      }
    }
  }

  .v2-sidemenu-space-short {
    display: none;
  }

  .v2-sidemenu-large {
    color: #415364 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  .v2-sidemenu-large.link {
    display: inline-block;
    border: 1px solid #6cb6e3;
    padding: 13px 0;
    width: 235px;
    text-align: center;
    border-radius: 2rem;
    color: #6cb6e3 !important;
    font-size: 12px !important;
  }
}