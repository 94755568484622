.modalOverlay{
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  
  * {
    font-family: 'Source Sans Pro' !important;
  }

}
.modalContainer {
  width: 1130px;
  margin: 0 !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F2F5F8;
  border-radius: 0px 0px 8px 8px;

  .modalContent {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      margin: 0 auto;
      height: 539px;

      .leftSide {
          width: 100%;
          overflow-y: auto;
          padding: 18px 0;

          &::-webkit-scrollbar {
            width: 6px;
            margin-top: 5px;
            padding-top: 5px;
          }
          &::-webkit-scrollbar-thumb {
              background-color: #a8a8a8;
              border-radius: 4px;
          }
          &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
              border-radius: 4px;
          }
      }
  }

}
.headerModal{
  background-color: #6CB6E3;
  height: 48px;
  border-radius: 8px 8px 0px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 15px;

  p{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
  }
  svg{
    cursor: pointer;
  }
}

.videosList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.videosListButtons{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.videoButtom {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  padding-left: 20px;
  cursor: pointer;

  span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #415364;
      font-weight: 400;
      font-size: 14px;
  }

  gap: 10px;

  font-weight: 600;

  .videoButtomIcon {
      margin-top: 3px;

      path {
          fill: none !important;
      }
  }
}

.rightSide {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  .videoPlayer {
      margin-top: 18px;
      margin-bottom: 18px;
      width: 800px;
      height: 450px;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
  }
  span {
      font-size: 20px;
      font-weight: 600;
      color: #3B454F;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      padding-bottom: 20px;
      width: 800px;
  }

  border-left: 1px solid #CCD1D6;
  height: 100%;
  width: 100%;
}

.loadingArea{
  width: 100%;
  min-height: 400px;
  margin-left: 50%;
  margin-top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
      path{
          fill: none
      }
  }
}

#video-player-tutorial{
  width: 800px !important; 
  height: 450px !important;
  margin-top: 15px;
}

.noVideos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 22%;
  padding: 30px 0px 30px 0px;
  width: 100%;
  height: 100%;
  span{
      font-size: 20px;
      font-weight: 600;
      color: #3B454F;
      line-height: 25px;
      text-align: center;
      width: 800px;
      margin: 0;
  }
}

.videoButtomSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // width: 97%;
  
  background: #6CB6E3;
  padding: 10px 0 ;
  border-radius: 8px;
  // padding-right: 15px;
  margin-left: 10px;
  margin-right: 15px;
  :only-child {
      margin-left: 8px;
  }
  
  cursor: pointer;
  span {
     
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
  }
  gap: 10px;
  font-weight: 600;
  .videoButtomIcon {
      margin-top: 3px;
      fill: #ffffff !important;
      path {
          fill: none ;
          svg{
              
          }
      }
  }
}