@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

table {
  .row-pending-requests-user {
    display: inline-flex;
    width: 200px;
    img {
      border-radius: 20px;
      width: 40px;
      height: 40px;
    }
    p {
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .col-desc span {
    width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
