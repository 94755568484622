.v2-page-card {
  .containerTeam {
    #selectedUsers .userBox {
      height: auto !important;

      .user_cname {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .user-message {
      color: red;
      display: block;
    }
  }
}
