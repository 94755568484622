.modal-history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-history-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f5f8;
  width: 799px;
  max-width: 100%;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 16px 32px 0px #22272d29;

  * {
    font-family: 'Source Sans Pro';
  }

  b {
    font-weight: bold;
  }

  > div {
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6em;
  }

  .modal-history-header {
    font-weight: bold;
    background-color: #6cb6e3;
    color: #ffffff;
    text-align: left;
    padding: 8px 32px;
    position: relative;
    min-height: 36px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .modal-history-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;

    > div {
      width: 100%;
    }
  }

  .modal-history-close {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 32px;
  }

  .input-search-history {
    width: auto;
    position: relative;
    margin: 32px 32px 0 32px;

    .icon-search-history {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      color: #acb4ba;
    }

    input {
      width: 100%;
      padding: 14px 8px 14px 44px;
      min-height: 44px;
      border-radius: 4px;
      border: none;
      background-color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        color: #acb4ba;
      }
    }
  }

  .table {
    width: 100%;
    border-collapse: separate;
    background-color: #fff;
    margin: 0;

    th,
    td {
      border: none;
      padding: 8px 16px;
      text-align: left;
    }

    td {
      color: #415364;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      padding: 12px 16px;
    }

    thead {
      width: 100%;
      color: #fff;
      background-color: #6cb6e3;

      tr {
        th {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 14px;
          padding: 8px 16px;
          text-transform: uppercase;
        }

        th {
          border-left: 1px solid #6cb6e3;
          border-right: 1px solid #6cb6e3;

          &:last-child {
            border-right: 1px solid #6cb6e3;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          td {
            color: #415364 !important;
            cursor: default;
          }
        }
        td:first-child {
          border-left: 1px solid #ccd1d6;
        }

        td:last-child {
          border-right: 1px solid #ccd1d6;
        }

        td {
          border-bottom: 1px solid #ccd1d6;
          line-break: normal;
        }
      }
    }
  }

  .customer-history-loading {
    margin: 0 auto;
    margin-top: 24px;
  }
}
