.v2-dashboard-audit {
  .btn-standard-new {
    background-image: url('../../default-v2/img/icon-plus-white.svg') !important;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 0 0 0 20px;
    margin-right: 15px;
    width: 125px;
  }

  .v2-cardheader-filter .v2-filter-searching .v2-filter-searching-nav {
    // padding: 17.25px 0 0 0;
  }

  .v2-filter-searching-nav {
    right: 30px !important;
  }

  .container-cards > ul > li .contentRole .containerBar .progressBar {
    margin: 15px auto;
  }

  .right-menu .btn-menu {
    top: 15px;
  }

  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  .container-cards > ul > li .contentRole .period {
    font-weight: normal;
  }

  .container-cards > ul > li .contentRole .local {
    text-align: left;
    padding: 0 20px 5px;
    font-size: 0.875rem;
    vertical-align: middle;
  }

  .container-cards > ul > li {
    width: 31.33% !important;
    border: unset;
    display: unset;
    margin: 10px;

    .content {
      border: 1px solid #dbdbdb;
      border-radius: 2px;
    }
  }

  .grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto auto auto; //repeat(auto-fill, minmax(310px, 1fr));
    grid-auto-rows: 10px;
    margin-left: 1%;
  }

  .validade-selo {
    padding: 4px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1178px) {
  .v2-dashboard-audit {
    .container-cards > ul > li {
      width: 47% !important;
    }
  }
}

@media only screen and (max-width: 985px) {
  .v2-dashboard-audit {
    .container-cards > ul > li {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .v2-page-content {
    .v2-header-menu {
      // margin-right: 5%;
    }

    padding-left: 2px;
    padding-right: 2px;

    .card-body {
      padding: 2% 8% 0% 0% !important;
    }
  }
}

@media only screen and (min-width: 451px) and (max-width: 912px) {
  .v2-page-content {
    .v2-header-menu {
      // margin-right: 5%;
    }

    padding-left: 2px;
    padding-right: 2px;

    .card-body {
      padding: 2% 6% 0% 2% !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .v2-page-content {
    .v2-header-menu {
      // margin-right: 5%;
    }

    .v2-page-content.consultant-panel {
      .container-cards > ul > li {
        width: 50% !important;
      }
    }
    /*
    padding-left: 2px;
    padding-right: 2px;
    */
    padding: 12px 32px 32px 32px;
    .card-body {
      padding: 2% 2% 0% 2%;
    }
  }
}
