@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

@import '../node_modules/bootstrap/scss/tables';
@import 'assets/scss/reset.scss';
@import 'assets/scss/fonticon.scss';
@import 'assets/scss/main.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/sweetalert2.scss';
