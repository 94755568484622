@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.valid-audit-period {
  input[type='date'] {
    border: 0;
    font-family: $Source;
    @include font-size(15px);
    color: $clr-txt-content;
    margin-left: 5px;
  }
}

.alt-checkbox-audit {
  margin: 0 1% 20px !important;
}

.fr-box {
  margin-top: 10px;
}

.custom-checkbox {
  .alt-checkbox-label {
    margin-right: 10px;
  }

  input {
    ~.togglecheck {
      background: rgb(250, 250, 250);
    }

    &:checked {
      ~.togglecheck {
        // background-color: green;
        background-color: $primary;

        &:after {
          left: 15px;
        }
      }
    }
  }

  .togglecheck {
    // opacity: 1;
    // position: relative;
  }
}

input[type='file'] {
  pointer-events: inherit !important;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0px;
  background: transparent;
  background-size: contain;
  filter: grayscale(100%);
}

input[type='date']::-webkit-time-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: -20px;
  background: transparent;
  opacity: 0.5;
}

input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  display: none;
}

input[type='time'],
.form input[type='time'] {
  background: url(../../../assets/img/ico-time.png) no-repeat #fff 10px center;
  padding-left: 35px;
  background-size: 16px;
  filter: grayscale(100%);
  text-align: left;
  position: relative;
  @include font-size(14px);
}

input[type='date'],
.form input[type='date'] {
  background: url(../../../assets/img/ico-calendar.png) no-repeat #fff 10px center;
  padding-left: 35px;
  text-align: left;
  position: relative;
  @include font-size(14px);
}

.selectNorm {
  .react-select__menu {
    z-index: 2;
  }
}

.timeInputs {
  .containerInputs {
    display: flex;

    .mark {
      margin: 15px 10px 0 0;
    }

    input[type='time'] {
      flex: 1 20%;
      margin: 10px 10px 5px;
    }

    input[type='date'] {
      flex: 1 30%;
      margin: 10px 0 5px;
    }
  }

  input {
    width: 100%;
  }
}

.custom-padding {
  padding: 0;

  .alt-checkbox-label {
    margin-left: 0;
  }
}

button.scope-edit {
  margin-top: 20px;
  height: 40px;
  line-height: 1;
}

.requisiteScopeList {
  label {
    margin: 10px 0;
    cursor: pointer;

    .reqChild:last-child {
      margin-bottom: 0px;
    }

    span:before {
      content: '';
      transition: all linear 0.1s;
      background: #fff;
      width: 15px;
      height: 15px;
      display: inline-block;
      margin-right: 7px;
      border-radius: 20px;
      border: 1px solid $primary;
      box-shadow: 0 0px 0px 2px inset transparent;
      position: relative;
      top: 2px;
    }

    input {
      display: none;

      &:checked~span:before {
        background: $primary;
        box-shadow: 0 0px 0px 1px inset #ffffff;
      }
    }
  }

  .reqChild {
    padding-left: 15px;
  }
}

#table-emule {
  &.auditTable {
    // border: 1px solid red;

    .head-table {
      li {
        max-width: unset !important;
        min-width: unset !important;

        &.name {
          width: 39% !important;
        }

        &.status {
          width: 15% !important;
        }

        &.auditor {
          width: 20% !important;
        }

        &.evidences {
          width: 26% !important;
        }
      }
    }

    .body-table {
      ul {
        &> {
          border-top: 1px solid transparent;
        }

        border-top: 1px solid $clr-ui-border;

        li {
          .optionsContainerTable {
            padding-top: 8px;
            width: 61%;

            .status {
              width: 23% !important;
              min-width: unset !important;
              margin-top: 2px;
            }

            .responsible {
              width: 33% !important;
              min-width: unset !important;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-top: 2px;
            }

            .status {
              &:after {
                display: none;
              }

              .ico-select {
                background: url(../../../assets/img/ico-desc.png) 0 -10px no-repeat;

                height: 5px;
                color: transparent;
                position: relative;
                top: 8px;
              }

              &.status-34 {
                &:before {
                  background: url(../../../assets/img/concluido-ico.svg) #107010 center center no-repeat !important;
                }
              }

              &.status-35 {
                &:before {
                  background: url(../../../assets/img/pendente-ico.svg) #efc30a center center no-repeat !important;
                }
              }

              &.status-36 {
                &:before {
                  background: url(../../../assets/img/ico-partially-audit.svg) center center no-repeat !important;
                }
              }

              &.status-37 {
                &:before {
                  background: url(../../../assets/img/na-ico.svg) #777777 center center no-repeat !important;
                }
              }

              &.status-38 {
                &:before {
                  background: url(../../../assets/img/ico-not-audited.svg) center center no-repeat !important;
                }
              }

              &.load {
                &:before {
                  background: url(../../../assets/img/preloader-black.gif) no-repeat center center #fff !important;
                  background-size: 20px !important;
                  border: 0;
                }
              }
            }
          }

          border-bottom: 1px solid $clr-ui-border;

          .requisite-item-list-title {
            .inc-width {
              padding-left: 10px;
            }

            div:first-child {
              flex-basis: 70px;
            }
          }

          .subLevel {
            background: transparent;

            li {
              padding-left: 0;

              .requisite-item-list-title {
                .inc-width {
                  padding-left: 20px;
                }
              }

              border-bottom: 1px solid $clr-ui-border;

              &:last-child {
                border-bottom: 0;
              }

              .subLevel {
                li {
                  .requisite-item-list-title {
                    .inc-width {
                      padding-left: 40px;
                    }
                  }
                }
              }
            }
          }

          .view-bt {
            width: 33px;
            height: 39px;
            border-right: 1px solid #ccc;
            padding: 10px 10px 10px 0;
            margin-right: 4px;
            left: 0;
            top: 10px;
            display: inline-table;

            i {
              @include font-size(16px);
              color: #fff;
              border-radius: 20px;
              padding: 5px;
              background: $clr-ui-border;
              padding: 7px;
              position: relative;
              top: 4px;
            }
          }

          span.optionsHover {
            opacity: 1;
            background: transparent;
            width: 215px;
            margin-top: -7px;

            b.counter {
              display: block;
              position: absolute;
              bottom: -6px;
              border-radius: 13px;
              height: 15px;
              padding: 0px 2px;
              left: 20px;
              line-height: 1.5;
              background: $alert;
              @include font-size(10px);
              font-weight: bold;
              color: $primary-lighter;
              width: 15px;
            }

            .menuContextEvid {
              @include font-size(12px);
              border: 1px solid #afaba5;
              position: absolute;
              display: none;
              font-weight: normal;

              width: 110px;
              background: #f8f8f8;
              z-index: 90;

              line-height: 0;

              li {
                margin: 0;
                margin: 0;
                padding: 3px;
                position: relative;
                color: $primary-darker;
                padding-right: 17px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: left;
                white-space: nowrap;
                display: block;

                &.addLink {
                  color: $clr-txt-note;

                  .add {
                    font-weight: bold;
                    @include font-size(17px);
                    line-height: 0;
                    position: relative;
                    top: 2px;
                  }
                }

                .linkRemove {
                  position: absolute;
                  right: 3px;
                  top: 5px;
                  padding: 4px 0px;
                  display: block;
                  background: #e8941a;
                  border-radius: 10px;
                  color: $primary-lighter;
                  @include font-size(16px);
                  min-width: unset;
                  width: 12px !important;
                  height: 12px;
                  line-height: 0.1;
                  text-align: center !important;
                }

                &:last-child {
                  border-bottom: 0;
                }
              }
            }

            >div {
              display: inline-block;
            }

            a {
              border: 1px solid $clr-ui-border;
              color: $clr-txt-note;
              background: #fff;
              padding: 6px;
              width: 33px;
              height: 33px;

              &.speak-audit {
                border: 0;
                border-radius: 0;
                margin-left: 15px;

                &:after {
                  display: none !important;
                }

                &:before {
                  content: '';
                  display: block;
                  width: 1px;
                  height: 40px;
                  background: #cccccc;
                  position: absolute;
                  left: -10px;
                  top: 0;
                }

                .icon-speak {
                  &:before {
                    // content: 'as';
                    background: url(../../../assets/img/ico-speak.svg) center center no-repeat;
                    // width: 20px;
                    // height: 20px;
                    // display: block;
                    // transform: scale(1.4);

                    content: '';

                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    transform: scale(1.8);
                    background-size: contain;
                  }
                }
              }

              &.comment-audit {
                margin-left: 10px;
                position: relative;

                &:before {
                  content: '';
                  width: 1px;
                  display: block;
                  background: red;
                  height: 33px;
                  position: absolute;
                  left: -8px;
                  top: 0;
                }
              }

              &:after {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.audit-editor {
  .ck-file-dialog-button {
    display: none;
  }
}

#section-desc {
  max-width: 100%;

  .titlefinalnoteincluded {
    float: left;
  }

  .dfinalnoteincluded {
    float: right;

    width: 80%;

    padding-right: 15px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: right;
  }
}

@media screen and (max-width: 600px) {
  .v2-dashboard-audit {
    .v2-page-cardheader {
      height: 90px !important;
    }

    .timeInputs {
      width: inherit !important;

      .containerInputs {
        display: block;

        #audiDexecutionbegin,
        #audiDexecutionend {
          width: 55%;
          float: left;
        }

        #audiDexecutionbeginTime,
        #audiDexecutionendTime {
          width: 35%;
          float: left;
        }

        .mark {
          width: 100%;
          float: left;
        }
      }
    }
  }
}