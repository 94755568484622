@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLight.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLightItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBoldItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
