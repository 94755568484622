@import '../../../assets/scss/variables.scss';

.block {
  display: flex;
  align-items: stretch;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  float: left;
  clear: both;
  opacity: 0.4;
  cursor: pointer;

  &.centered {
    justify-content: center;
  }

  &.warning-block {
    .block-text {
      background: #f9ad13 !important;
    }
  }

  &:hover,
  &.enabled {
    opacity: 1;
  }

  .block-check {
    display: flex;
    align-items: center;
    width: 40px;
  }
  .block-check-checkbox {
    border-radius: 5px;
  }

  input[type='checkbox'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
    content: none;
  }

  input[type='checkbox']:before {
    text-align: center;
    content: '✔';
    font-size: 15px;
    color: $clr-v2-white !important;
    background: $clr-v2-white;
    display: block;
    width: 16px;
    height: 16px;
    border: 3px solid $clr-v2-heavy-grey;
    margin-right: 7px;
    border-radius: 3px;
  }

  &.enabled {
    input[type='checkbox']:before {
      border: 3px solid $clr-v2-soft-blue;
      background: $clr-v2-soft-blue;
    }
  }

  .block-title {
    display: flex;
    align-items: center;
    width: 200px;
    background-color: $clr-v2-soft-grey;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .block-title-icon {
      float: left;
      margin-left: 20px;
      width: 30px;
      height: 30px;
    }

    .block-title-line1 {
      float: left;
      margin-left: 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .block-title-line2 {
      float: left;
      margin-left: 15px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .block-text {
    display: flex;
    align-items: center;
    width: calc(95% - 241px);
    background-color: $clr-v2-soft-blue;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $clr-v2-white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    font-weight: normal;
    background-position-x: 8px;
    background-position-y: 5px;

    p {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      font-size: 14px;
      overflow-wrap: break-word;
    }

    .block-text-container {
      margin-left: 5px;
      padding-left: 20px;
      border-left: 2px dotted;
      text-align: justify;
    }
  }
}
