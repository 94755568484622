@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-config-footer {
  .config-footer-msg {
    margin-bottom: 40px;
  }

  form {
    fieldset {
      position: relative;
      margin-bottom: 20px;
      border: 2px solid #F2F5F8;
      border-radius: 4px;

      .config-head {
        legend {
          font-weight: $bold;
          position: absolute;
          background: #F2F5F8;
          padding: 10px 20px;
          width: 100%;
        }
      }

      .config-body {
        display: flex;
        flex-wrap: wrap;
        padding: 50px 0 10px 15px;

        label {
          width: 350px;
        }

        .alt-checkbox {
          font-size: $fontsize-v2-small;
          color: $clr-v2-soft-red;
          font-family: $Source;
          width: 150px;
          margin: 0;

          .togglecheck {
            background-color: $clr-v2-soft-red;
            width: 31px;
            height: 10px;
            top: -3px;
          }

          .togglecheck::after {
            width: 16px;
            height: 16px;
            border: 1px solid #e2e5e8;
            box-shadow: 1px #00000066;
            top: -4px;
          }

          .togglecheck.selected {
            background-color: $clr-v2-soft-blue;
          }

          .togglecheck.selected::after {
            background-color: $clr-v2-soft-blue;
            border-color: $clr-v2-soft-blue;
            left: 15px;
          }

          .alt-checkbox-label {
            color: $clr-v2-heavier-grey;
          }
        }
      }
    }
  }
}
