@import '../../../assets/scss/variables.scss';
.cardheader-container{
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
 
  .cardheader-left-side{
    margin-left: 15px;
   // margin-top: 10px;
    svg{
     cursor: pointer;
    }
    &.specific-folder {
      margin-left: 50px;
    }
  }
}
.v2-page-cardheader {
  height: 64px;
  width: 100%;
  background-color: $clr-v2-soft-grey;
  position: relative;
  border-radius: 5px;

  .v2-page-btn-goback {
    display: block;
    position: absolute;
    width: 50px;
    height: 57px;
    padding: 18px 25px;
    cursor: pointer;
  }

  .btn-menu {
    padding: 21px 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .v2-page-cardheader {
    .v2-page-btn-goback {
      padding: 18px 20px !important;
    }

    .v2-filter-notsearching-left .containerBar {
      padding: 0 !important;
      width: 200px !important;
    }
  }
}
