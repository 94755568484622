.credit-card-logo {
  width: 52px;
  height: 28px;
  background: white;
  border-radius: 5px;
  margin: 0 0.5rem;
}

i.icon-credit-card {
  margin: 0 0.5rem;
}
