@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.folder-card {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 300px;
  border-radius: 8px;
  height: 304px;
  border: 1px solid #ccd1d6;
  overflow: hidden;
}
.folder-card__header {
  height: 40px;
  padding: 8px 16px;
  background-color: $clr-v2-soft-blue;
  color: white;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  & span {
    font-size: 24;
  }
}
.folder-card__body {
  flex: 1;
  & ul {
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & a {
      flex: 1;
    }
    & li {
      height: 100%;
      display: flex;
      align-items: center;
      &:first-child {
        flex: 0;
        font-size: 14px;
        line-height: 100%;
        font-weight: 700;
        color: $clr-v2-soft-red;
        padding: 8px 16px;
      }

      border-bottom: 1px solid $clr-v2-soft-blue;
      & > div {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        height: 60px;

        h2 {
          font-weight: 700;
          font-size: 14px;
          color: #22272d;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-weight: 500;
          color: #5a646e;
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.folder-card__footer {
  padding: 16px;
  & button {
    width: 100%;
    height: 30px;
    border-radius: 500px;
    color: $clr-v2-soft-blue;
    font-weight: 700;
    background-color: transparent;
    border: 2px solid $clr-v2-soft-blue;
    cursor: pointer;
  }
}
