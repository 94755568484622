@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-reading-document-delegates {
  .v2-filter-searching {
    position: relative;
    .v2-filter-searching-nav {
      top: 18px;
      right: 15px !important;
      padding: 0;
    }
  }

  .report-table {
    .col-doc-name,
    .col-user-name {
      span {
        display: block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
