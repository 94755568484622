#page-404 {
  background-color: rgb(247, 247, 247);

  #page-404-logo {
    padding-top: 20px;
    padding-left: 40px;
    width: 100%;
  }

  #page-404-image {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  #page-404-text {
    width: 100%;
    text-align: center;
    padding-top: 20px;

    h1 {
      color: #425465;
      font-weight: bold;
      font-size: 55px;
    }

    h2 {
      color: #6eb7e4;
      font-weight: bold;
      font-size: 50px;
      line-height: 50px;
    }

    p {
      font-size: 18px;
      margin-top: 20px;
      color: #777778;
    }
  }

  #page-404-button {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;

    .btn {
      text-transform: none;
    }
  }
}
