.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 20px;
}

.grid-item-first {
  background-color: rgba(255, 255, 255, 0.8);
  /* top | right | bottom | left */
  padding: 0 20px 0 0;
  font-size: 30px;
  text-align: center;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 20px 0 0;
  font-size: 30px;
  text-align: center;
}

.dateFilterWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.3rem;
  position: relative;

  .selectCustom {
    width: 500px;
    height: 50px;
  }

  .selectCustomStatusDoc {
    width: 200px;
    height: 50px;
  }

  @media screen and (max-width: 1400px) {
    width: 29.7rem;
    .dateFilterTitleWithShortCutCustom {
      margin-left: 0.5rem;
    }
  }
}

.dateFilterTitle {
  font-size: 0.9rem;
  font-weight: 700;
  color: #415364;
}

.dateFiltersContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 43px;

    .button {
      background-color: #ffffff;
      padding: 12px 1.35rem;
      border-top: 1px solid #ccd1d6;
      border-bottom: 1px solid #ccd1d6;
      border-left: 1px solid #ccd1d6;
      border-right: none;
      color: #5a646e;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-align: center;
      text-overflow: ellipsis;
      height: 100%;
      cursor: pointer;

//      width: Hug (77px)px;
  //    height: Fixed (44px)px;
    //  padding: 8px 20px 8px 20px;
      //gap: 8px;
     // opacity: 0px;


      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: 1px solid #ccd1d6;
      }

      &:hover,
      &.active {
        background-color: #6cb6e3;
        border: 1px solid #6cb6e3;
        border-right: none;
        color: #ffffff;
      }
    }
  }

  .dateInputContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;

    label {
      margin: 0 !important;
      font-weight: 400 !important;
    }

    @media screen and (max-width: 796px) {
      & {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 796px) {
    .buttonsContainer {
      gap: 2px;

      .button {
        border-radius: 4px !important;
        border: 1px solid #ccd1d6;
        padding: 8px 12px;
        font-size: 12px;
      }
    }

    .dateInputContainer {
      flex-direction: column;
    }
  }
}

.dataFilterContainterWithShortCutCustom {
  @media screen and (max-width: 1300px) {
    padding-bottom: 0px;
  }
}

.noPadding {
  @media screen and (max-width: 1300px) {
    padding-bottom: 0px;
  }
}
