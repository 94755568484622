.date-filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.3rem;
  position: relative;

  .date-filter-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: #415364;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;

    label {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 8px;
      margin: 0 !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #415364;
      flex: 1;

      input {
        min-height: 44px;
        width: 100%;
        padding: 0 16px 0 34px;
        border: 1px solid #6cb6e3;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        color: #415364;
      }
    }
  }
}

.export-logs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  label {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    color: #415364;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 43px;
    width: 100%;

    button {
      background-color: #ffffff;
      padding: 12px;
      border-top: 1px solid #ccd1d6;
      border-bottom: 1px solid #ccd1d6;
      border-left: 1px solid #ccd1d6;
      border-right: none;
      color: #5a646e;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-align: center;
      text-overflow: ellipsis;
      height: 100%;
      cursor: pointer;
      flex: 1;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: 1px solid #ccd1d6;
      }

      &:hover,
      &.active {
        background-color: #6cb6e3;
        border-color: #6cb6e3;
        color: #ffffff;
      }
    }
  }
}
