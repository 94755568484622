@import '../../../assets/scss/variables.scss';

#container {
  // menu-expanded {
  //   .v2-header {
  //     width: calc(100% - 203px);
  //     border: 1px solid red;
  //   }
  // }
  #v2-header {
    // float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    width: 100%;
    margin-bottom: 11px;
    // margin-top: 62px;
    padding: 12px 32px 0px 32px;
  }

  .cUser {
    display: none;
  }

  .v2-header-title {
    font-size: 40px;
    font-weight: 700;
    color: $clr-v2-soft-red;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v2-header-title-description-wrapper {
    display: inline;

    span {
      margin: 0 5px;
      font-weight: bold;
    }

    h1,
    h5 {
      display: inline;
    }

    h5 {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: text-bottom;
    }
  }

  .new-notifications {
    span {
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $alert;
    }
  }

  #v2-header-content {
    width: 75vw;
  }

  #v2-header-content .flex {
    // width: 70%;
    display: flex;
    align-items: center;
    // margin: 3px 0 0 0;
  }

  #v2-header-content .flex img {
    margin-right: 20px;
    height: 40px;
  }

  @media screen and (max-width: 1023px) {
    #v2-header-content {
      width: 67.5vw;
    }
  }

  @media screen and (max-width: 767px) {
    #v2-header-content {
      width: 60vw;
    }

    .v2-header-title {
      font-size: 30px;
    }

    #v2-header-content .flex img {
      margin-right: 20px;
      height: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    #v2-header-content {
      width: 83vw;
    }

    #v2-header {
      padding-left: 0 !important;
      padding: 48px 32px 0px;
    }

    .v2-header-title {
      // font-size: 24px;
    }
  }

  .v2-selected-header-menu {
    background-color: $primary;
  }

  #v2-navitem-company {
    width: 100%;
    cursor: unset;
  }

  .v2-navitem-company-name {
    width: unset !important;
  }

  .v2-navitem:nth-child(3) {
    float: right;
    margin-left: 16.28px;
    font-size: 14px;
    color: $clr-v2-soft-red;
  }

  // .v2-navitem:nth-child(3):hover {
  //   font-weight: 700;
  // }

  .v2-header-menu {
    width: auto;
    float: right;
    margin-top: 25px;
    margin-bottom: 35px;
    position: relative;
    top: 22px;

    .v2-header-navitems {
      //display: inline-flex;
      text-align: right;
      min-width: 54px;

      #v2-navitem-config {
        display: inline-block;
      }

      #v2-navitem-notification {
        display: inline-block;
        float: right;
        clear: both;
      }

      #v2-navitem-company {
        clear: both;
        margin-top: 12px;
      }

      .v2-navitem:first-child {
        margin-left: 0 !important;
        margin-right: 17px;
        position: relative;

        div {
          position: absolute;
          right: 0px;
          padding: 2px 0;

          .config-menu {
            width: 280px;
            background-color: white;
            font-family: $Source;
            z-index: 50;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            .navlink-version {
              border-top: 1px solid $card-borders;

              &:hover {
                background-color: white;
                color: $clr-txt-content;
              }
            }

            li {
              padding: 10px 30px;

              a {
                display: block;
              }

              &:hover {
                background-color: $clr-v2-soft-grey;
                color: $primary;
              }
            }
          }
        }
      }
    }

    .v2-header-navitems img {
      width: 18.3px;
      height: 18.3px;
    }
  }
}

@media screen and (max-width: 600px) {
  #v2-header-content {
    width: 100% !important;
    margin: 0 !important;
  }

  #container {
    .v2-header-title {
      color: #fff;
      margin-top: -1rem;
    }

    .cUser {
      margin-top: 0.5rem;
      display: block;
    }
  }
  #v2-header {
    background-color: #6cb6e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 32px 16px;
    padding-bottom: 8px;
  }
  .v2-header-menu {
    display: flex;
    margin: 0;
    height: 100%;
    position: static !important;

    .cUser {
      display: inline-block;
      position: absolute;
      top: 3.8rem;
      // left: 0;
      right: 2.2rem;
      color: #415364;
      font-size: 14px;
      font-weight: 400;
    }

    ul {
      text-align: left;
      list-style: none;
      margin-bottom: 1rem;
      display: flex;

      li {
        margin: 0 !important;

        &:last-child {
          display: none !important;
        }

        a {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }

      #v2-navitem-notification {
        margin: 0 !important;
      }
    }
  }

  .flex {
    display: flex;
    width: 100%;
    margin: 0;
    justify-content: center;

    img {
      display: none;
    }
    h1 {
      color: #fff;
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 100% !important;
      margin-left: 4rem;
      margin-bottom: 1rem;
    }
  }

  .config-menu {
    ul {
      display: flex;
      flex-direction: column;
    }
  }
}
