// Cores
$primary: #53b7e8;
$alert: #c66;

$clr-txt-title: #444;
$clr-txt-content: #777;

$clr-ui-border: #ccc;
$clr-ui-bkg: #f8f8f8;

$clr-social-fb: #3b5998;
$clr-social-gl: #dd4b39;

$global-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

// Fontes
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');
$Source: 'Source Sans Pro', Helvetica, Arial, sans-serif;

$light: 300;
$regular: 400;
$bold: 700;

$sz-title: 36px;
$sz-content: 18px;
$sz-note: 14px;

#register {
  width: 100%;
  padding: 181px 4% 100px;
  height: 100vh;
  background: url(../img/bkg.jpg);
  margin: 0;
  max-width: none;

  .wrapper-login {
    max-width: 430px;
  }

  header {
    overflow: hidden;
    margin-bottom: 60px;

    .logo {
      float: left;
    }

    h1 {
      margin-top: 10px;
      float: right;
      font-size: 26px;
      color: $clr-txt-title;
    }
  }

  .form {
    input {
      margin: 0 0 20px;

      &::placeholder {
        color: $clr-txt-content;
        opacity: 1;
      }
    }

    .g-recaptcha {
      margin-bottom: 20px;
    }

    .sc-login {
      float: left;
      max-width: 50%;
      margin-top: 5px;
      font-size: 16px;

      a {
        display: block;
        color: $primary;
      }
    }

    .btn {
      margin: 0 0 20px;
      float: right;
    }

    .ou {
      clear: both;
      position: relative;
      text-align: center;
      margin-bottom: 20px;

      &:before,
      &:after {
        content: ' ';
        display: block;
        width: 45%;
        height: 1px;
        background-color: $primary;
        position: absolute;
        top: 50%;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .btn-social {
      display: block;
      width: 100%;
      height: 50px;
      border: 1px solid $clr-ui-border;
      border-radius: 3px;
      background: no-repeat 15px center;
      text-align: center;
      font-size: 16px;
      line-height: 50px;
      margin-bottom: 20px;

      &.fb {
        background-image: url(../img/ico-facebook.png);
        color: $clr-social-fb;
        border-color: $clr-social-fb;
      }

      &.go {
        background-image: url(../img/ico-google.png);
        color: $clr-social-gl;
        border-color: $clr-social-gl;
      }
    }
  }
}

.externalRegister {
  .containerRegister {
    padding: 30px;

    h1 {
      font-size: 36px;
      color: $clr-txt-title;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .registerForm {
      display: block;
      min-height: 500px;
      width: 70vw;
      margin: 25px auto 60px;
      padding: 40px 60px 80px;
      border: 1px solid rgba(204, 204, 204, 0.7);
      border-radius: 3px;
      box-shadow: 4px 6px 12px 0px rgba(204, 204, 204, 0.7);
      background: #fff;

      .checkboxWrapper {
        label {
          a {
            text-decoration: underline;
            color: $primary;
          }
        }
      }

      .centered-text {
        text-align: center;
      }
    }
  }
}

.box-creating-database {
  margin: 5% auto 0;
  padding: 40px 20px 30px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  border-radius: 3px;
  box-shadow: 4px 6px 12px 0px rgba(204, 204, 204, 0.7);
  background: #fff;
  width: 55%;

  h1 {
    font-size: 36px;
    color: $clr-txt-title;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 959px) {
  .box-creating-database {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 540px) {
  .externalRegister {
    .containerRegister {
      padding: 30px 10px;
      text-align: center;
      .registerForm {
        width: 100%;
        padding: 10px;
      }
    }
  }

  #register {
    width: 100%;
    padding: 60px 5%;

    header {
      margin-bottom: 30px;
      text-align: center;

      .logo {
        width: 100%;
      }

      h1 {
        width: 100%;
      }
    }

    .form {
      text-align: center;

      .sc-login {
        max-width: 100%;
        width: 100%;
        margin: 0 0 10px;
      }

      .btn {
        margin: 0 0 20px;
        float: none;
        width: 100%;
      }

      .ou {
        clear: both;
        position: relative;
        text-align: center;
        margin-bottom: 20px;

        &:before,
        &:after {
          content: ' ';
          display: block;
          width: 45%;
          height: 1px;
          background-color: $primary;
          position: absolute;
          top: 50%;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }

      .btn-social {
        display: block;
        width: 100%;
        height: 50px;
        border: 1px solid $clr-ui-border;
        border-radius: 3px;
        background: no-repeat 15px center;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
        margin-bottom: 20px;

        &.fb {
          background-image: url(../img/ico-facebook.png);
          color: $clr-social-fb;
          border-color: $clr-social-fb;
        }

        &.go {
          background-image: url(../img/ico-google.png);
          color: $clr-social-gl;
          border-color: $clr-social-gl;
        }
      }
    }
  }
}
