@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-trash-bin {
  .v2-cardheader-filter {
    .v2-filter-notsearching {
      display: flex;
    }

    .v2-filter-searching {
      position: relative;
    }
  }

  .v2-cardheader-filter {
    .v2-select {
      width: 50px;
      .react-select__menu {
        width: 200px;
        margin-top: 0;
        margin-left: 8px;
      }
      .react-select__control {
        margin-left: 5px;
        width: 50px;
      }
    }
    .v2-navitem {
      margin-left: 8px !important;
    }
  }

  .v2-filter-iconSearch {
    margin-left: 14px !important;
  }

  .btn-empty-trashbin {
    width: 120px;
    padding: 0 !important;
  }

  .disabled-btn-trashbin-confirm {
    pointer-events: none;
    opacity: 0.6;
  }
  .trash-bin {
    display: flex;
    flex-wrap: wrap;

    .folder-items {
      cursor: pointer;
    }

    .doc-items,
    .folder-items {
      width: 235px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 20px 20px 0;
      border: 1px solid #f2f5f8;

      .doc-status,
      .folder-status {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        img {
          width: 45px;
          height: 45px;
        }
      }

      .block-right-menu {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 180px;
        z-index: 1;

        .btn-menu {
          display: block;
          cursor: pointer;
          position: absolute;
          transform: scale(0.7) rotate(90deg);
          top: 8px;
          right: -18px;
          padding: 10px 10px;

          span,
          &:before,
          &:after {
            display: block;
            float: left;
            content: ' ';
            width: 6px;
            height: 6px;
            background-color: $clr-v2-soft-red;
            border-radius: 50%;
            margin: 0 3px;
            transition: $transition;
          }

          &.active span,
          &.active:before,
          &.active:after,
          &:hover span,
          &:hover:before,
          &:hover:after {
            background-color: $clr-ui-border;
          }
        }

        .submenu {
          display: none;
          width: 165px;
          padding: 10px 20px 5px;
          margin-top: 40px;
          background-color: #fff;
          text-align: center;
          border: 1px solid #f2f5f8;

          li {
            span {
              display: block;
              padding: 5px 0;
              margin-bottom: 2px;
              transition: $transition;
              cursor: pointer;

              &:hover {
                opacity: $opacity;
              }
            }
          }

          li:nth-child(2) {
            color: $alert-darker;
          }
        }
      }

      .doc-status,
      .folder-status {
        width: 100%;

        span {
          margin: 0 25%;
        }
      }

      .doc-info,
      .folder-info {
        width: 100%;
        text-align: center;
        background-color: #f2f5f8;
        padding: 5px 0;

        p:first-child {
          font-weight: bold;
          font-size: 15px;
          padding: 0 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p:nth-child(2) {
          font-size: 13px;
        }
      }
    }
  }
}
