.fr-view,
#editor-view,
#document-text {

  ol {
    display: block !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
  }

  ul {
    display: block !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
  }

  p,
  li {
    color: #000000;

    em {
      font-style: italic !important;
    }
  }

  span {
    color: inherit;
  }

  strong,
  b {
    font-weight: bold !important;
  }
}

.fr-quick-insert {
  z-index: 99999;
  margin-left: 27px;
}

.fr-qi-helper {
  padding-left: 27px !important;
}


  .fr-box.fr-document .fr-wrapper .fr-element {
    width: auto !important;
  }

