@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-page-card .btn {
  padding: 0 30px;
}

.v2-extractindex {
  .extractindex-title {
    font-weight: bold;
    margin-left: 5px;
    color: $clr-v2-soft-red;
  }

  button {
    font-size: 13px;
    color: $primary;
    background-color: white;
    height: 35px;
    line-height: 0;
  }

  button:disabled,
  button[disabled]
  {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .extractindex-container-info {
    display: flex;

    div {
      background-color: #F2F5F8;
      width: 350px;
      height: 130px;
      margin: 30px 15px 30px 0;
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      p {
        font-weight: 450;
        font-size: 30px;
        color: $clr-v2-soft-red;
        width: 100%;
        text-align: center;

        span {
          font-size: 23px;
        }
      }
    }
  }

  .extractindex-container-extract {
    display: flex;

    .extractindex-last,
    .extractindex-billing {
      background-color: #F2F5F8;
      width: 500px;
      margin-right: 15px;
      padding: 10px 0 10px 25px;
    }

    .extractindex-last {
      p:first-child {
        font-weight: bold;
        color: $clr-v2-soft-red;
      }

      p span {
        font-weight: bold;
        color: $clr-v2-soft-red;
      }

      line-height: 35px;

      button {
        margin-top: 10px;
        margin-left: 0;
      }
    }

    .extractindex-billing {
      .extractindex-billing-header {
        display: flex;

        h4 {
          font-weight: bold;
          color: $clr-v2-soft-red;
          padding-top: 5px;
          padding-right: 50px;
        }

        button {
          height: 28px;
        }
      }

      .extractindex-billing-body {
        line-height: 2.5;

        p span {
          font-weight: normal;
        }
      }

      p span {
        font-weight: bold;
        color: $clr-v2-soft-red;
      }

      line-height: 25px;
    }
  }

  @media screen and (max-width: 1200px) {
    .extractindex-container-info,
    .extractindex-container-extract {
      flex-wrap: wrap;
      justify-content: center;
    }

    .extractindex-container-info {
      div {
        margin: 20px 15px 0 0;
      }
    }

    .extractindex-container-extract {
      .extractindex-last,
      .extractindex-billing {
        margin-bottom: 15px;
      }

      .extractindex-last {
        margin-top: 20px;
      }
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50vh;

  img {
    width: 64px;
    height: 64px;
  }
}
