@import '../../../assets/scss/variables.scss';

i {
  &.icon-editing-nonchecked {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../img/icon-edit.svg) $clr-v2-heavy-grey center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-comment-nonchecked {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../img/icon-comment.svg) $clr-v2-heavy-grey center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }

  &.icon-published-nonchecked {
    position: relative;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 30px;
      background: url(../img/icon-click.svg) $clr-v2-heavy-grey center center no-repeat !important;
      position: unset;
      display: inline-block;
    }
  }
}

.header-document {
  float: left;
  margin-left: 70px;
  margin-top: 13px;

  .header-document-edit,
  .header-document-review,
  .header-document-publish {
    float: left;
    margin-right: 30px;
  }

  .hd-line-none,
  .hd-line-half,
  .hd-line-full
  {
    float: left;
    position: absolute;
    height: 3px;
    width: 50px;
    top: 26px;
    margin-left: 20px;
  }

  .hd-line-none {
    background-color: $clr-v2-heavy-grey;
  }

  .hd-line-half {
    background: linear-gradient(
      to right,
      $primary-icon 0%,
      $primary-icon 50%,
      $clr-v2-heavy-grey 50%,
      $clr-v2-heavy-grey 100%
    );
  }

  .hd-line-full {
    background-color: $primary-icon;
  }
}
