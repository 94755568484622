@import '../../../assets/scss/variables.scss';

.v2-page-card {
  .notification-list {
    .message {
      width: 100%;
      padding-left: 20px;
      word-break: break-all;
    }
  }

  .notification-header {
    a,
    .notification-no-route {
      text-transform: uppercase;
      font-weight: normal;
      color: $clr-v2-heavier-grey;
      padding-left: 20px;
      font-size: 16px;
    }

    .notification-no-route {
      font-weight: normal !important;
      color: $clr-v2-heavier-grey !important;
    }
  }

  .notification-read {
    a,
    p,
    pre {
      color: $primary;
    }

    a {
      font-weight: bold !important;
    }
  }

  .notification-hover {
    &:hover {
      a {
        color: $primary;
        font-weight: bold !important;
      }
    }
  }
}
