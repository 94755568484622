@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-user {
  .btn-filter-user {
    padding-left: 55px;
    width: 200px;
  }

  .btn-resend-invitation {
    width: 180px;
  }

  .btn-user-new {
    background-image: url('../../default-v2/img/icon-plus-white.svg') !important;
    background-repeat: no-repeat;
    background-position: 30px center;
    padding-left: 42px;
    width: 160px;
  }

  .v2-cardheader-filter {
    .v2-filter-notsearching {
      display: flex;
    }

    .v2-filter-searching {
      position: relative;

      .v2-filter-searching-nav.close {
        padding: 0;
        margin: 17px 9px 0 0;
      }
    }
  }

  .v2-cardheader-filter {
    .v2-select {
      .react-select__menu {
        width: 200px;
        margin-top: 0;
        margin-left: 20px;
      }

      .react-select__control {
        width: 60px;
      }
    }
  }

  .empty-user {
    width: 100%;
    margin: 50px 0 50px;
    text-align: center;
    line-height: 30px;
    @include font-size($sz-content);

    strong {
      display: block;
      color: $clr-txt-title;
      font-weight: $bold;
      @include font-size(20px);
    }
  }

  table {
    table-layout: fixed;
  }

  .table-user {
    text-align: left;
    border-collapse: collapse;

    thead th {
      border-top: none;
      border-bottom: 2px solid gray;
    }

    tbody tr {
      .active {
        border-top: none;
        border-bottom: 1px solid $clr-txt-note;
        cursor: unset;
      }
      &.deleted {
        border-top: none;
        border-bottom: 1px solid $clr-txt-note;
        cursor: unset;
        background-color: lightgray;
      }
    }

    th,
    td {
      padding: 0.8em 0.1em;
      vertical-align: middle;
      border-top: none;
    }

    .column-user-name {
      width: 22%;
    }

    .column-teams {
      width: 11%;
    }

    .column-created-by,
    .column-removed-by {
      width: 13%;
    }

    .column-last-acess,
    .column-removal-date {
        width: 14%;
    }

    .user-info {
      padding: 7px;
      overflow: hidden;
      position: relative;
      width: 320px;
      .avatar {
        float: left;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;

        &.pending {
          &:before {
            content: '!';
            position: absolute;
            width: 17px;
            height: 17px;
            display: block;
            background: #c66;
            margin-top: 76%;
            color: #fff;
            text-align: center;
            border-radius: 20px;
            font-weight: bold;
            @include font-size(12px);
          }
        }

        img {
          display: block;
          width: 50px;
          height: 50px;
          border-radius: 80px;
        }
      }

      .info {
        float: left;
        max-width: 300px;
        padding-top: 15px;

        h2 {
          font-weight: $bold;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 135px;
        }

        p {
          color: $primary;
          font-size: $sz-note;
        }
      }
    }

    .badges {
      float: unset;
      margin-left: -8px;
      padding-top: 8px;
      width: 147px;
      .team-badge {
        display: inline-block;
        border: 1px solid #e2e2e2;
        background: #F2F5F8;
        @include font-size(12px);
        padding: 5px 10px;
        border-radius: 30px;
        margin: 1%;
        word-wrap: normal;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (max-width: 1330px) {
          max-width: 120px;
        }
        @media screen and (max-width: 1180px) {
          max-width: 110px;
        }
        @media screen and (max-width: 1080px) {
          max-width: 90px;
        }
        @media screen and (max-width: 650px) {
          max-width: 140px;
        }
      }
    }

    .create-by,
    .removed-by,
    .last-acess {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 120px;
      text-align: justify;
      @media screen and (max-width: 1200px) {
        width: 100px;
      }
    }

    .last-acess {
      width: 150px;
      @media screen and (max-width: 1200px) {
        width: 120px;
        white-space: pre-wrap;
      }
    }

    .action-user-item {
      width: 150px;
      .buttons {
        top: 5px;
        right: 5px;

        button {
          display: block;
          float: left;
          width: 36px;
          height: 36px;
          margin-left: 10px;
          background-color: $primary;
          border-radius: 50%;
          border: 0;
          cursor: pointer;
          position: relative;

          &.edit {
            &:before {
              font-family: 'icomoon';
              font-size: 17px;
              content: '\e90a';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }

          &.remove {
            background-color: $alert;
            &:before {
              font-family: 'icomoon';
              @include font-size(12px);
              content: '\e919';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }

          &.resend {
            &:before {
              font-family: 'icomoon';
              font-size: 21px;
              content: '\e912';
              position: absolute;
              top: 16px;
              left: 18.5px;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }

          &:disabled, &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      width: 1032px;
      .badges .team-badge {
        max-width: 140px !important;
      }

      .create-by,
      .removed-by {
        width: 120px !important;
      }
      .last-acess {
        width: 150px !important;
        white-space: nowrap !important;
      }
    }
  }
}
