@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.v2-group {
  .btn-group-new {
    background-image: url('../../default-v2/img/icon-plus-white.svg') !important;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 0 0 0 20px;
    margin-right: 15px;
    width: 120px;
  }

  .v2-cardheader-filter {
    .v2-filter-notsearching {
      display: flex;
    }

    .v2-filter-searching {
      position: relative;

      .v2-filter-searching-nav.close {
        padding: 0;
        margin: 17px 9px 0 0;
      }
    }
  }

  .empty-group {
    width: 100%;
    margin: 50px 0 50px;
    text-align: center;
    line-height: 30px;
    @include font-size($sz-content);

    strong {
      display: block;
      color: $clr-txt-title;
      font-weight: $bold;
      @include font-size(20px);
    }
  }

  .table-group {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    thead th {
      border-top: none;
      border-bottom: 2px solid gray;
    }

    thead th:last-of-type {
      width: 10%;
    }

    tbody tr {
      border-top: none;
      border-bottom: 1px solid $clr-txt-note;
      cursor: unset;
    }

    .col-name {
      span {
        display: block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .col-description {
      span {
        display: block;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    th,
    td {
      padding: 0.8em 0.2em;
      vertical-align: middle;
      border-top: none;
    }

    .action-group-item {
      .buttons {
        top: 5px;
        right: 5px;

        button {
          display: block;
          float: left;
          width: 36px;
          height: 36px;
          margin-left: 10px;
          background-color: $primary;
          border-radius: 50%;
          border: 0;
          cursor: pointer;
          position: relative;

          &.edit {
            &:before {
              font-family: 'icomoon';
              font-size: 17px;
              content: '\e90a';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }

          &.remove {
            background-color: $alert;
            &:before {
              font-family: 'icomoon';
              @include font-size(12px);
              content: '\e919';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }
        }
      }
    }

    .col-members {
      div {
        display: flex;
        flex-wrap: wrap;
        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin: 0 2px;
        }
        span {
          padding: 10px 0;
          margin-left: 3px;
        }
      }
    }
  }
}
