@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

// EvidenciasShow
#evidenceBox {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 0;

  .evidence {
    cursor: pointer;
    padding: 20px 5px;
    border: 1px solid $clr-ui-border;
    border-radius: 9px;
    margin: 0 10px 10px 10px;
    @include font-size(14px);
    position: relative;
    width: 18%;
    white-space: nowrap;
    text-overflow: ellipsis;

    .evidTitle {
      overflow: hidden;
      width: 100%;
      display: block;
      text-overflow: ellipsis;
      margin-top: 10px;
      text-align: center;
    }

    .evidTitle.no-margin {
      margin: 0;
    }

    i,
    img {
      @include font-size(40px);
      display: block;
      clear: both;
      margin: 0 auto;
      text-align: center;
    }

    img {
      width: 45px;
      height: 41px;
      filter: grayscale(0.7);
    }

    i.icon-bin {
      width: unset;
      height: unset;
      position: absolute;
      @include font-size(12px);
      top: 0px;
      right: 0px;
      border-radius: 20px;
      font-weight: 300;
      background: #fff;
      padding: 3px;
      color: $alert;
    }

    .icon-error {
      position: absolute;
      font-size: 1.3rem;
      top: -10px;
      left: -10px;
      fill: $alert;
      stroke: #fff;
    }

    &.document,
    &.comment {
      cursor: pointer;
    }

    &.pending {
      &:before {
        content: '!';
        position: absolute;
        width: 12px;
        height: 12px;
        display: block;
        background: #c66;
        color: #fff;
        text-align: center;
        border-radius: 20px;
        font-weight: bold;
        @include font-size(9px);
        top: -5px;
        left: 0;
      }
    }

    &:hover {
      background-color: 'white';
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    }

    .icon-close {
      @include font-size(8px);
      margin-left: 10px;
      cursor: pointer;
      padding: 10px 5px;
    }
  }
}

label.no-width-label {
  width: unset !important;
}

.historyBox {
  display: block;
  clear: both;
  padding-top: 15px;

  li {
    padding-left: 55px;
    margin-bottom: 20px;
    min-height: 80px;
    position: relative;

    &:last-child {
      i {
        &:after {
          display: none;
        }
      }
    }

    i {
      position: absolute;
      left: 0;
      top: -5px;
      color: #fff;
      border-radius: 40px;
      height: 40px;
      width: 40px;
      @include font-size(20px);
      text-align: center;
      line-height: 1.7;
      padding: 2px;
      z-index: 20;

      &:after {
        position: absolute;
        width: 1px;
        height: 100px;
        content: '';
        margin: 0 auto;
        background: $clr-ui-border;
        left: 0;
        right: 0;
        z-index: 10;
        top: 100%;
      }

      &.icon-bin {
        background: $alert;
      }

      &.icon-magic-wand {
        background: $primary;
      }

      &.icon-attachment {
        background: $clr-ui-border;
      }

      &.icon-edit {
        background: $primary-icon;
      }

      &.icon-done {
        background: #107010;
        padding: 10px;

        &:before {
          border-color: #107010;
          content: '';
          background: url(../../../assets/img/concluido-ico.svg) #107010 center center no-repeat;
          width: 20px;
          height: 20px;
          display: block;
          transform: scale(1.4);
        }
      }

      &.icon-speak {
        background: #ccc;
        padding: 10px;

        &:before {
          border-color: #ccc;
          content: '';
          background: url(../../../assets/img/ico-speak2.svg) #ccc center center no-repeat;
          width: 20px;
          height: 20px;
          display: block;
          background-size: 20px;
          transform: scale(1.4);
        }
      }

      &.icon-not-applicable {
        background: $clr-txt-content;
        padding: 10px;

        &:before {
          border-color: $clr-txt-content;
          content: '';
          background: url(../../../assets/img/na-ico.svg) $clr-txt-content center center no-repeat;
          width: 20px;
          height: 20px;
          display: block;
          transform: scale(1.4);
        }
      }

      &.icon-pending {
        background: #efc30a;
        padding: 10px;

        &:before {
          border-color: #efc30a;
          content: '';
          background: url(../../../assets/img/pendente-ico.svg) #efc30a center center no-repeat;
          width: 20px;
          height: 20px;
          display: block;
          transform: scale(1.4);
        }
      }
    }

    h4 {
      margin-bottom: 0;
      font-weight: normal;
      @include font-size(16px);
      flex: 1;
      width: 100%;
      display: block;
      border-bottom: 1px solid $clr-ui-border;
      padding: 0 0 4px 0;
      margin: 0 0 4px 0;

      .timeHistory {
        float: right;
        @include font-size(14px);
      }
    }

    p {
      margin: 0;
      @include font-size(16px);
    }
  }
}