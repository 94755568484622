@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.table-page {
  .btn-requisite-new {
    background-image: url('../../default-v2/img/icon-plus-white.svg') !important;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 0 0 0 20px;
    margin-right: 15px;
    width: 125px;
  }

  .v2-cardheader-filter .v2-filter-searching .v2-filter-searching-nav {
    // padding: 17.25px 0 0 0;
  }

  .v2-filter-searching-nav {
    right: 30px !important;
  }

  .v2-page-cardheader .v2-page-btn-goback {
    left: 0;
  }

  .v2-page-content .card-body {
    padding: 0;
  }

  .right-menu {
    position: absolute;
    z-index: 1;
  }

  .card-body {
    position: relative;
    // z-index: 0;
  }

  .alert-container {
    padding: 13px 13px 0 13px;
  }

  #table-emule {
    margin: 0;
    padding: 0 0 10px 0;
    position: relative;

    hr {
      position: absolute;
      right: 0;
      margin: 0;
      width: 100%;
      border-top: 2px solid $clr-v2-heavier-grey;
    }

    .head-table ul:first-of-type {
      border-bottom: 0;
      margin-left: 30px;
    }
  }

  #table-emule {
    .body-table {
      li {
        padding: 10px 0;
        border-bottom: none;

        .optionsContainerTable {
          width: 50%;
        }
      }

      ul {
        li {
          border-top: 1px solid #afaba5;

          &.all-finished {
            background: #6cb6e3;
            color: #fff;
            border-top: 1px solid #fff !important;
          }

          &.non-finished {
            background: white;
            color: #777;
          }

          .dragComp {
            margin-left: 10px;
          }
        }

        .all-finished ~ li {
          border-top: 1px solid white;
        }

        .non-finished ~ .non-finished {
          border-top: 1px solid #afaba5;
        }
      }

      > ul:first-of-type {
        border-bottom: 1px solid #afaba5;
      }
    }

    .changeState {
      color: #777;
    }
  }

  #table-emule.lastOption {
    .head-table {
      ul {
        li:first-child {
          max-width: 48.5%;
        }

        li {
          max-width: 15.2%;
        }
      }
    }

    .body-table {
      ul > li > span {
        max-width: 37%;
      }

      ul {
        li {
          span {
            min-width: unset;
            max-width: unset;

            &.optionsHover {
              position: absolute;
              right: 40px;
              background: white;
              width: unset;

              a {
                line-height: 16px;
              }
            }
          }

          span:first-child {
            width: 30%;
          }

          span:nth-child(2n) {
            width: 33%;
            margin-right: 10px;
          }

          span:nth-child(3n) {
            width: 30%;
          }

          span:nth-child(4n) {
            width: 20%;
          }

          .subLevel {
            margin-top: 10px;
            margin-bottom: -10px;
            background: none;

            li {
              padding-left: 0;
            }

            .enter {
              .optionsHover {
                background: none;
              }
            }
          }
        }
      }
    }
  }

  #table-emule.deadline {
    .head-table {
      ul {
        li:first-child {
          max-width: 39.5%;
        }

        li {
          max-width: 15%;
        }
      }
    }

    .body-table {
      ul {
        li {
          .optionsContainerTable {
            width: 58.5%;
            span {
              width: 25%;
              &.optionsHover {
                width: 15%;
              }
            }
            .late {
              color: $alert-darker;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .v2-cardheader-filter .v2-filter-notsearching {
    margin-right: 15px;
  }

  .v2-filter-notsearching-left {
    display: inline-block;
    margin-left: 60px;

    .containerBar {
      display: flex;
      padding: 0 20px;
      position: relative;
      width: 350px;

      .progressBar {
        border: 1px solid $card-borders;
        border-radius: 30px;
        background: #e6e6e6;
        width: 88%;
        display: block;
        margin: 13px auto;
        height: 30px;
        position: relative;
        overflow: hidden;

        .fill {
          height: 100%;
          width: 0%;
          background: $clr-v2-soft-blue;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &:hover {
        .tooltipNorma {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .tooltipNorma {
        transition: $transition;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        z-index: 10;
        background: #a8aca8;
        color: $clr-v2-white;
        width: 7.4rem;
        top: 32px;
        border-radius: 5px;
        opacity: 0;
        padding: 10px 10px;
        text-align: left;
        transform: translateY(-25px);
        @include font-size(12px);
      }
      .percent {
        color: $primary;
        margin: auto 0 auto 10px;
        line-height: 2.4;
        @include font-size(13px);
        min-width: 35px;
        text-align: left;
      }
    }
  }

  .dragComp {
    i {
      visibility: hidden;
    }
  }

  .dragComp:hover {
    i {
      visibility: visible;
    }
  }

  .optionEvidence .evidencesAdd a {
    padding: 6px 10px 10px;
  }
}

.norm-alert .sweet-alert {
  h2 {
    font-size: 30px !important;
    text-align: center !important;
  }
  .text-muted {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1500px) {
  #table-emule .body-table li .optionsContainerTable {
    width: 60%;
  }
}

@media screen and (max-width: 1600px) {
  #table-emule .body-table li .optionsContainerTable {
    width: 60%;
  }
}

@media screen and (max-width: 1367px) {
  .v2-page-content {
    float: left;
    /*padding-left: 91px;
    padding-right: 93px;*/
    // padding: 32px;
    background-color: #dbdfe5;
    width: calc(100% - 0px);
    height: 100vh;
    transition: width 0.5s;
    //overflow: auto;
  }

  .table-page #table-emule.deadline .body-table ul li .optionsContainerTable {
    .status {
      &:after {
        left: 80%;
      }
    }
  }

  .table-page.menu-expanded {
    .v2-page-cardheader .v2-filter-notsearching-left .containerBar {
      padding: 0;
      width: 245px;
    }
  }
}

@media screen and (max-width: 1258px) {
  .table-page .v2-page-cardheader .v2-filter-notsearching-left .containerBar.deadline {
    padding: 0;
    width: 300px;
  }

  .table-page #table-emule.deadline .body-table ul li .optionsContainerTable {
    .status {
      &:after {
        left: 90%;
      }
    }

    .optionsHover {
      width: 17%;
      right: 40px !important;
    }
  }

  .table-page.menu-expanded {
    #table-emule.deadline .body-table ul li .optionsContainerTable {
      .optionsHover {
        width: 17%;
        right: 20px !important;
      }
    }
  }

  .table-page.menu-expanded {
    .v2-page-cardheader .v2-filter-notsearching-left .containerBar {
      padding: 0;
      width: 190px;
    }
  }
}

@media screen and (max-width: 1208px) {
  .table-page .v2-page-cardheader .v2-filter-notsearching-left .containerBar.deadline {
    padding: 0;
    width: 230px;
  }

  .table-page.menu-expanded {
    .v2-page-cardheader .v2-filter-notsearching-left .containerBar {
      padding: 0;
      width: 140px;
    }
  }
}

@media screen and (max-width: 1170px) {
  .table-page #table-emule.deadline .head-table ul {
    li:first-child {
      max-width: 36%;
    }

    li:nth-child(3) {
      max-width: 17%;
    }

    li {
      max-width: 15%;
    }
  }

  .table-page #table-emule.deadline .body-table ul li .optionsContainerTable {
    width: 60% !important;
  }
}

@media screen and (max-width: 1158px) {
  .table-page {
    .v2-page-cardheader {
      .v2-page-btn-goback {
        padding: 18px 20px !important;
      }

      .v2-filter-notsearching-left .containerBar {
        padding: 0 !important;
        width: 200px !important;
      }
    }
  }

  .table-page.menu-expanded {
    .v2-page-cardheader .v2-filter-notsearching-left .containerBar {
      padding: 0;
      width: 110px !important;
    }
  }
}

@media screen and (max-width: 1125px) {
  .table-page.menu-expanded {
    .v2-filter-notsearching {
      .alt-checkbox {
        display: flex;
        .alt-checkbox-label {
          display: block;
          width: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media screen and (max-width: 1101px) {
  .table-page #table-emule.deadline .body-table ul li .optionsContainerTable {
    width: 61% !important;
  }
}

@media screen and (max-width: 1105px) {
  .table-page .v2-page-cardheader .v2-filter-notsearching-left .containerBar.deadline {
    width: 110px !important;
  }
}

@media screen and (max-width: 1078px) {
  .table-page.menu-expanded {
    #table-emule.deadline .body-table ul li .optionsContainerTable {
      width: 60% !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table-page #table-emule .body-table li {
    .optionsContainerTable {
      width: 55%;
    }
    .status:after {
      left: unset;
      right: 10px;
    }
  }

  .table-page #table-emule.deadline .body-table ul li .optionsContainerTable {
    width: 73% !important;
  }

  .table-page #table-emule.lastOption .head-table ul {
    li:first-child {
      max-width: 42.5%;
    }

    li {
      max-width: 18.2%;
    }
  }

  .table-page #table-emule.deadline .head-table ul {
    li:first-child {
      max-width: 24%;
    }

    li {
      max-width: 19%;
    }
  }

  .table-page #table-emule.lastOption .body-table ul li {
    span:first-child {
      width: 135px !important;
    }
    span:nth-child(2n) {
      width: 135px;
      margin-right: unset;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span:nth-child(3n) {
      padding-left: 10px;
      width: 115px;
    }
  }

  .table-page #table-emule.deadline .body-table ul li {
    min-width: unset !important;
    max-width: unset !important;

    span:first-child {
      width: 130px !important;
    }
    span:nth-child(2n) {
      width: 115px !important;
      margin-right: unset;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span:nth-child(3n) {
      padding-left: 10px;
      width: 110px !important;
    }
    span:nth-child(4n) {
      padding-left: 10px;
      width: 130px !important;
    }
  }
}
